import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Path } from '../containers/config'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { CompanyRegistration } from '../containers/organization/com-reg'
import { UserRegistration } from '../containers/organization/user-reg'
import Dropdown from 'react-dropdown'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { REGISTRATION_TYPE } from '../utils/constant'

export class RegisterAccount extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            key: 'company',
        }
    }

    handleChange = (event) => {
        console.log(
            '---ID---',
            event.target.id,
            '---value---',
            event.target.value,
        )
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    handleSubmit = () => {
        // forgetPassword(this.state.username)
        // .then((res)=>{
        //     if(res&&res.status===200){
        //         alert(res.data.message)
        //         this.props.history.push(Path.index)
        //     }
        // })
    }

    handleValidation = () => {
        let errors = {}
        return errors
    }

    onKeySet = (val) => {
        this.setState({
            key: val,
        })
    }

    render() {
        const props = this.props
        return (
            <>
                {/* <!-- Main menu Navbar --> */}
                <nav className="navbar navbar-expand-lg navbar-light bg-light sticky l-bg">
                    <div style={{ width: '100%' }}>
                        <div style={{ float: 'left' }}>
                            <Link id="1" to={Path.home}>
                                <img
                                    id="1"
                                    src="/assets/images/eDoc.png"
                                    height="30"
                                />
                            </Link>
                        </div>
                    </div>
                </nav>

                <div
                    className="col-md-12 shadow l-bg scroll4"
                    style={{
                        paddingBottom: '100px',
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.key}
                        className="justify-content-center"
                        onSelect={(k) =>
                            this.setState({
                                key: k,
                            })
                        }
                    >
                        <Tab eventKey="company" title="Company">
                            <CompanyRegistration
                                {...props}
                                key={this.state.key}
                                outside={true}
                                tittle="Register as a Company?"
                                html={
                                    <p className="mt-2 l-f-r">
                                        <small>
                                            Already Registered?{' '}
                                            <Link className=" " to={Path.login}>
                                                Back to Login
                                            </Link>
                                        </small>
                                    </p>
                                }
                            />
                        </Tab>
                        <Tab eventKey="user" title="User">
                            <UserRegistration
                                key={this.state.key}
                                {...props}
                                outside={true}
                                tittle={'Register as a User?'}
                                html={
                                    <p className="mt-2 l-f-r">
                                        <small>
                                            Already Registered?{' '}
                                            <Link className=" " to={Path.login}>
                                                Back to Login
                                            </Link>
                                        </small>
                                    </p>
                                }
                            />
                        </Tab>
                    </Tabs>
                </div>
                <div className="container-fluid footer">
                    Developed by{' '}
                    <a href="https://www.tirzok.com/" target="_blank">
                        Tirzok Private Limited
                    </a>
                </div>
            </>
        )
    }
}
