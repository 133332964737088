import React, { Component } from 'react'
import { Link, Switch, NavLink } from 'react-router-dom'
import AppliedRoute from '../utils/applied-route'
import { API, Path, PATHNAME } from '../containers/config'
import {
    CompanyList,
    CompanyPendingList,
    CompanyRegistration,
} from '../containers/organization/com-reg'
import NotFound, { WelcomeScem } from './not-found'
import {
    UserList,
    UserPendingList,
    UserRegistration,
} from '../containers/organization/user-reg'
import { RoleList } from '../containers/organization/role-managment/role-list'
import { ActivityLog } from './activity-log'
import OutsideAlerter from '../components/outside-alert'
import { POList } from '../containers/user/po-list'
import ChangePassword from './change-password'
import Profile from '../containers/user/profile'
import { checkRole } from '../utils/event-handlers'
import PoCreationFileUploadPage from './po-cretaion-xd'
import { POApprovalStatus } from '../containers/reports/po-approval-status'
import { ExceptionCheckReport } from '../containers/reports/exception-check-report'
import { PriceMismatchRepor } from '../containers/reports/price-mismatch-repor'
import { POApprovedByPeriod } from '../containers/reports/po-approved-by-period'
import { getApiCallDynamic } from '../service'

export default class Home extends Component {
    constructor(props) {
        super(props)
        console.log('user location', this.props.location.pathname)
        this.state = {
            sidebarToggle: '',
            activeLink: '',
            activeDrowDown: '',
            pendingCount: 0,
            userPendingCount: 0,

            companyPendingCount: 0,
            profileDrop: false,
            userData: '',
        }
    }
    linkActivation = () => {
        const obj = PATHNAME.find(
            (element) => element.name == this.props.location.pathname,
        )
        if (obj !== undefined) {
            this.setState({
                activeDrowDown: obj.activeDrowDown,
                activeLink: obj.activeLink,
            })
        }
    }

    outside = (e) => {
        this.setState({
            [e]: '',
        })
    }
    handleChangeMenu = (e, flash) => {
        console.log('aaaa', e.target.id, flash)
        if (flash) {
            this.setState({
                activeDrowDown: '',
            })
        }
        this.setState({
            activeLink: e.target.id,
        })
    }

    handleChangeDropMenu = (e) => {
        if (this.state.activeDrowDown != e.target.id) {
            this.setState({
                activeDrowDown: e.target.id,
            })
        }
    }
    handleChange = (event) => {
        console.log('aa', event.target.id, event)
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    poPendingCount = () => {
        let queryParameter = { isCount: true }
        getApiCallDynamic(queryParameter, API.poPendingCount)
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        pendingCount: res.data,
                    })
                    console.log(this.state.pendingCount)
                }
            })
            .catch((err) => err)
    }
    companyPendingCount = () => {
        let queryParameter = { orgId: 1 }
        getApiCallDynamic(queryParameter, API.companyPendingCount)
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        companyPendingCount: res.data,
                    })
                    console.log(
                        'companyPendingCount',
                        this.state.companyPendingCount,
                    )
                }
            })
            .catch((err) => err)
    }
    userPendingCount = () => {
        let queryParameter = { orgId: 1 }
        getApiCallDynamic(queryParameter, API.userPendingCount)
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        userPendingCount: res.data,
                    })
                    console.log('userPendingCount', this.state.userPendingCount)
                }
            })
            .catch((err) => err)
    }
    checkAdmin = () => {
        let admin = checkRole({
            roleArray: this.props.user.data.roleList,
            roleName: 'tp::adm',
        })
        console.log('lets see', admin, this.props.user.data.roleList)
        if (admin) {
            this.companyPendingCount()
            this.userPendingCount()
        }
    }

    userInfo = () => {
        let queryParameter = { id: this.props.user.data.businessInfo.userId }
        getApiCallDynamic(queryParameter, API.createdUserList)
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        userData: res.data,
                    })
                    console.log(this.state.userData)
                }
            })
            .catch((err) => err)
    }

    componentDidMount() {
        this.checkAdmin()

        this.poPendingCount()

        this.userInfo()
        this.linkActivation()
    }

    render() {
        const {
            businessInfo: { touchPointName },
            companyName,
            touchPointId,
            roleList,
        } = this.props.user.data
        const { firstName, lastName } = this.state.userData
        const {
            activeLink,
            activeDrowDown,
            pendingCount,
            companyPendingCount,
            userPendingCount,
        } = this.state

        let sideBar = (
            <nav className="sidenav scroll4">
                <ul className="nav nav-pills">
                    {/* <!-- Add nav-actice class to active a nav li --> */}
                    <li className={activeLink == 1 ? 'nav-active' : ''}>
                        <Link
                            onClick={(e) => this.handleChangeMenu(e, true)}
                            id="1"
                            to={Path.home}
                        >
                            <i className="fa fa-th-large"></i>Dashboard
                        </Link>
                    </li>
                    {/* <!-- Add nav-dropdown class for the li which have sub-menu --> */}
                    {roleList.some((i) => i.includes('tp::adm')) ? (
                        <>
                            <li
                                className={
                                    activeDrowDown == 2
                                        ? 'nav-active nav-dropdown-collapse'
                                        : 'nav-dropdown'
                                }
                            >
                                <Link
                                    onClick={this.handleChangeDropMenu}
                                    id="2"
                                    to="#"
                                >
                                    <i className="fa fa-building"></i>Company
                                </Link>
                                <div className="submenu">
                                    <NavLink
                                        onClick={(e) =>
                                            this.handleChangeMenu(e, false)
                                        }
                                        id="3"
                                        value="1"
                                        className={
                                            activeLink == 3
                                                ? 'nav-item-active'
                                                : ''
                                        }
                                        to={Path.companyReg}
                                    >
                                        Registration Form
                                    </NavLink>
                                    <NavLink
                                        onClick={(e) =>
                                            this.handleChangeMenu(e, false)
                                        }
                                        id="4"
                                        className={
                                            activeLink == 4
                                                ? 'nav-item-active'
                                                : ''
                                        }
                                        to={Path.companyList}
                                    >
                                        Company List
                                    </NavLink>
                                    <NavLink
                                        onClick={(e) =>
                                            this.handleChangeMenu(e, false)
                                        }
                                        id="5"
                                        className={
                                            activeLink == 5
                                                ? 'nav-item-active'
                                                : ''
                                        }
                                        to={Path.companyPendingList}
                                    >
                                        Pending List
                                        <div className="pr-2 float-right">
                                            <p>{companyPendingCount}</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            {/* <!-- Add nav-active and nav-dropdown-collapse for active a nav li which have multiple sub-menu --> */}
                            <li
                                className={
                                    activeDrowDown == 6
                                        ? 'nav-active nav-dropdown-collapse'
                                        : 'nav-dropdown'
                                }
                            >
                                <Link
                                    onClick={this.handleChangeDropMenu}
                                    id="6"
                                    to="#"
                                >
                                    <i className="fa fa-users"></i>Users
                                </Link>
                                <div className="submenu">
                                    {/* <!-- Add nav-item-active to show active submenu --> */}
                                    <NavLink
                                        onClick={(e) =>
                                            this.handleChangeMenu(e, false)
                                        }
                                        id="7"
                                        className={
                                            activeLink == 7
                                                ? 'nav-item-active'
                                                : ''
                                        }
                                        to={Path.userReg}
                                    >
                                        Registration Form
                                    </NavLink>
                                    <NavLink
                                        onClick={(e) =>
                                            this.handleChangeMenu(e, false)
                                        }
                                        id="8"
                                        className={
                                            activeLink == 8
                                                ? 'nav-item-active'
                                                : ''
                                        }
                                        to={Path.userList}
                                    >
                                        User List
                                    </NavLink>
                                    <NavLink
                                        onClick={(e) =>
                                            this.handleChangeMenu(e, false)
                                        }
                                        id="9"
                                        className={
                                            activeLink == 9
                                                ? 'nav-item-active'
                                                : ''
                                        }
                                        to={Path.userPendingList}
                                    >
                                        Pending List
                                        <div className="pr-2 float-right">
                                            <p>{userPendingCount}</p>
                                        </div>
                                    </NavLink>
                                </div>
                            </li>
                            <li
                                className={activeLink == 15 ? 'nav-active' : ''}
                            >
                                <Link
                                    onClick={(e) =>
                                        this.handleChangeMenu(e, true)
                                    }
                                    id="15"
                                    to={Path.poFileUpload}
                                >
                                    <i className="fa fa-upload"></i>PO Creation
                                </Link>
                            </li>
                        </>
                    ) : null}
                    <li className={activeLink == 10 ? 'nav-active' : ''}>
                        <Link
                            onClick={(e) => this.handleChangeMenu(e, true)}
                            id="10"
                            to={Path.poListDashboard}
                        >
                            <i className="fa fa-list"></i>PO List{' '}
                            <div className="pr-2 float-right">
                                <p>{this.state.pendingCount}</p>
                            </div>
                        </Link>
                    </li>
                    {/* reporting section */}
                    {roleList.some((i) => i.includes('tp::adm')) ||
                    roleList.some((i) => i.includes('tp::vn')) ||
                    roleList.some((i) => i.includes('tp::sc')) ? (
                        <>
                            <li
                                className={
                                    activeDrowDown == 'report'
                                        ? 'nav-active nav-dropdown-collapse'
                                        : 'nav-dropdown'
                                }
                            >
                                <Link
                                    onClick={this.handleChangeDropMenu}
                                    id="report"
                                    to="#"
                                >
                                    <i className="fa fa-users"></i>Reports
                                </Link>
                                <div className="submenu">
                                    <NavLink
                                        onClick={(e) =>
                                            this.handleChangeMenu(e, false)
                                        }
                                        id="po_approval_status"
                                        className={
                                            activeLink == 'po_approval_status'
                                                ? 'nav-item-active'
                                                : ''
                                        }
                                        to={Path.poApprovalStatus}
                                    >
                                        PO APPROVAL STATUS
                                    </NavLink>
                                    {roleList.some((i) =>
                                        i.includes('tp::adm'),
                                    ) ||
                                    roleList.some((i) =>
                                        i.includes('tp::vn'),
                                    ) ? (
                                        <NavLink
                                            onClick={(e) =>
                                                this.handleChangeMenu(e, false)
                                            }
                                            id="exception_check_report"
                                            className={
                                                activeLink ==
                                                'exception_check_report'
                                                    ? 'nav-item-active'
                                                    : ''
                                            }
                                            to={Path.exceptionCheckReport}
                                        >
                                            Exception Check Report
                                        </NavLink>
                                    ) : (
                                        ''
                                    )}
                                    {roleList.some((i) =>
                                        i.includes('tp::adm'),
                                    ) ||
                                    roleList.some((i) =>
                                        i.includes('tp::sc'),
                                    ) ? (
                                        <NavLink
                                            onClick={(e) =>
                                                this.handleChangeMenu(e, false)
                                            }
                                            id="price_mismatch_report"
                                            className={
                                                activeLink ==
                                                'price_mismatch_report'
                                                    ? 'nav-item-active'
                                                    : ''
                                            }
                                            to={Path.priceMismatchReport}
                                        >
                                            Price Mismatch Report
                                        </NavLink>
                                    ) : (
                                        ''
                                    )}
                                    {roleList.some((i) =>
                                        i.includes('tp::adm'),
                                    ) ||
                                    roleList.some((i) =>
                                        i.includes('tp::sc'),
                                    ) ? (
                                        <NavLink
                                            onClick={(e) =>
                                                this.handleChangeMenu(e, false)
                                            }
                                            id="po_approved_by_period"
                                            className={
                                                activeLink ==
                                                'po_approved_by_period'
                                                    ? 'nav-item-active'
                                                    : ''
                                            }
                                            to={Path.poApprovedByPeriod}
                                        >
                                            Po Approved by Period
                                        </NavLink>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </li>
                        </>
                    ) : null}

                    {/* <hr/>
				<li  className={activeLink==11?"nav-active":''}>
					<Link onClick={(e)=>this.handleChangeMenu(e,true)} id='11' to={Path.activityLog}><i className="fa fa-file-alt"></i>Activity Log</Link>
				</li> */}
                    <hr />
                    {/* <!-- Replace nav-dropdown to nav-dropdown-collapse to li's sub-menu --> */}
                    <li
                        className={
                            activeDrowDown == 12
                                ? 'nav-active nav-dropdown-collapse'
                                : 'nav-dropdown'
                        }
                    >
                        <Link
                            onClick={this.handleChangeDropMenu}
                            id="12"
                            to="#"
                        >
                            <i className="fa fa-user"></i>Profile
                        </Link>
                        <div className="submenu">
                            <Link
                                onClick={(e) => this.handleChangeMenu(e, false)}
                                id="13"
                                className={
                                    activeLink == 13 ? 'nav-item-active' : ''
                                }
                                to={Path.profile}
                            >
                                Profile Information
                            </Link>
                            <Link
                                onClick={(e) => this.handleChangeMenu(e, false)}
                                id="14"
                                className={
                                    activeLink == 14 ? 'nav-item-active' : ''
                                }
                                to={Path.changePassword}
                            >
                                Change Password
                            </Link>
                        </div>
                    </li>
                    <hr />
                    <li className="l-out">
                        <Link onClick={this.props.handleLogout} id="15" to="#">
                            <i className="fa fa-power-off"></i>Log Out
                        </Link>
                    </li>
                </ul>
            </nav>
        )

        let topBar = (
            <nav className="static-top  nav-shadow">
                <div className="container-fluid py-3">
                    <div className="row justify-content-between">
                        <div className="col-md-3">
                            <Link
                                onClick={(e) => this.handleChangeMenu(e, true)}
                                id="1"
                                to={Path.home}
                            >
                                <img
                                    id="1"
                                    src="/assets/images/eDoc.png"
                                    height="30"
                                />
                            </Link>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center text-center">
                            <h4 className="px-2 e-user">{firstName} </h4>
                            <h4 className="px-2 e-company">{companyName}</h4>
                            <h4 className="px-2 e-usertype">
                                {touchPointName}
                            </h4>
                        </div>

                        <div className="col-md-3 text-right">
                            <OutsideAlerter
                                id="profileDrop"
                                onchangeNav={this.outside}
                            >
                                <ul className="navbar-nav">
                                    <li
                                        className={
                                            this.state.profileDrop
                                                ? ` nav-item dropdown show`
                                                : ' nav-item dropdown '
                                        }
                                    >
                                        <Link
                                            id="profileDrop"
                                            to="#"
                                            className="nav-link dropdown-toggle profileDrop"
                                            onClick={(e) =>
                                                this.setState({
                                                    profileDrop: true,
                                                })
                                            }
                                        >
                                            <img
                                                src="/assets/images/user.png"
                                                height="30"
                                            />
                                        </Link>
                                        <div
                                            className={
                                                this.state.profileDrop
                                                    ? `dropdown-menu p-dropdown show`
                                                    : 'dropdown-menu p-dropdown'
                                            }
                                            aria-labelledby="navbarDropdown"
                                        >
                                            <Link
                                                id="13"
                                                className="dropdown-item"
                                                to={Path.profile}
                                                onClick={(e) => {
                                                    this.handleChangeMenu(
                                                        e,
                                                        false,
                                                    )
                                                    this.setState({
                                                        profileDrop: false,
                                                    })
                                                    this.handleChangeDropMenu({
                                                        target: { id: 12 },
                                                    })
                                                }}
                                            >
                                                Profile Information
                                            </Link>
                                            <Link
                                                id="14"
                                                className="dropdown-item"
                                                to={Path.changePassword}
                                                onClick={(e) => {
                                                    this.handleChangeMenu(
                                                        e,
                                                        false,
                                                    )
                                                    this.setState({
                                                        profileDrop: false,
                                                    })
                                                    this.handleChangeDropMenu({
                                                        target: { id: 12 },
                                                    })
                                                }}
                                            >
                                                Change Password
                                            </Link>
                                            <hr />
                                            {/* <!-- <div className="dropdown-divider"></div> --> */}
                                            <Link
                                                to="#"
                                                className="dropdown-item"
                                                style={{ color: 'red' }}
                                                onClick={
                                                    this.props.handleLogout
                                                }
                                            >
                                                Log Out
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </OutsideAlerter>
                        </div>
                    </div>
                </div>
            </nav>
        )
        let pending = { ...this.props, poStatus: 'pending' }
        let all = {
            ...this.props,
            poStatus: '',
            poPendingCount: this.poPendingCount,
        }
        let routeAdmin = (
            <Switch>
                <AppliedRoute
                    exact
                    path={Path.companyReg}
                    component={CompanyRegistration}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.poApprovalStatus}
                    component={POApprovalStatus}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.exceptionCheckReport}
                    component={ExceptionCheckReport}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.priceMismatchReport}
                    component={PriceMismatchRepor}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.poApprovedByPeriod}
                    component={POApprovedByPeriod}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.companyList}
                    component={CompanyList}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.companyPendingList}
                    component={CompanyPendingList}
                    props={this.props}
                    companyPendingCount={this.companyPendingCount}
                />
                <AppliedRoute
                    exact
                    path={Path.userReg}
                    component={UserRegistration}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.userList}
                    component={UserList}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.userPendingList}
                    component={UserPendingList}
                    props={this.props}
                    userPendingCount={this.userPendingCount}
                />
                <AppliedRoute
                    exact
                    path={Path.roleList}
                    component={RoleList}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.activityLog}
                    component={ActivityLog}
                    props={this.props}
                />
                {/* <AppliedRoute exact path={Path.vendor} component={Vendor}  props={this.props}/> */}
                <AppliedRoute
                    exact
                    path={Path.poListDashboard}
                    component={POList}
                    props={all}
                />
                <AppliedRoute
                    exact
                    path={Path.changePassword}
                    component={ChangePassword}
                    props={this.props}
                />
                {/* <AppliedRoute exact path={Path.poListDashboard} component={POList}  props={this.props}/> */}
                <AppliedRoute
                    exact
                    path={Path.home}
                    component={WelcomeScem}
                    firstName={firstName}
                    lastName={lastName}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.profile}
                    component={Profile}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.poFileUpload}
                    component={PoCreationFileUploadPage}
                    props={this.props}
                />
                <AppliedRoute component={NotFound} />
            </Switch>
        )

        let routeVendor = (
            <Switch>
                <AppliedRoute
                    exact
                    path={Path.poApprovalStatus}
                    component={POApprovalStatus}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.exceptionCheckReport}
                    component={ExceptionCheckReport}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.poListDashboard}
                    component={POList}
                    props={all}
                />
                {/* <AppliedRoute exact path={Path.pendingPoList} component={POList}  props={pending}/> */}
                <AppliedRoute
                    exact
                    path={Path.activityLog}
                    component={ActivityLog}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.home}
                    component={WelcomeScem}
                    firstName={firstName}
                    lastName={lastName}
                    props={this.props}
                />

                <AppliedRoute
                    exact
                    path={Path.changePassword}
                    component={ChangePassword}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.profile}
                    component={Profile}
                    props={this.props}
                />
                <AppliedRoute component={NotFound} />
            </Switch>
        )

        let routeSourcing = (
            <Switch>
                <AppliedRoute
                    exact
                    path={Path.poApprovalStatus}
                    component={POApprovalStatus}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.priceMismatchReport}
                    component={PriceMismatchRepor}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.poApprovedByPeriod}
                    component={POApprovedByPeriod}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.poListDashboard}
                    component={POList}
                    props={all}
                />
                {/* <AppliedRoute exact path={Path.pendingPoList} component={POList}  props={pending}/> */}
                <AppliedRoute
                    exact
                    path={Path.activityLog}
                    component={ActivityLog}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.home}
                    component={WelcomeScem}
                    firstName={firstName}
                    lastName={lastName}
                    props={this.props}
                />

                <AppliedRoute
                    exact
                    path={Path.changePassword}
                    component={ChangePassword}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.profile}
                    component={Profile}
                    props={this.props}
                />
                <AppliedRoute component={NotFound} />
            </Switch>
        )

        let routeCommon = (
            <Switch>
                <AppliedRoute
                    exact
                    path={Path.poListDashboard}
                    component={POList}
                    props={all}
                />
                {/* <AppliedRoute exact path={Path.pendingPoList} component={POList}  props={pending}/> */}
                <AppliedRoute
                    exact
                    path={Path.activityLog}
                    component={ActivityLog}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.home}
                    component={WelcomeScem}
                    firstName={firstName}
                    lastName={lastName}
                    props={this.props}
                />

                <AppliedRoute
                    exact
                    path={Path.changePassword}
                    component={ChangePassword}
                    props={this.props}
                />
                <AppliedRoute
                    exact
                    path={Path.profile}
                    component={Profile}
                    props={this.props}
                />
                <AppliedRoute component={NotFound} />
            </Switch>
        )
        return (
            <>
                {topBar}
                <div className="page-content l-bg">
                    {sideBar}
                    <div className="main-content scroll4">
                        <Switch>
                            {roleList.some((i) => i.includes('tp::adm'))
                                ? routeAdmin
                                : roleList.some((i) => i.includes('tp::vn'))
                                ? routeVendor
                                : roleList.some((i) => i.includes('tp::sc'))
                                ? routeSourcing
                                : routeCommon}
                        </Switch>
                        <div className="container-fluid footer">
                            {/* <p>Developed by </p><a href='https://www.tirzok.com/' target='_blank'>Tirzok Private Limited</a> */}
                            Developed by{' '}
                            <a href="https://www.tirzok.com/" target="_blank">
                                Tirzok Private Limited
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
