import React, { Component } from 'react'
import { COLUMNS_ROLE_LIST } from '../../../utils/constant'

import CommonTable from '../../../components/table'

import { Button } from 'react-bootstrap'
import 'react-dropdown/style.css'
import { getApiCallDynamic } from '../../../service'

export class RoleList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedData: {},
            roleList: [],
        }
    }

    handleSelectedRow = (row, isSelect, rowIndex, e) => {
        console.log(
            'row====>',
            row,
            'isSelected=>>>>',
            isSelect,
            'rowIndex=>>>>>>>',
            rowIndex,
            'Event=>>>>',
            e,
        )
        this.setState({
            selectedData: row,
            selectedRow: rowIndex,
        })
    }

    refresh = () => {
        getApiCallDynamic(API.roleList)
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        roleList: res.data,
                    })
                    console.log(this.state.roleList)
                }
            })
            .catch((err) => err)
    }

    componentDidMount() {
        this.refresh()
    }

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true, // enable hide selection column.

            clickToSelect: true,
            onSelect: this.handleSelectedRow,
        }
        // this.resetData();
        return (
            <div id="company_list" className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="p-5">
                            <Button
                                type="button"
                                variant="outline-secondary"
                                className="ml-3   f-r"
                                onClick={this.refresh}
                            >
                                Refresh
                            </Button>

                            <CommonTable
                                selectRow={selectRow}
                                columns={COLUMNS_ROLE_LIST}
                                data={this.state.roleList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
