import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { CSVExport, Search } from 'react-bootstrap-table2-toolkit'
import filterFactory from 'react-bootstrap-table2-filter'
import axios from 'axios'
import Dropdown from 'react-dropdown'
import Pagination from '@mui/material/Pagination'
import { COMPANY_TYPE } from '../../utils/constant'
import LoadingOverlay from 'react-loading-overlay'

const { SearchBar } = Search
const { ExportCSVButton } = CSVExport

export default class ComUserPagination extends Component {
    constructor(props) {
        super(props)
        console.log('pending count', this.props)

        this.child = React.createRef()
        this.state = {
            data: [],
            currentTutorial: null,
            currentIndex: -1,
            searchTitle: '',
            page: 1,
            count: 0,
            pageSize: 50,
            status: this.props.status,
            startDate: '',
            endDate: '',
            tpId: '',
            spinner: false,
            username: '',
            companyName: '',
            companyCode: '',
            email: '',
            country: '',
            businessCurrency: '',
        }
        this.pageSizes = [50, 100, 200]
        this.onChangeSearchFilter = this.onChangeSearchFilter.bind(this)
        this.retrievePoLists = this.retrievePoLists.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    }
    onChangeSearchFilter(e) {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }
    getRequestParams(
        searchTitle,
        page,
        pageSize,
        startDate,
        endDate,
        status,
        tpId,
        username,
        email,
        companyName,
        country,
        companyCode,
        businessCurrency,
    ) {
        let params = {}
        params['orgId'] = 1
        if (businessCurrency) {
            params['businessCurrency'] = businessCurrency
        }

        if (companyCode) {
            params['companyCode'] = companyCode
        }
        if (username) {
            params['username'] = username
        }
        if (email) {
            params['email'] = email
        }
        if (companyName) {
            params['companyName'] = companyName
        }
        if (country) {
            params['country'] = country
        }
        if (searchTitle) {
            params['title'] = searchTitle
        }

        if (page) {
            params['page'] = page - 1
        }
        if (pageSize) {
            params['size'] = pageSize
        }
        if (startDate) {
            params['startDate'] = startDate
        }
        if (endDate) {
            params['endDate'] = endDate
        }
        if (tpId) {
            params['touchPointId'] = tpId
        }
        if (status) {
            params['status'] = status
        }

        return params
    }

    retrievePoLists(pageChange = false) {
        this.setState({
            data: [],

            spinner: true,
        })
        let {
            searchTitle,
            page,
            pageSize,
            startDate,
            endDate,
            status,
            tpId,
            username,
            email,
            companyName,
            country,
            companyCode,
            businessCurrency,
        } = this.state
        if (pageChange === true) {
        } else {
            page = 1
        }
        const params = this.getRequestParams(
            searchTitle,
            page,
            pageSize,
            startDate,
            endDate,
            status,
            tpId,
            username,
            email,
            companyName,
            country,
            companyCode,
            businessCurrency,
        )
        axios
            .get(this.props.path, { params })
            .then((response) => {
                const { dataList, totalPages, currentPage } = response.data

                this.setState({
                    data: dataList,
                    count: totalPages,
                    spinner: false,
                    page: currentPage + 1,
                })
                if (this.props.pendingPage) {
                    this.props.pendingCount()
                }
                console.log(response.data)
            })
            .catch((e) => {
                this.setState({
                    data: [],
                    page: 1,
                    spinner: false,
                })
                console.log(e)
            })
    }

    handlePageChange(event, value) {
        this.setState(
            {
                page: value,
            },
            () => {
                this.retrievePoLists(true)
            },
        )
    }

    componentDidMount() {
        this.retrievePoLists()
    }

    handlePageSizeChange(event) {
        this.setState(
            {
                pageSize: event.target.value,
                page: 1,
            },
            () => {
                this.retrievePoLists(true)
            },
        )
    }

    handleDropDownChange = (val, name) => (event) => {
        this.state[val] = event.value
        this.state[name] = event.label
        this.setState()
    }

    onClear = (e) => {
        console.log('onClear', e.target.id)
        this.setState(
            {
                [e.target.id]: '',
            },
            () => this.retrievePoLists(),
        )
    }

    render() {
        const { searchTitle, page, count, pageSize } = this.state

        const parentMethod = {
            onClear: this.onClear,
            state: this.state,
            refresh: this.retrievePoLists,
            handleDropDownChange: this.handleDropDownChange,
            handleChange: this.onChangeSearchFilter,
        }

        return (
            <div className="list row">
                <div className="col-md-12">
                    <table
                        className={
                            this.props.pendingPage
                                ? 'd-none'
                                : 'table table-condensed'
                        }
                    >
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td>
                                    <label className="from-control from-control-sm">
                                        {this.props.tittle} Status
                                        <sup className="text-danger">*</sup>
                                    </label>
                                    <Dropdown
                                        controlClassName={`form-control form-control-sm `}
                                        className={` Dropdown-root-width `}
                                        value={this.state.status}
                                        id="status"
                                        options={COMPANY_TYPE}
                                        onChange={this.handleDropDownChange(
                                            'status',
                                        )}
                                        placeholder="Select an option"
                                    />
                                </td>

                                <td>
                                    <label className="from-control from-control-sm"></label>

                                    <button
                                        className="from-control btn btn-sm btn-outline-primary"
                                        type="button"
                                        onClick={this.retrievePoLists}
                                    >
                                        Search
                                    </button>
                                </td>

                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 overflow-hidden mb-2">
                    <div className=" float-left">
                        {'Items per Page: '}
                        <select
                            onChange={this.handlePageSizeChange}
                            value={pageSize}
                        >
                            {this.pageSizes.map((size) => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className=" float-right">
                        <Pagination
                            className=""
                            showFirstButton
                            showLastButton
                            count={count}
                            page={page}
                            siblingCount={1}
                            boundaryCount={1}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={this.handlePageChange}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <LoadingOverlay
                        active={this.state.spinner}
                        spinner={true}
                        text="Please wait a moment"
                    >
                        <BootstrapTable
                            keyField="id"
                            data={this.state.data}
                            columns={this.props.columns(
                                (page - 1) * pageSize,
                                parentMethod,
                            )}
                            selectRow={this.props.selectRow}
                            hover
                            condensed
                            filter={filterFactory()}
                        />
                    </LoadingOverlay>
                </div>
            </div>
        )
    }
}
