import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from '../api/auth'
import { toastNotify } from '../utils/Alert-msg'
import { DUMMY_USER } from '../utils/constant'
import { Path } from '../containers/config'

export default class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            tagType: 'password',
            icon: 'fa-eye',
            regName: '',
            regEmail: '',
            regNumber: '',
            regPassword: '',
            regUserType: '',

            isPasswordExpired: '',
        }
    }

    handleShowPassword = () => {
        if (this.state.tagType === 'password') {
            this.setState({
                tagType: 'text',
                icon: 'fa-eye-slash',
            })
        } else {
            this.setState({
                tagType: 'password',
                icon: 'fa-eye',
            })
        }
    }

    handleChange = (event) => {
        console.log(
            '---ID---',
            event.target.id,
            '---value---',
            event.target.value,
        )
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()

        if (event.target.id === 'reg') {
            console.log(
                this.state.regName,
                this.state.regEmail,
                this.state.regNumber,
                this.state.regPassword,
                this.state.regUserType,
            )
        } else if (event.target.id === 'login') {
            if (this.state.username === 'SuperAdmin') {
                let a = () => {
                    let dummy = false
                    let user = null

                    DUMMY_USER.map((i) => {
                        console.log(
                            'c',
                            i.username,
                            'd',
                            this.state.username,
                            'e',
                            i.password,
                            'f',
                            this.state.password,
                        )
                        if (
                            i.username == this.state.username &&
                            i.password == this.state.password
                        ) {
                            dummy = true
                            user = i
                        }
                    })
                    return { dummy: dummy, user: user }
                }
                let c = a()
                console.log('dummy', c)

                if (c.dummy) {
                    this.props.userHasAuthenticated(c.dummy, c.user)
                    this.props.history.push(Path.index)
                } else {
                    toastNotify('error', 'Credential missmatch')
                }
            } else {
                Auth.signin(this.state.username, this.state.password).then(
                    (response) => {
                        this.props.userHasAuthenticated(
                            response[0],
                            response[1],
                        )
                        this.setState({
                            spinner: false,
                        })

                        if (response[0]) {
                            this.props.history.push(Path.index)
                        } else {
                            if (
                                response[1] &&
                                response[1].error &&
                                response[1].error.errorCode
                            ) {
                                if (response[1].error.errorCode === 2005) {
                                    alert(response[1].error.errorMessage)
                                    this.setState({
                                        isPasswordExpired: true,
                                    })
                                } else {
                                    toastNotify(
                                        'error',
                                        response[1].error.errorMessage,
                                    )
                                }
                            } else {
                                toastNotify(
                                    'error',
                                    'Server Down! Contact Admin',
                                )
                            }
                        }
                    },
                )
            }
        }
    }
    render() {
        return (
            <>
                <div className="container-fluid l-bg">
                    <div className="row justify-content-center">
                        <div className="col-auto login-center">
                            <div className="card shadow m-auto">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 col-12 h-100 l-img">
                                            <img src="assets/images/eDoc-login.png" />
                                        </div>
                                        <div className="col-md-6 col-12 h-100 text-center">
                                            <h2>Welcome</h2>
                                            <form
                                                className="text-left"
                                                autoComplete="off"
                                                id="login"
                                                onSubmit={this.handleSubmit}
                                            >
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Username
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="username"
                                                        aria-describedby="emailHelp"
                                                        required
                                                        placeholder="e.g. Jhon"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label for="exampleInputPassword1">
                                                        Password
                                                    </label>
                                                    <div className=" input-group">
                                                        <input
                                                            id="password"
                                                            type={
                                                                this.state
                                                                    .tagType
                                                            }
                                                            className="form-control "
                                                            required
                                                            placeholder="*******"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <div className="input-group-prepend">
                                                            <span
                                                                className="input-group-text text-origin "
                                                                id="inputGroup-sizing-default"
                                                                onClick={
                                                                    this
                                                                        .handleShowPassword
                                                                }
                                                            >
                                                                <i
                                                                    toggle="#password"
                                                                    className={`fa ${this.state.icon}  toggle-password`}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button
                                                    type="submit"
                                                    className="btn btn-outline-success btn-block"
                                                >
                                                    Login
                                                </button>
                                            </form>
                                            <br />
                                            <div className="text-center">
                                                <small>
                                                    New here?{' '}
                                                    <Link
                                                        className=""
                                                        to={Path.registerPage}
                                                    >
                                                        Create an Account!
                                                    </Link>{' '}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid footer">
                    Developed by{' '}
                    <a href="https://www.tirzok.com/" target="_blank">
                        Tirzok Private Limited
                    </a>
                </div>
            </>
        )
    }
}
