import { Link } from '@mui/material'
import { ErrorMessage } from 'formik'
import React, { Component } from 'react'
import FileUpload from '../components/file-upload'
import { DateFormater, DateTimeFormater } from '../utils/event-handlers'

export function EarlyFundingEntityHtml(props) {
    const { errors, touched } = props
    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Ref*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field36 ? 'is-invalid ' : ''
                        }`}
                        id="field36"
                        required={props.required}
                        value={state.field36}
                        disabled={props.disabled}
                        placeholder="Ref*"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Receiving Date*</label>
                    <input
                        type="date"
                        className={`form-control form-control-sm ${
                            errors.field37 ? 'is-invalid ' : ''
                        }`}
                        id="field37"
                        required={props.required}
                        value={state.field37}
                        disabled={props.disabled}
                        placeholder="Receiving Date*"
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Value discounted*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field38 ? 'is-invalid ' : ''
                        }`}
                        id="field38"
                        required={props.required}
                        value={state.field38}
                        disabled={props.disabled}
                        placeholder="Value discounted*"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Comment*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field39 ? 'is-invalid ' : ''
                        }`}
                        id="field39"
                        required={props.required}
                        value={state.field39}
                        disabled={props.disabled}
                        placeholder="Comment*"
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function BusinessServicesCentreHtml(props) {
    const { errors, touched } = props
    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Docs Receiving Date*</label>
                    <input
                        type="date"
                        className={`form-control form-control-sm ${
                            errors.field34 ? 'is-invalid ' : ''
                        }`}
                        id="field34"
                        required={props.required}
                        value={state.field34}
                        disabled={props.disabled}
                        placeholder="Docs Receiving Date*"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Comment*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field35 ? 'is-invalid ' : ''
                        }`}
                        id="field35"
                        required={props.required}
                        value={state.field35}
                        disabled={props.disabled}
                        placeholder="Comment*"
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function CourierAgencyHtml(props) {
    const { errors, touched } = props
    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>IGM Ref*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field32 ? 'is-invalid ' : ''
                        }`}
                        id="field32"
                        required={props.required}
                        value={state.field32}
                        disabled={props.disabled}
                        placeholder="IGM Ref*"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>IGM Date*</label>
                    <input
                        type="date"
                        className={`form-control form-control-sm ${
                            errors.field33 ? 'is-invalid ' : ''
                        }`}
                        id="field33"
                        required={props.required}
                        value={state.field33}
                        disabled={props.disabled}
                        placeholder="IGM Date*"
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function CustomsAtDestinationHtml(props) {
    const { errors, touched } = props
    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>IGM Ref*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field32 ? 'is-invalid ' : ''
                        }`}
                        id="field32"
                        required={props.required}
                        value={state.field32}
                        disabled={props.disabled}
                        placeholder="IGM Ref*"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>IGM Date*</label>
                    <input
                        type="date"
                        className={`form-control form-control-sm ${
                            errors.field33 ? 'is-invalid ' : ''
                        }`}
                        id="field33"
                        required={props.required}
                        value={state.field33}
                        disabled={props.disabled}
                        placeholder="IGM Date*"
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function CustomsAtOriginHtml(props) {
    const { errors, touched } = props
    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>IGM Ref*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field32 ? 'is-invalid ' : ''
                        }`}
                        id="field32"
                        required={props.required}
                        value={state.field32}
                        disabled={props.disabled}
                        placeholder="IGM Ref*"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>IGM Date*</label>
                    <input
                        type="date"
                        className={`form-control form-control-sm ${
                            errors.field33 ? 'is-invalid ' : ''
                        }`}
                        id="field33"
                        required={props.required}
                        value={state.field33}
                        disabled={props.disabled}
                        placeholder="IGM Date*"
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function ForwarderAtDestinationHtml(props) {
    const { errors, touched } = props
    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>IGM Ref*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field32 ? 'is-invalid ' : ''
                        }`}
                        id="field32"
                        required={props.required}
                        value={state.field32}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>IGM Date*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field33 ? 'is-invalid ' : ''
                        }`}
                        id="field33"
                        required={props.required}
                        value={state.field33}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function BankIntermediaryByVendorHtml(props) {
    const { errors, touched } = props

    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Bank Ref*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field29 ? 'is-invalid ' : ''
                        }`}
                        id="field29"
                        required={props.required}
                        value={state.field29}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Receiving Date*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field30 ? 'is-invalid ' : ''
                        }`}
                        id="field30"
                        required={props.required}
                        value={state.field30}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Comment*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field31 ? 'is-invalid ' : ''
                        }`}
                        id="field31"
                        required={props.required}
                        value={state.field31}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function BankAtOriginByVendorHtml(props) {
    const { errors, touched } = props

    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Bank Ref*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field26 ? 'is-invalid ' : ''
                        }`}
                        id="field26"
                        required={props.required}
                        value={state.field26}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Receiving Date*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field27 ? 'is-invalid ' : ''
                        }`}
                        id="field27"
                        required={props.required}
                        value={state.field27}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Comment*</label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        id="field28"
                        required={props.required}
                        value={state.field28}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function ForwardAtOriginHtml(props) {
    const { errors, touched } = props

    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Container Ref*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field24 ? 'is-invalid ' : ''
                        }`}
                        id="field24"
                        required={props.required}
                        value={state.field24}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Shipper's Vessel Clearance List*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field25 ? 'is-invalid ' : ''
                        }`}
                        id="field25"
                        required={props.required}
                        value={state.field25}
                        disabled={props.disabled}
                        placeholder=""
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function CFSHtml(props) {
    const { errors, touched } = props
    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Terminal Receipt Ref</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field17 ? 'is-invalid ' : ''
                        }`}
                        id="field17"
                        required={props.required}
                        value={state.field17}
                        disabled={props.disabled}
                        placeholder="Terminal Receipt Ref"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Cargo Receive Date</label>
                    <input
                        type="date"
                        className={`form-control form-control-sm ${
                            errors.field18 ? 'is-invalid ' : ''
                        }`}
                        id="field18"
                        required={props.required}
                        value={state.field18}
                        disabled={props.disabled}
                        placeholder="Cargo Receive Date"
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Bill of Entry Ref</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field19 ? 'is-invalid ' : ''
                        }`}
                        id="field19"
                        required={props.required}
                        value={state.field19}
                        disabled={props.disabled}
                        placeholder="Bill of Entry Ref"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>BOE Date</label>
                    <input
                        type="date"
                        className={`form-control form-control-sm ${
                            errors.field20 ? 'is-invalid ' : ''
                        }`}
                        id="field20"
                        required={props.required}
                        value={state.field20}
                        disabled={props.disabled}
                        placeholder="BOE Date"
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Cargo Stuffing Report</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field21 ? 'is-invalid ' : ''
                        }`}
                        id="field21"
                        required={props.required}
                        value={state.field21}
                        disabled={props.disabled}
                        placeholder="Cargo Stuffing Report"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Equipment Interchange Receipt ref</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field22 ? 'is-invalid ' : ''
                        }`}
                        id="field22"
                        required={props.required}
                        value={state.field22}
                        disabled={props.disabled}
                        placeholder="Equipment Interchange Receipt ref"
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>EIR</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field23 ? 'is-invalid ' : ''
                        }`}
                        id="field23"
                        required={props.required}
                        value={state.field23}
                        disabled={props.disabled}
                        placeholder="EIR"
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function SourcingHtml(props) {
    const { errors, touched } = props
    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
    } = props.this
    return (
        <>
            <div className="form-group row mt-1">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Comment</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field16 ? 'is-invalid ' : ''
                        }`}
                        id="field16"
                        required={props.required}
                        value={state.field16}
                        disabled={props.disabled}
                        placeholder="Comment"
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export function VendorHtml(props) {
    const {
        handleChange,
        state,
        handleFileDownload,
        removeFile,
        cancelButtonOF,
        handleUpdate,
    } = props.this
    const { revisedUnitPrice, unitPrice } = props.this.props.data
    const { poUnintUpdate } = props.this.props
    const { errors, touched } = props
    const unitPriceChange = () => {
        if (revisedUnitPrice !== null) {
            return !(revisedUnitPrice == unitPrice)
        }
        return false
    }

    return (
        <>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>PO ref*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field1 ? 'is-invalid ' : ''
                        }`}
                        id="field1"
                        required={props.required}
                        value={state.field1}
                        placeholder="PO ref*"
                        onChange={handleChange}
                        disabled={true}
                    />
                    <ErrorMessage
                        component="div"
                        name="field1"
                        className="invalid-feedback"
                    />
                </div>
                <div className="col-sm-6">
                    <label>Unit Price*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field2 ? 'is-invalid ' : ''
                        }`}
                        id="field2"
                        required={props.required}
                        value={state.field2}
                        placeholder="Unit Price*"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                    {poUnintUpdate && unitPriceChange() ? (
                        <small className="text-danger">
                            Po revised unit price is {revisedUnitPrice}.{' '}
                            <Link
                                id="field2"
                                onClick={(e) => {
                                    handleUpdate(e, revisedUnitPrice)
                                }}
                            >
                                Update the price{' '}
                                <i className="fa fa-refresh"></i>
                            </Link>{' '}
                        </small>
                    ) : null}
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Invoice ref</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field3 ? 'is-invalid ' : ''
                        }`}
                        id="field3"
                        required={props.required}
                        value={state.field3}
                        placeholder="Invoice ref"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
                <div className="col-sm-6">
                    <div
                        className={` ${
                            touched.field4 && errors.field4
                                ? 'border border-danger is-invalid'
                                : ''
                        }`}
                    >
                        <FileUpload
                            modeStatus={state.field4}
                            label="Invoice*"
                            id="field4"
                            this={props.this}
                            value={state.field4}
                            uploadPercentage={state.uploadPercentage}
                            handleFileDownload={handleFileDownload}
                            removeFile={removeFile}
                            handleChange={handleChange}
                            called={state.called}
                            errors={errors}
                            touched={touched}
                            uploadButtonOff={props.disabled}
                            cancelButtonOF={props.disabled}
                        />
                        <small className="text-danger mt-1">
                            {' '}
                            {
                                'Valid format .csv, .txt, .xls, .xlsx, .xlsb, .xlsx, .pdf, .doc, .docx'
                            }
                        </small>
                    </div>

                    <ErrorMessage
                        component="div"
                        name="field4"
                        className="invalid-feedback"
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>EPL ref</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field5 ? 'is-invalid ' : ''
                        }`}
                        id="field5"
                        required={props.required}
                        value={state.field5}
                        placeholder="EPL ref"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <div
                        className={` ${
                            touched.field7 && errors.field6
                                ? 'border border-danger is-invalid'
                                : ''
                        }`}
                    >
                        <FileUpload
                            modeStatus={state.field6}
                            label="EPL"
                            id="field6"
                            this={props.this}
                            value={state.field6}
                            uploadPercentage={state.uploadPercentage}
                            handleFileDownload={handleFileDownload}
                            errors={errors}
                            touched={touched}
                            removeFile={removeFile}
                            handleChange={handleChange}
                            called={state.called}
                            uploadButtonOff={props.disabled}
                            cancelButtonOF={props.disabled}
                        />
                        {/* {state.field6!==0?state.field6.map(i=>
                    <span className='c-span-round'>
                    <span className='c-link' style={{'textAlign':'center'}} id={i} value={i} onClick={handleFileDownload}   htmlFor={props.id}>{i.split(/_(.+)/)[1]}</span>
                     <span><button type='button' className='  pl-1 c-link ' value={i}  id={'field6'} disabled={cancelButtonOF}   onClick={(event)=>{removeFile(event)}}> x</button></span>
                    </span>
                    
                    ):null} */}
                    </div>
                    <small className="text-danger mt-1">
                        {' '}
                        {
                            'Valid format .csv, .txt, .xls, .xlsx, .xlsb, .xlsx, .pdf, .doc, .docx'
                        }
                    </small>

                    <ErrorMessage
                        component="div"
                        name="field6"
                        className="invalid-feedback"
                    />
                </div>
            </div>

            {/* <!-- Docmunet Input Field --> */}
            <div className="form-group mt-4 row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <div
                        className={` ${
                            touched.field7 && errors.field7
                                ? 'border border-danger is-invalid'
                                : ''
                        }`}
                    >
                        <FileUpload
                            modeStatus={state.field7}
                            label="QA Audit Report"
                            id="field7"
                            value={state.field7}
                            uploadPercentage={state.uploadPercentage}
                            handleFileDownload={handleFileDownload}
                            errors={errors}
                            touched={touched}
                            removeFile={removeFile}
                            handleChange={handleChange}
                            called={state.called}
                            uploadButtonOff={props.disabled}
                            cancelButtonOF={props.disabled}
                        />
                        <small className="text-danger mt-1">
                            {' '}
                            {
                                'Valid format .csv, .txt, .xls, .xlsx, .xlsb, .xlsx, .pdf, .doc, .docx'
                            }
                        </small>
                    </div>
                    <ErrorMessage
                        component="div"
                        name="field7"
                        className="invalid-feedback"
                    />
                </div>
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>FOB Date*</label>
                    <input
                        type="date"
                        className={`form-control form-control-sm ${
                            errors.field8 ? 'is-invalid ' : ''
                        }`}
                        id="field8"
                        required={props.required}
                        value={state.field8}
                        placeholder="FOB Date*"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Booking Id</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field9 ? 'is-invalid ' : ''
                        }`}
                        id="field9"
                        required={props.required}
                        value={state.field9}
                        placeholder="Booking Id"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
                <div className="col-sm-6">
                    <label>Destination*</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field10 ? 'is-invalid ' : ''
                        }`}
                        id="field10"
                        required={props.required}
                        value={state.field10}
                        placeholder="Destination"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>FOB Point</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field11 ? 'is-invalid ' : ''
                        }`}
                        id="field11"
                        required={props.required}
                        value={state.field11}
                        placeholder="FOB Point"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
                <div className="col-sm-6">
                    <label>Port of Loading</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field12 ? 'is-invalid ' : ''
                        }`}
                        id="field12"
                        required={props.required}
                        value={state.field12}
                        placeholder="Port of Loading"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Shipper</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field13 ? 'is-invalid ' : ''
                        }`}
                        id="field13"
                        required={props.required}
                        value={state.field13}
                        placeholder="Shipper"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
                <div className="col-sm-6">
                    <label>Consignee</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field14 ? 'is-invalid ' : ''
                        }`}
                        id="field14"
                        required={props.required}
                        value={state.field14}
                        placeholder="Consignee"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Notify Party</label>
                    <input
                        type="text"
                        className={`form-control form-control-sm ${
                            errors.field15 ? 'is-invalid ' : ''
                        }`}
                        id="field15"
                        required={props.required}
                        value={state.field15}
                        placeholder="Notify Party"
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </div>
            </div>
        </>
    )
}
