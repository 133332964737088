import axios from 'axios'
import { Component } from 'react'
import { API_AUTH } from '../api/config'
import { API } from '../containers/config'
import { toastNotify } from './Alert-msg'
import { TOUCH_POINT } from './constant'

export const handleEdit = (event, props) => {
    props.setState({
        modeStatus: 'EDIT',
        [event.target.id]: true,
    })
}

export class SetupDashBoardHandlerClass extends Component {
    handleClose = (event) => {
        console.log('event', event)

        if (event !== undefined) {
            this.setState({
                [event.target.id]: false,
            })
        }
    }

    handleSelectedRow = (row, isSelect, rowIndex, e) => {
        console.log(
            'row====>',
            row,
            'isSelected=>>>>',
            isSelect,
            'rowIndex=>>>>>>>',
            rowIndex,
            'Event=>>>>',
            e.target,
        )
        if (e.target.id === 'approved') {
            this.refresh()
        } else if (e.target.id === 'reject') {
            this.refresh()
        } else if (e.target.id === 'view') {
            this.setState({
                selectedData: row,
                selectedRow: rowIndex,
                show: true,
                edit: false,
            })
        } else if (e.target.id === 'edit') {
            this.setState({
                selectedData: row,
                selectedRow: rowIndex,
                show: true,
                edit: true,
            })
        }
    }

    handleShow = (event) => {
        this.setState({
            [event.target.id]: true,
        })
    }
}

export function DateTimeFormater(date) {
    return (
        new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
            new Date(date),
        ) +
        ' ' +
        new Intl.DateTimeFormat('en', { month: 'short' }).format(
            new Date(date),
        ) +
        ' ' +
        new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
            new Date(date),
        )
    )
}

export function DateFormater(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

export function getOrganizationList() {
    return axios.get(API.organizationList).catch((error) => AlertError(error))
}

export function getPOPendingCount() {
    return axios.get(API.poPendingCount).catch((error) => AlertError(error))
}

export function getCompanyPendingCount() {
    return axios
        .get(API.companyPendingCount)
        .catch((error) => AlertError(error))
}

export function getUserPendingCount() {
    return axios.get(API.userPendingCount).catch((error) => AlertError(error))
}

export function getTouchPointList(orgId) {
    let queryParamString = {}
    if (orgId != null && orgId !== undefined && orgId != '') {
        queryParamString = { orgId: orgId }
    }

    return axios
        .get(API.touchPointList, queryParamString)
        .catch((error) => AlertError(error))
}

export function getForm(form) {
    return axios.get(API.formData + form).catch((error) => AlertError(error))
}

export function getCreatedCompanyList(status, touchPointCode) {
    let queryParamString = {}

    queryParamString = { status: status, orgId: 1 }
    if (touchPointCode) {
        queryParamString['touchPointCode'] = touchPointCode
    }

    if (status === 'APPROVED') {
        return axios
            .get(API.createdCompanyListAll, {
                params: queryParamString,
            })
            .catch((error) => AlertError(error))
    }

    return axios
        .get(API.createdCompanyList, {
            params: queryParamString,
        })
        .catch((error) => AlertError(error))
}

export function companyStatusChange(data, refresh) {
    return axios
        .post(API.companyStatusChange, data, {
            headers: { 'XTR-API-Action': 'UPDATE' },
        })
        .then((res) => {
            if (res && res.status === 200) {
                toastNotify('success', res.data.message)
                refresh()
            }
        })
        .catch((error) => AlertError(error))
}

export function rejectPO(data) {
    return axios.post(API.rejectPO, data).catch((error) => AlertError(error))
}

export function creatPO(data) {
    return axios
        .post(API.createPO, data, {
            headers: { 'XTR-API-Action': 'UPDATE' },
        })
        .catch((error) => AlertError(error))
}

export function createCompany(data, status) {
    console.log('company', data)
    if (status) {
        return axios
            .post(API.createCompany, data, {
                headers: { 'XTR-API-Action': 'UPDATE' },
            })
            .catch((error) => AlertError(error))
    } else {
        return axios
            .post(API.createCompanySignup, data, {
                headers: { 'XTR-API-Action': 'CREATE' },
            })
            .catch((error) => AlertError(error))
    }
}

export function userStatusChange(data, refresh) {
    console.log('pp', data)
    return axios
        .post(API.userStatusChange, data, {
            headers: { 'XTR-API-Action': 'UPDATE' },
        })
        .then((res) => {
            if (res && res.status === 200) {
                toastNotify('success', res.data.message)
                refresh()
            }
        })
        .catch((error) => AlertError(error))
}

export function getCreatedUserList(status) {
    let queryParamString = {}

    queryParamString = { status: status, orgId: 1 }

    return axios
        .get(API.createdUserList, {
            params: queryParamString,
        })
        .catch((error) => AlertError(error))
}

export function getUserDetails(id) {
    return axios
        .get(API.createdUserList + id)
        .catch((error) => AlertError(error))
}

export function createUser(data, status) {
    if (status) {
        return axios
            .post(API.createUser, data, {
                headers: { 'XTR-API-Action': 'UPDATE' },
            })
            .catch((error) => AlertError(error))
    } else {
        return axios
            .post(API.createUser, data, {
                headers: { 'XTR-API-Action': 'CREATE' },
            })
            .catch((error) => AlertError(error))
    }
}

export function getRoleList() {
    return axios.get(API.roleList).catch((error) => AlertError(error))
}

export function getPOList(path) {
    return axios.get(API.poList + path).catch((error) => AlertError(error))
}

export function getPoInformation(id) {
    return axios
        .get(API.touchPointPOInfo + id)
        .catch((error) => AlertError(error))
}

export function getTouchPointPOInfo(poId, touchPointID) {
    return axios
        .get(API.touchPointPOInfo + poId + '/' + touchPointID)
        .catch((error) => AlertError(error))
}

export function getTemplateSequance(data) {
    return axios
        .get(API.touchPointSequence, { params: data })
        .catch((error) => AlertError(error))
}

export function getPoActivityList(status) {
    let queryParamString = {}

    return axios.get(API.poActivityList).catch((error) => AlertError(error))
}

export function AlertError(error) {
    if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
    ) {
        toastNotify('error', error.response.data.errorMessage)
    } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
    ) {
        toastNotify('error', error.response.data.message)
    } else if (error && error.response && error.response.message) {
        toastNotify('error', error.reponse.message)
    } else {
        toastNotify('error', 'Server Down! Contact Admin')
    }
}

export function addIsCheckedField(value) {
    let data = []
    value.map((i, index) => {
        if (index === 0) {
            data.push({ ...i, checked: true })
        } else {
            data.push({ ...i, checked: false })
        }
    })
    console.log('checked func', data)
    return data
}

export function getSingleSelectedValue(array, value) {
    console.log('dataproduct income', array, value)
    let data = []

    for (let i = 0; i < array.length; i++) {
        if (array[i].checked === true) {
            return array[i][value]
        }
    }
    return null
}

export function objectHasKeyValueParser({ obj: obj, key: key, type: type }) {
    // console.log('obj', obj, key, obj[key], type)

    if (type === 'text') {
        if (obj[key] === undefined) {
            return ''
        }
    }
    if (type === 'file') {
        if (obj[key] === undefined) {
            return []
        }
    }

    return obj[key]
}

// log out all page
export function broserSignOutChecker() {
    if (localStorage.getItem('user-session') === 'signout') {
        localStorage.removeItem('scem-session')

        alert('User is not signed in')

        window.location.reload()
    }
}

export function modelClose(event) {
    if (event !== undefined) {
        this.setState({
            [event.target.id]: false,
        })
    }
}

export function isEqual(a, b) {
    if (a == b) {
        return true
    } else {
        return false
    }
}

export function uploadFile(file, option) {
    return axios
        .post(API.fileUpload, file, option, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .catch((error) => AlertError(error))
}

export function uploadPOFile(file, option) {
    return axios
        .post(API.poFileUpload, file, option, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .catch((error) => AlertError(error))
}

export function downloadFile(file) {
    // file=file.split("/cs/")[1]
    return axios
        .get(API.fileDownload, {
            params: { fileToDownload: file },
            responseType: 'blob',
        })
        .then((response) => {
            console.log('res', response)
            let filename = ''
            let blob = new Blob([response.data]),
                downloadUrl = window.URL.createObjectURL(blob),
                // filename = `${
                //     file.includes('_') ? file.split(/_(.+)/)[1] : file
                // }`,
                disposition = response.headers['content-disposition']

            if (disposition && disposition.indexOf('attachment') !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(disposition)

                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '')
                }
            }

            let a = document.createElement('a')
            if (typeof a.download === 'undefined') {
                window.location.href = downloadUrl
            } else {
                a.href = downloadUrl
                a.download = filename
                document.body.appendChild(a)
                a.click()
            }
        })
        .catch((error) => AlertError(error))
}

export class PoHandlerClass extends Component {
    componentDidUpdate() {
        const el = document.querySelector('.scroll5')
        console.log(el.scrollLeft, el.scrollTop)
        let load = 0
        load = TOUCH_POINT.filter(
            (i, index) => i.id === this.props.data.expectedTouchPointState,
        )
        // console.log('load',(413/14*load[0].id))
        if (load.length === 0) {
            el.scrollLeft = 1000
        } else {
            el.scrollLeft = (413 / 14) * load[0].id
        }
    }

    handleUpdate = (event, value) => {
        this.setState({
            [event.target.id]: value,
        })
    }

    handleChange = (event) => {
        console.log('press handleChange', event.target.id, event.target.value)
        if (event.target.type === 'file') {
            let fileSize = event.target.files[0].size / 1048576
            if (this.state[event.target.id].length >= 10) {
                toastNotify('warn', 'Can not upload more than 10 files')
                return
            }
            if (fileSize > 50) {
                toastNotify('error', 'File size can not be grater than 50mb')
                return
            }
            console.log('who', event.target.files[0])
            let blnValid = false
            let _validFileExtensions = [
                '.csv',
                '.txt',
                '.xls',
                '.xlsx',
                '.xlsb',
                '.xlsx',
                '.pdf',
                '.doc',
                '.docx',
            ]
            // if()
            for (var j = 0; j < _validFileExtensions.length; j++) {
                var sCurExtension = _validFileExtensions[j]
                if (
                    event.target.files[0].name
                        .substr(
                            event.target.files[0].name.length -
                                sCurExtension.length,
                            sCurExtension.length,
                        )
                        .toLowerCase() == sCurExtension.toLowerCase()
                ) {
                    blnValid = true
                    break
                }
            }

            if (!blnValid) {
                toastNotify('warn', 'File extension missmatch')
            } else {
                const data = new FormData()
                this.setState({
                    called: event.target.id,
                    spinner: true,
                })
                const option = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent
                        let percent = Math.floor((loaded * 100) / total)

                        if (percent < 100) {
                            this.setState({
                                uploadPercentage: percent,
                            })
                        }
                    },
                }

                data.append('fileToUpload', event.target.files[0])

                uploadFile(data, option).then((res) => {
                    if (
                        (res !== undefined && res.status === 200) ||
                        (res !== undefined && res.status === 201)
                    ) {
                        let data = this.state[event.target.id]
                        data.push(res.data)
                        this.setState(
                            {
                                uploadPercentage: 100,
                                [event.target.id]: data,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({ uploadPercentage: 0 })
                                    toastNotify(
                                        'success',
                                        'File uploaded successfully',
                                    )
                                }, 1000)
                            },
                        )
                    }
                    this.setState({
                        spinner: false,
                    })
                })
            }
        } else {
            this.setState({
                [event.target.id]: event.target.value,
            })
        }
    }

    handleFileDownload = (event) => {
        // let data =this.state[event.target.id]
        let data = event.target.id

        console.log('filetodownload', event.target)

        downloadFile(data)
    }
    removeFile = (event) => {
        console.log(
            'dataA',
            this.state[event.target.id].filter((i) => i != event.target.value),
        )
        this.setState({
            [event.target.id]: this.state[event.target.id].filter(
                (i) => i != event.target.value,
            ),
        })
    }
}

export function getSelectedLength(array) {
    console.log(array)
    let count = 0
    for (let i = 0; i < array.length; i++) {
        if (array[i].checked === true) {
            count = count + 1
        }
    }
    return count
}

export function handleShow(props, event) {
    props.setState({
        [event.target.id]: true,
    })
}
export function checkRole({ roleArray: roleArray, roleName: roleName }) {
    return roleArray.some((i) => i.includes(roleName))
}
