import React, { Component } from 'react'
import {
    BUSINESS_CURRENCY,
    PAYMENT_TYPE,
    EVENT_MODEL_CLOSE,
    getCompanyPendingList,
    getCompanyList,
} from '../../utils/constant'
import {
    addIsCheckedField,
    createCompany,
    downloadFile,
    getCreatedCompanyList,
    getOrganizationList,
    getTouchPointList,
    objectHasKeyValueParser,
    SetupDashBoardHandlerClass,
    uploadFile,
} from '../../utils/event-handlers'
import Dropdown from 'react-dropdown'
import FileUpload from '../../components/file-upload'
import 'react-dropdown/style.css'
import { toastNotify } from '../../utils/Alert-msg'
import { Formik, Form, ErrorMessage } from 'formik'
import { ModalCommon } from '../../components/modal'
import ComUserPagination from './com&user-pagintation-table'
import { getApiCallDynamic } from '../../service'
import { API } from '../config'

export class CompanyRegistration extends Component {
    constructor(props) {
        super(props)
        console.log('coma', this.props)

        this.state = {
            uploadPercentage: 0,
            id: this.props.status ? this.props.data.id : null,
            name: this.props.status ? this.props.data.name : '',
            code: this.props.status ? this.props.data.code : '',
            companyLocation: this.props.status
                ? this.props.data.companyLocation
                : '',
            factoryLocation: this.props.status
                ? this.props.data.factoryLocation
                : '',
            portOfOrigin: this.props.status ? this.props.data.portOfOrigin : '',
            businessCurrency: this.props.status
                ? this.props.data.businessCurrency
                : '',
            paymentType: this.props.status ? this.props.data.paymentType : '',

            creditTermInDays: this.props.status
                ? this.props.data.creditTermInDays
                : '',
            companyCertificate: this.props.status
                ? this.props.data.docs !== null
                    ? objectHasKeyValueParser({
                          obj: this.props.data.docs,
                          key: 'companyCertificate',
                          type: 'file',
                      })
                    : []
                : [],
            orgId: this.props.status ? this.props.data.orgId : '',
            orgName: this.props.status ? this.props.data.orgName : '',
            tpName: this.props.status ? this.props.data.tpName : '',
            tpId: this.props.status ? this.props.data.tpId : '',
            organizationList: [],
            touchPointList: [],
        }
    }

    resetFormField = () => {
        this.setState({
            uploadPercentage: 0,
            id: this.props.status ? this.props.data.id : null,
            name: this.props.status ? this.props.data.name : '',
            code: this.props.status ? this.props.data.code : '',
            companyLocation: this.props.status
                ? this.props.data.companyLocation
                : '',
            factoryLocation: this.props.status
                ? this.props.data.factoryLocation
                : '',
            portOfOrigin: this.props.status ? this.props.data.portOfOrigin : '',
            businessCurrency: this.props.status
                ? this.props.data.businessCurrency
                : '',
            paymentType: this.props.status ? this.props.data.paymentType : '',

            creditTermInDays: this.props.status
                ? this.props.data.creditTermInDays
                : '',
            companyCertificate: this.props.status
                ? this.props.data.docs !== null
                    ? objectHasKeyValueParser({
                          obj: this.props.data.docs,
                          key: 'companyCertificate',
                          type: 'file',
                      })
                    : []
                : [],
            orgId: this.props.status ? this.props.data.orgId : '',
            orgName: this.props.status ? this.props.data.orgName : '',
            tpName: this.props.status ? this.props.data.tpName : '',
            tpId: this.props.status ? this.props.data.tpId : '',
            organizationList: [],
            touchPointList: [],
        })
        if (this.props.outside) {
            window.location.reload()
        }
        this.refresh()
    }

    handleFileDownload = (event) => {
        let data = event.target.id

        console.log('filetodownload', event.target)

        downloadFile(data)
    }

    removeFile = (event) => {
        console.log(
            'dataA',
            this.state[event.target.id].filter((i) => i != event.target.value),
        )
        this.setState({
            [event.target.id]: this.state[event.target.id].filter(
                (i) => i != event.target.value,
            ),
        })
    }

    handleChange = (event) => {
        if (event.target.type === 'file') {
            let fileSize = event.target.files[0].size / 1048576
            if (this.state[event.target.id].length >= 1) {
                toastNotify('warn', 'Can not upload more than 1 files')
                return
            }
            if (fileSize > 1) {
                toastNotify('error', 'File size can not be grater than 1mb')
                return
            }
            console.log('who', event.target.files[0])
            let blnValid = false
            let _validFileExtensions = [
                '.jpg',
                '.jpeg',
                '.png',
                '.pdf',
                '.doc',
                '.docx',
            ]

            for (var j = 0; j < _validFileExtensions.length; j++) {
                var sCurExtension = _validFileExtensions[j]
                if (
                    event.target.files[0].name
                        .substr(
                            event.target.files[0].name.length -
                                sCurExtension.length,
                            sCurExtension.length,
                        )
                        .toLowerCase() == sCurExtension.toLowerCase()
                ) {
                    blnValid = true
                    break
                }
            }

            if (!blnValid) {
                toastNotify('warn', 'File extension missmatch')
            } else {
                const data = new FormData()
                this.setState({
                    called: event.target.id,
                    spinner: true,
                })
                const option = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent
                        let percent = Math.floor((loaded * 100) / total)

                        if (percent < 100) {
                            this.setState({
                                uploadPercentage: percent,
                            })
                        }
                    },
                }

                data.append('fileToUpload', event.target.files[0])

                uploadFile(data, option).then((res) => {
                    if (
                        (res !== undefined && res.status === 200) ||
                        (res !== undefined && res.status === 201)
                    ) {
                        let data = this.state[event.target.id]
                        data.push(res.data)
                        this.setState(
                            {
                                uploadPercentage: 100,
                                [event.target.id]: data,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({
                                        uploadPercentage: 0,
                                    })
                                    toastNotify(
                                        'success',
                                        'File uploaded successfully',
                                    )
                                }, 1000)
                            },
                        )
                    }
                    this.setState({
                        spinner: false,
                    })
                })
            }
        } else {
            this.setState({
                [event.target.id]: event.target.value,
            })
        }
    }

    handleDropDownChange = (val, name) => (event) => {
        console.log(event)
        this.state[val] = event.value
        this.state[name] = event.label

        this.setState()

        if (val === 'orgId') {
            getTouchPointList(event.value)
                .then((res) => {
                    if (res !== undefined && res.status === 200) {
                        this.setState({
                            touchPointList: addIsCheckedField(res.data),
                        })
                        console.log(this.state.touchPointList)
                    }
                })
                .catch((err) => err)
        }
    }

    handleSubmit = () => {
        let data = {
            id: this.state.id,
            name: this.state.name,
            code: this.state.code,
            companyLocation: this.state.companyLocation,
            factoryLocation: this.state.factoryLocation,
            portOfOrigin: this.state.portOfOrigin,
            businessCurrency: this.state.businessCurrency,
            paymentType: this.state.paymentType,
            creditTermInDays: this.state.creditTermInDays,
            orgId: this.state.orgId,
            tpId: this.state.tpId,
            docs: { companyCertificate: this.state.companyCertificate },
        }
        console.log(JSON.stringify(this.state))

        createCompany(data, this.props.status).then((res) => {
            if (res !== undefined) {
                this.setState({
                    spinner: false,
                })

                if (
                    res.status === 201 ||
                    res.status === 202 ||
                    res.status === 200
                ) {
                    if (this.props.status) {
                        toastNotify('success', 'Company updated successfully')
                        if (this.props.call === 'modal') {
                            this.props.handleClose(EVENT_MODEL_CLOSE)
                            this.props.refresh()
                        }
                    } else {
                        toastNotify('success', 'Company created successfully')
                        this.resetFormField()
                    }
                }
            }
            this.setState({
                spinner: false,
            })
        })
    }

    handleValidation = () => {
        let errors = {}
        if (this.state.orgId === '') {
            errors.orgId = 'Please enter organization'
        }
        if (this.state.tpId === '') {
            errors.tpId = 'Please enter touch point'
        }
        if (this.state.name === '') {
            errors.name = 'Please enter name'
        }
        if (this.state.code === '') {
            errors.code = 'Please enter id'
        }
        if (this.state.companyLocation === '') {
            errors.companyLocation = 'Please enter  location'
        }
        if (this.state.factoryLocation === '') {
            errors.factoryLocation = 'Please enter  location'
        }
        if (this.state.portOfOrigin === '') {
            errors.portOfOrigin = 'Please enter'
        }
        if (this.state.businessCurrency === '') {
            errors.businessCurrency = 'Please select an option'
        }
        if (this.state.paymentType === '') {
            errors.paymentType = 'Please select an option'
        }
        if (this.state.creditTermInDays === '') {
            errors.creditTermInDays = 'Please enter value'
        }

        return errors
    }

    refresh = () => {
        getApiCallDynamic({}, API.organizationListAll)
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState(
                        {
                            organizationList: res.data,
                            orgId: res.data[0].id,
                            orgName: res.data[0].name,
                        },
                        () => {
                            this.getTouchPoint()
                        },
                    )

                    console.log(this.state.organizationList)
                }
            })
            .catch((err) => err)
    }
    getTouchPoint = () => {
        getApiCallDynamic({ orgId: this.state.orgId }, API.touchPointList)
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        touchPointList: addIsCheckedField(res.data),
                    })
                    console.log(this.state.touchPointList)
                }
            })
            .catch((err) => err)
    }
    componentDidMount() {
        this.refresh()
    }

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true, // enable hide selection column.

            clickToSelect: true,
            onSelect: this.handleSelectedRow,
        }

        return (
            <div className="container-fluid ">
                {!this.props.status && !this.props.outside ? (
                    <div className="row">
                        <div className="col-md-auto py-3 m-auto">
                            <h2>
                                {this.props.tittle
                                    ? this.props.tittle
                                    : 'Company Registration'}
                            </h2>
                        </div>
                    </div>
                ) : null}
                {this.props.outside ? (
                    <div className="row">
                        <div className="col-md-auto py-3 m-auto">
                            <div className="text-center">
                                <h2>{'Register'}</h2>
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="row">
                    <div className="col-md-auto m-auto">
                        <div className="card p-3">
                            <Formik
                                initialValues={this.state}
                                validate={(values) =>
                                    this.handleValidation(this, values)
                                }
                                onSubmit={(values, { setSubmitting }) => {
                                    console.log('va', values)
                                    this.handleSubmit()

                                    setSubmitting(false)
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <>
                                        <Form>
                                            {this.props.outside
                                                ? this.props.accountType
                                                : null}
                                            <div className="form-group row">
                                                <div className="col-sm-6">
                                                    <label>
                                                        Organization{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <Dropdown
                                                        controlClassName={`form-control form-control-sm `}
                                                        className={` Dropdown-root-width ${
                                                            errors.orgId
                                                                ? 'border border-danger is-invalid'
                                                                : ''
                                                        } `}
                                                        value={
                                                            this.state.orgName
                                                        }
                                                        id="orgId"
                                                        options={this.state.organizationList.map(
                                                            (i) => {
                                                                return {
                                                                    value: i.id,
                                                                    label: i.name,
                                                                }
                                                            },
                                                        )}
                                                        onChange={this.handleDropDownChange(
                                                            'orgId',
                                                            'orgName',
                                                        )}
                                                        placeholder="Select an option"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="orgId"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label>
                                                        Touch Point{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <Dropdown
                                                        controlClassName={`form-control form-control-sm `}
                                                        className={` Dropdown-root-width ${
                                                            errors.tpId
                                                                ? 'border border-danger is-invalid'
                                                                : ''
                                                        } `}
                                                        value={
                                                            this.state.tpName
                                                        }
                                                        id="tpId"
                                                        options={this.state.touchPointList.map(
                                                            (i) => {
                                                                return {
                                                                    value: i.id,
                                                                    label: i.name,
                                                                }
                                                            },
                                                        )}
                                                        onChange={this.handleDropDownChange(
                                                            'tpId',
                                                            'tpName',
                                                        )}
                                                        placeholder="Select an option"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="tpId"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <label>
                                                        Compay ID{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control form-control-sm ${
                                                            errors.code
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        value={this.state.code}
                                                        id="code"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="CO123"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="code"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label>
                                                        Company Name{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control form-control-sm ${
                                                            errors.name
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        value={this.state.name}
                                                        id="name"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="ABD Example Company"
                                                    />

                                                    <ErrorMessage
                                                        component="div"
                                                        name="name"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <label>
                                                        Company location{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control form-control-sm ${
                                                            errors.companyLocation
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        value={
                                                            this.state
                                                                .companyLocation
                                                        }
                                                        id="companyLocation"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="Road#0, Area#1, City"
                                                    />

                                                    <ErrorMessage
                                                        component="div"
                                                        name="companyLocation"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <label>
                                                        Factory location{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control form-control-sm ${
                                                            errors.factoryLocation
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        value={
                                                            this.state
                                                                .factoryLocation
                                                        }
                                                        id="factoryLocation"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="Road#0, Area#1, City"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="factoryLocation"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <label>
                                                        Port of Origin{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control form-control-sm ${
                                                            errors.portOfOrigin
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        value={
                                                            this.state
                                                                .portOfOrigin
                                                        }
                                                        id="portOfOrigin"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="PORT012"
                                                    />

                                                    <ErrorMessage
                                                        component="div"
                                                        name="portOfOrigin"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label>
                                                        Credit Term In Days{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className={`form-control form-control-sm ${
                                                            errors.creditTermInDays
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        value={
                                                            this.state
                                                                .creditTermInDays
                                                        }
                                                        id="creditTermInDays"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="e.g. 7"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="creditTermInDays"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <label>
                                                        Business Currency{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <Dropdown
                                                        controlClassName={`form-control form-control-sm `}
                                                        className={` Dropdown-root-width ${
                                                            errors.businessCurrency
                                                                ? 'border border-danger is-invalid'
                                                                : ''
                                                        }`}
                                                        value={
                                                            this.state
                                                                .businessCurrency
                                                        }
                                                        id="businessCurrency"
                                                        options={BUSINESS_CURRENCY.map(
                                                            (i) =>
                                                                i.businessCurrency,
                                                        )}
                                                        onChange={this.handleDropDownChange(
                                                            'businessCurrency',
                                                        )}
                                                        placeholder="Select an option"
                                                    />

                                                    <ErrorMessage
                                                        component="div"
                                                        name="businessCurrency"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label>
                                                        Payment Type{' '}
                                                        <sup className="text-danger">
                                                            *
                                                        </sup>
                                                    </label>
                                                    <Dropdown
                                                        controlClassName={`form-control form-control-sm `}
                                                        className={` Dropdown-root-width ${
                                                            errors.paymentType
                                                                ? 'border border-danger is-invalid'
                                                                : ''
                                                        } `}
                                                        value={
                                                            this.state
                                                                .paymentType
                                                        }
                                                        id="paymentType"
                                                        options={PAYMENT_TYPE}
                                                        onChange={this.handleDropDownChange(
                                                            'paymentType',
                                                        )}
                                                        placeholder="Select an option"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="paymentType"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-12 mb-3 mb-sm-0">
                                                    <div
                                                        className={` ${
                                                            touched.companyCertificate &&
                                                            errors.companyCertificate
                                                                ? 'border border-danger is-invalid'
                                                                : ''
                                                        }`}
                                                    >
                                                        <FileUpload
                                                            modeStatus={
                                                                this.state
                                                                    .companyCertificate
                                                            }
                                                            this={this}
                                                            label="Company Certificate"
                                                            id="companyCertificate"
                                                            value={
                                                                this.state
                                                                    .companyCertificate
                                                            }
                                                            uploadPercentage={
                                                                this.state
                                                                    .uploadPercentage
                                                            }
                                                            handleFileDownload={
                                                                this
                                                                    .handleFileDownload
                                                            }
                                                            removeFile={
                                                                this.removeFile
                                                            }
                                                            handleChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            called={
                                                                this.state
                                                                    .called
                                                            }

                                                            // uploadButtonOff={true}

                                                            // cancelButtonOF={true}
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        component="div"
                                                        name="companyCertificate"
                                                        className="invalid-feedback"
                                                    />
                                                    <small className="text-danger mt-1">
                                                        {' '}
                                                        {
                                                            'Valid format .jpg, .jpeg, .png, .pdf, .doc, .docx'
                                                        }
                                                    </small>
                                                </div>
                                            </div>
                                            <button
                                                id="show"
                                                type="button"
                                                className={
                                                    this.props.status
                                                        ? 'btn btn-sm btn-outline-danger mr-5 mt-2'
                                                        : 'd-none'
                                                }
                                                onClick={this.props.handleClose}
                                            >
                                                Close
                                            </button>
                                            {this.props.edit ? (
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm mt-2 btn-outline-success "
                                                >
                                                    Update
                                                </button>
                                            ) : this.props.status ? null : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm  mt-2 btn-outline-success "
                                                >
                                                    Register
                                                </button>
                                            )}
                                            {this.props.html}
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class CompanyList extends SetupDashBoardHandlerClass {
    constructor(props) {
        super(props)
        this.child = React.createRef()

        console.log('company', this.props)

        this.state = {
            selectedData: {},
            companyList: [],
            edit: true,
            companyType: 'ALL',
        }
    }

    refresh = () => {
        const { companyType } = this.state

        getCreatedCompanyList(companyType)
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        companyList: res.data,
                    })
                    console.log(this.state.companyList)
                }
            })
            .catch((err) => err)
    }

    handleDropDownChange = (val) => (event) => {
        this.state[val] = event.value
        this.setState()
    }

    componentDidMount() {}

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true, // enable hide selection column.

            clickToSelect: true,
            onSelect: this.handleSelectedRow,
        }

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-auto py-3 m-auto">
                        <h2>Company List</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="p-1">
                            <ModalCommon
                                load={
                                    <CompanyRegistration
                                        call={'modal'}
                                        status={true}
                                        data={this.state.selectedData}
                                        edit={this.state.edit}
                                        tittle={'Company Information'}
                                        handleClose={this.handleClose}
                                        refresh={this.child.retrievePoLists}
                                    />
                                }
                                tittle={'Company Information'}
                                handleClose={this.handleClose}
                                show={this.state.show}
                                onHide={this.handleClose}
                                size={'lg'}
                            />

                            <ComUserPagination
                                ref={(instance) => {
                                    this.child = instance
                                }}
                                tittle="Company"
                                selectRow={selectRow}
                                path={'/scem/api/v1/company?'}
                                columns={getCompanyList}
                                status={'ALL'}
                                pending={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class CompanyPendingList extends SetupDashBoardHandlerClass {
    constructor(props) {
        super(props)
        this.child = React.createRef()
        console.log('com pending', this.props)
        this.state = {
            selectedData: {},
            companyList: [],
            edit: true,
        }
    }
    refresh = () => {
        getCreatedCompanyList('PENDING')
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        companyList: res.data,
                    })
                    console.log(this.state.companyList)
                }
            })
            .catch((err) => err)
    }

    componentDidMount() {}

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true, // enable hide selection column.

            clickToSelect: true,
            onSelect: this.handleSelectedRow,
        }

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-auto py-3 m-auto">
                        <h2>Pending Company List</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="col-lg-12" align="center"></div>

                        <div className="p-1">
                            <ModalCommon
                                load={
                                    <CompanyRegistration
                                        call={'modal'}
                                        status={true}
                                        data={this.state.selectedData}
                                        edit={this.state.edit}
                                        tittle={'Company Information'}
                                        handleClose={this.handleClose}
                                        refresh={this.child.retrievePoLists}
                                    />
                                }
                                tittle={'Company Information'}
                                handleClose={this.handleClose}
                                show={this.state.show}
                                onHide={this.handleClose}
                            />

                            <ComUserPagination
                                ref={(instance) => {
                                    this.child = instance
                                }}
                                pendingCount={this.props.companyPendingCount}
                                tittle="Company"
                                selectRow={selectRow}
                                path={'/scem/api/v1/company?'}
                                columns={getCompanyPendingList}
                                status={'PENDING'}
                                pendingPage={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
