import React from 'react'
import { TOUCH_POINT } from '../utils/constant'
import { GrDocumentUser } from 'react-icons/gr'
import {
    Svg11BusinessServiceCentre01,
    Svg1Vendor01,
    Svg2Sourcing01,
    Svg3Cfs01,
} from '../utils/icons'
export default function PoTrafic(props) {
    const { poSequence, repairFlag } = props

    let iconsLoad = (i, color, size = 30) => {
        const obj = {
            vn: (
                <Svg1Vendor01
                    fill={color}
                    fontSize={size}
                    fontWeight={'bold'}
                />
            ),
            sc: <Svg2Sourcing01 fill={color} fontSize={size} />,
            cfs: <Svg3Cfs01 fill={color} fontSize={'37'} />,
            bsc: <Svg11BusinessServiceCentre01 fill={color} fontSize={size} />,
        }
        return obj[i]
    }
    return (
        <div className="row">
            <div className="col">
                <div className="card card-timeline px-2 border-none scroll5">
                    <ul className="bs4-order-tracking">
                        {poSequence.map((i, index) => (
                            <li
                                className={
                                    i.id === props.expectedTouchPointState
                                        ? ' active'
                                        : index <=
                                          poSequence.findIndex(
                                              (x, xIndex) =>
                                                  x.id ===
                                                  props.activeTouchPointState,
                                          )
                                        ? repairFlag
                                            ? index ==
                                              poSequence.findIndex(
                                                  (x, xIndex) =>
                                                      x.id ===
                                                      props.activeTouchPointState,
                                              )
                                                ? 'danger'
                                                : 'complete'
                                            : ' complete'
                                        : ''
                                }
                            >
                                <div>
                                    {iconsLoad(
                                        i.code,

                                        i.id === props.expectedTouchPointState
                                            ? 'blue'
                                            : index <=
                                              poSequence.findIndex(
                                                  (x, xIndex) =>
                                                      x.id ===
                                                      props.activeTouchPointState,
                                              )
                                            ? repairFlag
                                                ? index ==
                                                  poSequence.findIndex(
                                                      (x, xIndex) =>
                                                          x.id ===
                                                          props.activeTouchPointState,
                                                  )
                                                    ? 'red'
                                                    : 'green'
                                                : 'green'
                                            : '#f48425',
                                    )}
                                </div>
                                <p>{i.name}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
