import { Height } from '@material-ui/icons'
import React from 'react'
import './not-found.css'

const NotFound = () => (
    <div className="NotFound">
        <h3>Sorry, page not found!</h3>
    </div>
)

export default NotFound

export const WelcomeScem = (props) => (
    <div className="container-fluid px-0 ">
        {console.log('wel', props)}
        <div className="row">
            <div className="col-12 text-center py-3">
                <h3>
                    Welcome{' '}
                    <span style={{ color: '#0069c5' }}>{props.firstName}</span>{' '}
                    to eDOC Service
                </h3>
            </div>
            <div className="col-12 px-5">
                <p className="text-justify">
                    Supply Chain eDoc Management (eDOC) is partnering with
                    financiers and corporates to explore ways to digitize global
                    trade, making the exchange of documents and information a
                    truly paperless experience. eDOC provides seamless, secure
                    sharing of real-time, actionable supply chain information
                    across all parties — encompassing shipping milestones, cargo
                    details, trade documents, sensor readings and more—thereby
                    empowering the consumers to mitigate problems through
                    predictability and exception handling.
                </p>
            </div>
            <div className="col-12 py-0">
                <img
                    style={{
                        maxWidth: ' 100%%',
                        width: '100%',
                        position: 'fixed',
                        height: ' 75%',

                        paddingBottom: '10px',
                    }}
                    src="/assets/images/dashboard.jpg"
                    className="img-fluid"
                />
            </div>
        </div>
    </div>
)
