import axios from 'axios'

export const IDLE_MINITUE = 30
export const PASSWORD_LENGTH = 10

export class APIConfig {
    static BASE_URL = 'https://edoc.tirzok.com'

    static AUTH_TOKEN = ''
    static POST_CONTENT_TYPE = 'application/json'
    static AUTH_TOKEN_HEADER = 'Authorization'
}

export function configureAxios({
    baseURL = APIConfig.BASE_URL,
    authToken = APIConfig.AUTH_TOKEN,
    authCallback = null,
    postContentType = APIConfig.POST_CONTENT_TYPE,
    ...props
} = {}) {
    axios.defaults.baseURL = baseURL

    if (authToken !== null) {
        console.log('authtoken', authToken)
        axios.defaults.headers.common[
            APIConfig.AUTH_TOKEN_HEADER
        ] = `Bearer ${authToken}`
    } else {
        delete axios.defaults.headers.common[APIConfig.AUTH_TOKEN_HEADER]
    }
    // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    configureAxios.authCallback = authCallback
    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            if (401 === error.response.status) {
                switch (error.response.data.errorCode) {
                    case 2005:
                        return Promise.reject(error)
                    case 2006:
                        return Promise.reject(error)
                    case 2007:
                        return Promise.reject(error)
                    case 2013:
                        return Promise.reject(error)
                    default:
                        if (configureAxios.authCallback) {
                            configureAxios.authCallback(error.response)
                        }
                }
            } else {
                return Promise.reject(error)
            }
        },
    )
}

const API_LOGIN = '/scem/api/v1/auth/signin'
const API_SET_PASSWORD = ''
const API_LOGOUT = '/scem/api/v1/auth/signout'
const API_CHANGE_PASSWORD = '/scem/api/v1/user/password'
const API_FORGET_PASSWORD = 'api/v1/user/password/forget'
const API_EXPIRE_PASSWORD = ''

export class API_AUTH {
    static get login() {
        return API_LOGIN
    }

    static get setPassword() {
        return API_SET_PASSWORD
    }

    static get changePassword() {
        return API_CHANGE_PASSWORD
    }

    static get forgetPassword() {
        return API_FORGET_PASSWORD
    }

    static get expirePassword() {
        return API_EXPIRE_PASSWORD
    }

    static get logout() {
        return API_LOGOUT
    }
}
