import React, { Component } from 'react'
import {
    COUNTRY_LIST,
    EVENT_MODEL_CLOSE,
    getUserList,
    userPendingListColumn,
} from '../../utils/constant'
import {
    createUser,
    getCreatedCompanyList,
    getCreatedUserList,
    SetupDashBoardHandlerClass,
    uploadFile,
} from '../../utils/event-handlers'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { toastNotify } from '../../utils/Alert-msg'
import { Formik, Form, ErrorMessage } from 'formik'
import { ModalCommon } from '../../components/modal'
import { PASSWORD_LENGTH } from '../../api/config'
import ComUserPagination from './com&user-pagintation-table'
import Select from 'react-dropdown-select'
import countryList from 'react-select-country-list'
import { minWidth } from '@material-ui/system'
import PhoneInput, {
    formatPhoneNumber,
    formatPhoneNumberIntl,
    isValidPhoneNumber,
} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { API, Path } from '../config'
import { postApiCallDynamic } from '../../service'

export class UserRegistration extends Component {
    constructor(props) {
        super(props)

        console.log('userdata', this.props)
        console.log(
            'Country',
            countryList().getData(),
            countryList().getValue('bangladesh'),
            countryList().getLabel('bangladesh'),
        )

        this.state = {
            uploadPercentage: 0,
            id: this.props.status ? this.props.data.id : null,
            username: this.props.status ? this.props.data.username : '',
            firstName: this.props.status ? this.props.data.firstName : '',
            lastName: this.props.status ? this.props.data.lastName : '',
            email: this.props.status ? this.props.data.email : '',
            country: this.props.status
                ? this.props.data.country
                : countryList().getValue('bangladesh'),
            phone: this.props.status ? this.props.data.phone : '',
            companyId: this.props.status ? this.props.data.companyId : '',
            companyName: this.props.status ? this.props.data.companyName : '',
            password: this.props.status ? this.props.data.password : '',
            confirmPassword: '',
            companyList: [],
            icon: 'fa-eye',
            tagType: 'password',
            iconConfirm: 'fa-eye',
            tagTypeConfirm: 'password',
            countryList: [],
            iconOldPassword: 'fa-eye',
            tagTypeOldPassword: 'password',
        }
    }

    refresh = () => {
        countryList()
            .getData()
            .map((i) => (i.value = i.label))
        this.setState({
            countryList: countryList().getData(),
        })
        getCreatedCompanyList('APPROVED')
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        companyList: res.data.dataList,
                    })
                    console.log(this.state.companyList)
                }
            })
            .catch((err) => err)
    }

    resetFormField = () => {
        this.setState({
            uploadPercentage: 0,
            id: this.props.status ? this.props.data.id : null,
            username: this.props.status ? this.props.data.username : '',
            firstName: this.props.status ? this.props.data.firstName : '',
            lastName: this.props.status ? this.props.data.lastName : '',
            email: this.props.status ? this.props.data.email : '',
            country: this.props.status
                ? this.props.data.country
                : countryList().getValue('bangladesh'),
            phone: this.props.status ? this.props.data.phone : '',
            companyId: this.props.status ? this.props.data.companyId : '',
            companyName: this.props.status ? this.props.data.companyName : '',
            password: this.props.status ? this.props.data.password : '',
            confirmPassword: '',
            companyList: [],
            icon: 'fa-eye',
            tagType: 'password',
            iconConfirm: 'fa-eye',
            tagTypeConfirm: 'password',

            iconOldPassword: 'fa-eye',
            tagTypeOldPassword: 'password',
        })

        this.refresh()
    }

    removeFile = (event) => {
        this.setState({
            [event.target.id]: null,
        })
    }

    handleChange = (event) => {
        console.log('handleChange', event)
        if (event.target.type === 'file') {
            console.log('who', event.target.files[0])
            let blnValid = false
            let _validFileExtensions = ['.csv', '.txt']
            // if()
            for (var j = 0; j < _validFileExtensions.length; j++) {
                var sCurExtension = _validFileExtensions[j]
                if (
                    event.target.files[0].name
                        .substr(
                            event.target.files[0].name.length -
                                sCurExtension.length,
                            sCurExtension.length,
                        )
                        .toLowerCase() == sCurExtension.toLowerCase()
                ) {
                    blnValid = true
                    break
                }
            }
            if (!blnValid) {
                toastNotify('warn', 'File extension missmatch')
            } else {
                const data = new FormData()
                this.setState({
                    called: event.target.id,
                    [event.target.id]: event.target.files[0].name,

                    // spinner:true,
                })
                const option = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent
                        let percent = Math.floor((loaded * 100) / total)

                        if (percent < 100) {
                            this.setState({
                                uploadPercentage: percent,
                            })
                        }
                    },
                }

                data.append('fileToUpload', event.target.files[0])
                uploadFile(data, option).then((res) => {
                    if (
                        (res !== undefined && res.status === 200) ||
                        (res !== undefined && res.status === 201)
                    ) {
                        this.setState(
                            {
                                uploadPercentage: 100,
                                [event.target.id]: res.data,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({
                                        uploadPercentage: 0,
                                    })
                                    toastNotify(
                                        'success',
                                        'File uploaded successfully',
                                    )
                                }, 1000)
                            },
                        )
                    }
                    this.setState({
                        spinner: false,
                    })
                })
            }
        } else {
            this.setState({
                [event.target.id]: event.target.value,
            })
        }
    }

    handleDropDownChange =
        (val, name, multi = false) =>
        (event) => {
            console.log('drop', event, 'val', val, 'name', name, 'multi', multi)
            if (multi) {
                if (event.length === 0) {
                    this.setState({
                        [val]: '',
                        [name]: '',
                    })
                    return
                }
                event = event[0]
            }
            this.setState({
                [val]: event.value,
                [name]: event.label,
            })
        }

    handleShowPassword = (event) => {
        console.log(event)
        if (event.target.id === 'password') {
            if (this.state.tagType === 'password') {
                this.setState({
                    tagType: 'text',
                    icon: 'fa-eye-slash',
                })
            } else {
                this.setState({
                    tagType: 'password',
                    icon: 'fa-eye',
                })
            }
        }
        if (event.target.id === 'confirmPassword') {
            if (this.state.tagTypeConfirm === 'password') {
                this.setState({
                    tagTypeConfirm: 'text',
                    iconConfirm: 'fa-eye-slash',
                })
            } else {
                this.setState({
                    tagTypeConfirm: 'password',
                    iconConfirm: 'fa-eye',
                })
            }
        }

        if (event.target.id === 'oldPassword') {
            if (this.state.tagTypeOldPassword === 'password') {
                this.setState({
                    tagTypeOldPassword: 'text',
                    iconOldPassword: 'fa-eye-slash',
                })
            } else {
                this.setState({
                    tagTypeOldPassword: 'password',
                    iconOldPassword: 'fa-eye',
                })
            }
        }
    }

    handleSubmit = () => {
        let data = {
            id: this.state.id,
            username: this.state.username,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            country: this.state.country,
            phone: this.state.phone,
        }

        if (!this.props.status) {
            data = {
                id: this.state.id,
                username: this.state.username,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                country: this.state.country,
                phone: this.state.phone,
                companyId: this.state.companyId,
                companyName: this.state.companyName,
                password: this.state.password,
            }
        }
        console.log('json', JSON.stringify(this.state))
        let head = this.props.status ? 'UPDATE' : 'CREATE'
        let path = this.props.status
            ? API.createUser
            : '/scem/api/v1/user/signup'
        postApiCallDynamic(data, path, head).then((res) => {
            if (res !== undefined) {
                this.setState({
                    spinner: false,
                })

                if (
                    res.status === 201 ||
                    res.status === 202 ||
                    res.status === 200
                ) {
                    if (this.props.status) {
                        toastNotify('success', 'Successfully user updated')
                        if (this.props.call === 'profile') {
                            this.props.history.push(Path.profile)
                        }
                        if (this.props.call === 'modal') {
                            this.props.handleClose(EVENT_MODEL_CLOSE)
                            this.props.refresh()
                        }
                    } else {
                        toastNotify('success', 'Successfully user created')
                        // this.props.history.push(Path.index)
                        this.resetFormField()
                    }
                }
            }
            this.setState({
                spinner: false,
            })
        })
    }

    componentDidMount() {
        this.refresh()
    }

    handleValidation = () => {
        let errors = {}
        console.log('raafi bhai', this.state)
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let phoneNumberValid = /^(?:\+?88)?(018|017|016|019|015|013)\d{8}/

        if (this.props.status) {
            if (this.state.username === '') {
                errors.username = 'Please enter name'
            }

            if (!isValidPhoneNumber(this.state.phone)) {
                errors.phone = 'Please enter valid mobile no'
            }

            if (this.state.country === '') {
                errors.country = 'Please select a location'
            }

            if (re.test(String(this.state.email).toLowerCase()) === false) {
                errors.email = 'Please enter valid email'
            }
            if (this.state.companyName === '') {
                errors.companyName = 'Please select an option'
            }
            if (this.state.firstName === '') {
                errors.firstName = 'Please enter firstname'
            }
            if (this.state.lastName === '') {
                errors.lastName = 'Please enter lastname'
            }
            if (this.state.country === '') {
                errors.country = 'Please select an option'
            }
        } else {
            if (this.state.username === '') {
                errors.username = 'Please enter name'
            }
            if (this.state.companyName === '') {
                errors.companyName = 'Please select an option'
            }

            if (!isValidPhoneNumber(this.state.phone)) {
                errors.phone = 'Please enter valid mobile no'
            }

            if (this.state.country === '') {
                errors.country = 'Please select a country'
            }

            if (re.test(String(this.state.email).toLowerCase()) === false) {
                errors.email = 'Please enter valid email'
            }
            if (this.state.firstName === '') {
                errors.firstName = 'Please enter firstname'
            }
            if (this.state.lastName === '') {
                errors.lastName = 'Please enter lastname'
            }
            if (this.state.country === '') {
                errors.country = 'Please select an option'
            }

            let count = 0
            var regNumber = /[0-9]+/
            var regCapitalAlpha = /[A-Z]+/
            var regSmallAlpha = /[a-z]+/
            var regSpecialChar = /[*@!#%&()^~{}]+/

            if (this.state.password !== this.state.confirmPassword) {
                errors.confirmPassword = 'Password did not match'
            }

            if (this.state.password.includes(' ')) {
                errors.password = "Password can't contain white space"
            } else {
                if (
                    this.state.password
                        .toLowerCase()
                        .includes(this.state.username.toLowerCase())
                ) {
                    errors.password = "Password can't contain user name"
                } else if (
                    this.state.password.toLowerCase().includes('admin')
                ) {
                    errors.password =
                        "Password can't contain admin, administrator"
                } else if (
                    this.state.password.toLowerCase().includes('administrator')
                ) {
                    errors.password =
                        "Password can't contain admin, administrator"
                } else {
                    if (this.state.password.length < PASSWORD_LENGTH) {
                        errors.password = `Password length must be ${PASSWORD_LENGTH}`
                    } else {
                        if (regNumber.test(this.state.password)) {
                            count = count + 1
                        }
                        if (regCapitalAlpha.test(this.state.password)) {
                            count = count + 1
                        }
                        if (regSmallAlpha.test(this.state.password)) {
                            count = count + 1
                        }
                        if (regSpecialChar.test(this.state.password)) {
                            count = count + 1
                        }
                        if (count < 3) {
                            errors.password =
                                'Password must contain  Alphanumeric & Special Character. Password length must be 10'
                        }
                    }
                }
            }
        }

        return errors
    }

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true, // enable hide selection column.

            clickToSelect: true,
            onSelect: this.handleSelectedRow,
        }

        return (
            <div className="container-fluid">
                {!this.props.status && !this.props.outside ? (
                    <div className="row">
                        <div className="col-md-auto py-3 m-auto">
                            <h2>
                                {this.props.tittle
                                    ? this.props.tittle
                                    : 'User Registration'}
                            </h2>
                        </div>
                    </div>
                ) : null}
                {this.props.outside ? (
                    <div className="row">
                        <div className="col-md-auto py-3 m-auto">
                            <div className="text-center">
                                <h2>{'Register'}</h2>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="row">
                    <div
                        className={
                            this.props.status
                                ? 'col-md-auto m-auto'
                                : 'col-md-6 m-auto'
                        }
                    >
                        <div className="card p-3">
                            <Formik
                                initialValues={this.state}
                                validate={(values) =>
                                    this.handleValidation(this, values)
                                }
                                onSubmit={(values, { setSubmitting }) => {
                                    console.log('va', values)
                                    this.handleSubmit()
                                    setSubmitting(false)
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <>
                                        <Form>
                                            {this.props.outside
                                                ? this.props.accountType
                                                : null}
                                            <div className="form-group row">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <label>First Name*</label>
                                                    <input
                                                        type="text"
                                                        value={
                                                            this.state.firstName
                                                        }
                                                        className={`form-control form-control-sm ${
                                                            errors.firstName
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        id="firstName"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="e.g. Jhon"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="firstName"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label>Last Name*</label>
                                                    <input
                                                        type="text"
                                                        value={
                                                            this.state.lastName
                                                        }
                                                        className={`form-control form-control-sm ${
                                                            errors.lastName
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        id="lastName"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="e.g. Doe"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="lastName"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <label>Username*</label>
                                                    <input
                                                        type="text"
                                                        disabled={
                                                            this.props.status
                                                        }
                                                        value={
                                                            this.state.username
                                                        }
                                                        className={`form-control form-control-sm ${
                                                            errors.username
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        id="username"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="e.g. jhon1234"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="username"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label>E-mail*</label>
                                                    <input
                                                        type="email"
                                                        disabled={
                                                            this.props.status
                                                        }
                                                        value={this.state.email}
                                                        className={`form-control form-control-sm ${
                                                            errors.email
                                                                ? 'is-invalid '
                                                                : ''
                                                        }`}
                                                        id="email"
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder="e.g. abc@provider.com"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="email"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    this.props.status
                                                        ? 'd-none'
                                                        : 'form-group row'
                                                }
                                            >
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <label>Password*</label>

                                                    <div className="input-group  input-group-sm">
                                                        <input
                                                            type={
                                                                this.state
                                                                    .tagType
                                                            }
                                                            value={
                                                                this.state
                                                                    .password
                                                            }
                                                            className={`form-control form-control-sm ${
                                                                errors.password
                                                                    ? 'is-invalid '
                                                                    : ''
                                                            }`}
                                                            id="password"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            placeholder="*******"
                                                        />

                                                        <div className="input-group-prepend">
                                                            <span
                                                                id="password"
                                                                className="input-group-text text-origin"
                                                                onClick={
                                                                    this
                                                                        .handleShowPassword
                                                                }
                                                            >
                                                                <i
                                                                    id="password"
                                                                    toggle="#password"
                                                                    className={`fa  ${this.state.icon}  toggle-password`}
                                                                ></i>
                                                            </span>
                                                        </div>
                                                        <ErrorMessage
                                                            component="div"
                                                            name="password"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                    {/* <small className="text-danger mt-1">{`Password must contain  Alphanumeric & Special Character. Password length must be 10`}</small> */}
                                                </div>

                                                <div className="col-sm-6">
                                                    <label>
                                                        Confirm Password*
                                                    </label>
                                                    <div className="input-group  input-group-sm">
                                                        <input
                                                            type={
                                                                this.state
                                                                    .tagTypeConfirm
                                                            }
                                                            value={
                                                                this.state
                                                                    .confirmPassword
                                                            }
                                                            className={`form-control form-control-sm ${
                                                                errors.confirmPassword
                                                                    ? 'is-invalid '
                                                                    : ''
                                                            }`}
                                                            id="confirmPassword"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            placeholder="*******"
                                                        />

                                                        <div className="input-group-prepend">
                                                            <span
                                                                id="confirmPassword"
                                                                className="input-group-text text-origin"
                                                                onClick={
                                                                    this
                                                                        .handleShowPassword
                                                                }
                                                            >
                                                                <i
                                                                    id="confirmPassword"
                                                                    toggle="#password"
                                                                    className={`fa  ${this.state.iconConfirm}  toggle-password`}
                                                                ></i>
                                                            </span>
                                                        </div>
                                                        <ErrorMessage
                                                            component="div"
                                                            name="confirmPassword"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-6 mb-3 mb-sm-0">
                                                    <label>Country*</label>

                                                    {/* <Dropdown
                                                        controlClassName={`form-control form-control-sm `}
                                                        className={` Dropdown-root-width ${
                                                            errors.country
                                                                ? 'border border-danger is-invalid'
                                                                : ''
                                                        }`}
                                                        // className={`Dropdown-root-width
                                                        // ${touched.paymentType && errors.paymentType
                                                        //             ? "border border-danger is-invalid" : ""}`
                                                        //         }
                                                        value={
                                                            this.state.country
                                                        }
                                                        id="country"
                                                        options={
                                                            this.state
                                                                .countryList
                                                        }
                                                        onChange={this.handleDropDownChange(
                                                            'country',
                                                        )}
                                                        placeholder="Select an option"
                                                    ></Dropdown> */}

                                                    <Select
                                                        multi={false}
                                                        className={`select select-search ${
                                                            errors.country
                                                                ? 'border border-danger is-invalid'
                                                                : ''
                                                        } `}
                                                        clearOnBlur={true}
                                                        clearable={true}
                                                        placeholder="Select..."
                                                        values={[
                                                            {
                                                                label: this
                                                                    .state
                                                                    .country,
                                                            },
                                                        ]}
                                                        id="country"
                                                        options={
                                                            this.state
                                                                .countryList
                                                        }
                                                        // onChange={this.handleDropDownChange(
                                                        //     'country',
                                                        // )}
                                                        onChange={this.handleDropDownChange(
                                                            'country',
                                                            '',
                                                            true,
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="country"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="col-sm-6">
                                                    <label>Company Name*</label>

                                                    {this.props.status ? (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .companyName
                                                                }
                                                                disabled
                                                                className={`form-control form-control-sm ${
                                                                    errors.companyNameShow
                                                                        ? 'is-invalid '
                                                                        : ''
                                                                }`}
                                                                id="companyNameShow"
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="companyNameShow"
                                                                className="invalid-feedback"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Select
                                                                multi={false}
                                                                className={`select select-search ${
                                                                    errors.companyName
                                                                        ? 'border border-danger is-invalid'
                                                                        : ''
                                                                } `}
                                                                clearOnBlur={
                                                                    true
                                                                }
                                                                clearable={true}
                                                                value={
                                                                    this.state
                                                                        .companyName
                                                                }
                                                                id="companyId"
                                                                options={this.state.companyList.map(
                                                                    (i) => {
                                                                        return {
                                                                            value: i.id,
                                                                            label: i.name,
                                                                        }
                                                                    },
                                                                )}
                                                                onChange={this.handleDropDownChange(
                                                                    'companyId',
                                                                    'companyName',
                                                                    true,
                                                                )}
                                                            />

                                                            <ErrorMessage
                                                                component="div"
                                                                name="companyName"
                                                                className="invalid-feedback"
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-6">
                                                    <label>
                                                        Contact Number*
                                                    </label>
                                                    <PhoneInput
                                                        international
                                                        className={` form-control form-control-sm ${
                                                            errors.phone
                                                                ? ' is-invalid'
                                                                : ''
                                                        } `}
                                                        defaultCountry="BD"
                                                        id="phone"
                                                        value={this.state.phone}
                                                        onChange={(value) =>
                                                            this.setState({
                                                                phone: value,
                                                            })
                                                        }
                                                    />

                                                    <ErrorMessage
                                                        component="div"
                                                        name="phone"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            {this.props.call ===
                                            'profile' ? null : (
                                                <button
                                                    id="show"
                                                    type="button"
                                                    className={
                                                        this.props.status
                                                            ? 'btn btn-sm mt-2 btn-outline-danger mr-5'
                                                            : 'd-none'
                                                    }
                                                    onClick={
                                                        this.props.handleClose
                                                    }
                                                >
                                                    Close
                                                </button>
                                            )}
                                            {this.props.edit ? (
                                                <button
                                                    type="submit"
                                                    className="btn mt-2 btn-sm btn-outline-success "
                                                >
                                                    Update
                                                </button>
                                            ) : this.props.status ? null : (
                                                <button
                                                    type="submit"
                                                    className="btn mt-2 btn-sm btn-outline-success "
                                                >
                                                    Register
                                                </button>
                                            )}
                                            {this.props.html}
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class UserList extends SetupDashBoardHandlerClass {
    constructor(props) {
        super(props)
        this.child = React.createRef()

        this.state = {
            selectedData: {},
            userList: [],
            edit: true,
            userType: 'ALL',
        }
    }

    refresh = () => {
        const { userType } = this.state
        this.setState({
            userList: [],
        })

        getCreatedUserList(userType)
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        userList: res.data,
                    })
                    console.log(this.state.userList)
                }
            })
            .catch((err) => err)
    }

    handleDropDownChange = (val) => (event) => {
        this.state[val] = event.value
        this.setState()
    }

    componentDidMount() {}

    handleClose = () => {
        this.setState({
            show: false,
        })
    }

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true, // enable hide selection column.

            clickToSelect: true,
            onSelect: this.handleSelectedRow,
        }

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-auto py-3 m-auto">
                        <h2>User List</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="col-lg-12" align="center"></div>
                        <div className="p-1">
                            <ModalCommon
                                load={
                                    <UserRegistration
                                        call="modal"
                                        tittle={'User Infromation'}
                                        edit={this.state.edit}
                                        status={true}
                                        data={this.state.selectedData}
                                        handleClose={this.handleClose}
                                        refresh={this.child.retrievePoLists}
                                    />
                                }
                                handleClose={this.handleClose}
                                tittle={'User Information'}
                                show={this.state.show}
                                onHide={this.handleClose}
                                size={'lg'}
                            />

                            <ComUserPagination
                                ref={(instance) => {
                                    this.child = instance
                                }}
                                selectRow={selectRow}
                                tittle="User"
                                path={'/scem/api/v1/user?'}
                                columns={getUserList}
                                status={'ALL'}
                                pendingPage={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class UserPendingList extends SetupDashBoardHandlerClass {
    constructor(props) {
        super(props)
        this.child = React.createRef()
        this.state = {
            selectedData: {},
            userList: [],
            edit: true,
        }
    }

    refresh = () => {
        getCreatedUserList('PENDING')
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        userList: res.data,
                    })
                    console.log(this.state.userList)
                }
            })
            .catch((err) => err)
    }

    componentDidMount() {}

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true, // enable hide selection column.

            clickToSelect: true,
            onSelect: this.handleSelectedRow,
        }

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-auto py-3 m-auto">
                        <h2>Pending User List</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="p-1">
                            <ModalCommon
                                load={
                                    <UserRegistration
                                        call="modal"
                                        tittle={'User Information'}
                                        edit={this.state.edit}
                                        status={true}
                                        data={this.state.selectedData}
                                        handleClose={this.handleClose}
                                        refresh={this.child.retrievePoLists}
                                    />
                                }
                                tittle={'User Information'}
                                handleClose={this.handleClose}
                                show={this.state.show}
                                onHide={this.handleClose}
                            />
                            <ComUserPagination
                                ref={(instance) => {
                                    this.child = instance
                                }}
                                pendingCount={this.props.userPendingCount}
                                selectRow={selectRow}
                                tittle="User"
                                path={'/scem/api/v1/user?'}
                                columns={userPendingListColumn}
                                status={'PENDING'}
                                pendingPage={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
