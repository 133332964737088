import React, { Component } from 'react'
import 'react-dropdown/style.css'
import { getApiCallDynamic } from '../service'
import { getPoActivityList } from '../utils/event-handlers'

export class ActivityLog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user: '',
            poActivityList: [],
        }
    }

    refresh = () => {
        getApiCallDynamic({}, API.poActivityList)
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        poActivityList: res.data,
                    })
                    console.log('poActivityList', this.state.poActivityList)
                }
            })
            .catch((err) => err)
    }

    componentDidMount() {
        this.refresh()
    }

    handleChange = (e) => {
        console.log(e)
    }

    render() {
        return (
            <div id="company_list" className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="p-5">
                            <div className="overflow-hidden">
                                <h3 className="float-left">Activity Log</h3>

                                <button
                                    className="ml-3   f-r btn btn-outline-secondary"
                                    onClick={this.refresh}
                                >
                                    {' '}
                                    Refresh
                                </button>
                            </div>
                            <div className="p-5">
                                <hr />
                                {this.state.poActivityList.map((i) => (
                                    <>
                                        <p>{i}</p>
                                        <hr />
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
