import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
    PaginationProvider,
    SizePerPageDropdownStandalone,
    PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {
    CSVExport,
    Search,
} from 'react-bootstrap-table2-toolkit'
import filterFactory from 'react-bootstrap-table2-filter'
const { SearchBar } = Search
const { ExportCSVButton } = CSVExport

export default class CommonTable extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    show = () => {
        this.setState({
            show: true,
        })
    }

    render() {
        const defaultSorted = [
            {
                dataField: 'id',
                order: 'desc',
            },
        ]

        const rowStyle = (row, rowIndex) => {
            row.index = rowIndex
            console.log('row', row)
            const style = {}

            style.color = 'black'

            return style
        }

        const options = {
            custom: true,
            totalSize: this.props.data.length,
        }

        return (
            <ToolkitProvider
                bootstrap4
                keyField="id"
                data={this.props.data}
                columns={this.props.columns}
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                search
            >
                {(props) => (
                    <PaginationProvider pagination={paginationFactory(options)}>
                        {({ paginationProps, paginationTableProps }) => (
                            <div>
                                <div style={{ marginBottom: '1rem' }}>
                                    <SizePerPageDropdownStandalone
                                        className="w-80"
                                        {...paginationProps}
                                    />

                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                </div>
                                <BootstrapTable
                                    {...props.baseProps}
                                    selectRow={this.props.selectRow}
                                    rowStyle={rowStyle}
                                    defaultSorted={defaultSorted}
                                    {...paginationTableProps}
                                    filter={filterFactory()}
                                    hover
                                    condensed
                                    noDataIndication="Table is Empty"
                                />
                            </div>
                        )}
                    </PaginationProvider>
                )}
            </ToolkitProvider>
        )
    }
}
