import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap'

export default class FileUpload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: this.props.value,
        }
    }

    removeFile = (event) => {
        console.log('remove', event.target.id)
        this.state.value = this.state.value.filter(
            (i) => i != event.target.value,
        )
        this.setState({
            value: this.state.value,
        })
        this.props.this.setState({
            [this.props.id]: this.state.value,
        })
    }

    render() {
        return (
            <>
                <label
                    htmlFor={this.props.htmlFor}
                    className={` ${this.props.textStyle}`}
                >
                    {this.props.label}
                </label>

                <div
                    className={
                        this.props.disabled
                            ? 'input-group input-group-sm '
                            : 'input-group input-group-sm  '
                    }
                >
                    <div className="input-group-prepend">
                        <input
                            className="overflow-hidden d-none"
                            disabled={this.props.uploadButtonOff}
                            id={this.props.id}
                            type="file"
                            onChange={this.props.handleChange}
                            onClick={(event) => {
                                event.target.value = null
                            }}
                        />
                        <label
                            htmlFor={this.props.id}
                            id={this.props.id}
                            type="reset"
                            className="mb-0 btn btn-sm btn-secondary"
                        >
                            Upload
                            <i className="pl-1 fas fa-file-upload" />
                        </label>
                    </div>

                    <div className="input-group  ">
                        {this.state.value.length !== 0
                            ? this.state.value.map((i) => (
                                  <small className="c-span-round ">
                                      <span
                                          className="c-link"
                                          style={{ textAlign: 'center' }}
                                          id={i}
                                          value={i}
                                          onClick={
                                              this.props.handleFileDownload
                                          }
                                          htmlFor={this.props.id}
                                      >
                                          {i.split(/_(.+)/)[1]}
                                      </span>
                                      <span>
                                          <label className="  pl-1 c-link ">
                                              <input
                                                  className="d-none"
                                                  value={i}
                                                  id={this.props.id}
                                                  disabled={
                                                      this.props.cancelButtonOF
                                                  }
                                                  onClick={(event) => {
                                                      this.removeFile(event)
                                                  }}
                                              />{' '}
                                              x
                                          </label>
                                      </span>
                                  </small>
                              ))
                            : null}
                    </div>
                </div>
                {this.props.uploadPercentage > 0 && (
                    <ProgressBar
                        className={
                            this.props.called === this.props.id
                                ? 'mt-1'
                                : 'd-none'
                        }
                        now={this.props.uploadPercentage}
                        label={`${this.props.uploadPercentage}%`}
                    />
                )}
            </>
        )
    }
}
