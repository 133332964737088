import React, { Component } from 'react'
import { API } from '../config'
import Dropdown from 'react-dropdown'
import LoadingOverlay from 'react-loading-overlay'
import {
    PO_APPROVED_BY_PERIOD_REPORT,
    PO_STATUS_TYPE_FOR_REPORT,
    TOUCH_POINT2,
} from '../../utils/constant'
import { getCreatedCompanyList } from '../../utils/event-handlers'
import axios from 'axios'
import { downloadExcelReport } from '../../utils/download-excel'
import Select from 'react-dropdown-select'
import { toastNotify } from '../../utils/Alert-msg'
import { getApiCallDynamic } from '../../service/index'

var XLSX = require('xlsx')

export class POApprovedByPeriod extends Component {
    constructor(props) {
        super(props)
        console.log('this.props', this.props)

        this.state = {
            poId: '',
            startDate: null,
            endDate: null,
            approvedTime: null,

            touchPointList: [],
            status: '',
            tpName: '',
            tpId: null,
            companyName: '',
            companyCode: '',
            companyList: [],
            spinner: false,
            departmentList: [],
            department: '',
            supplierList: [],
            supplier: '',
        }
        this.onChangeSearchFilter = this.onChangeSearchFilter.bind(this)
    }
    getApprovedCompany = () => {
        getCreatedCompanyList('APPROVED', 'vn')
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        companyList: res.data,
                    })
                    console.log(this.state.companyList)
                }
            })
            .catch((err) => err)
    }
    defaultParam = () => {
        const {
            roleList,
            businessInfo: { touchPointName },
            businessInfo: { touchPointId },
        } = this.props.user.data
        this.setState({
            status: roleList.some((i) => i.includes('tp::adm'))
                ? 'ALL'
                : 'PENDING',
            tpName: roleList.some((i) => i.includes('tp::adm'))
                ? 'None'
                : touchPointName,
            tpId: roleList.some((i) => i.includes('tp::adm'))
                ? null
                : touchPointId,
        })
    }

    getTouchPoint = (id) => {
        let queryParameter = { templateId: 2, orgId: 1 }
        getApiCallDynamic(queryParameter, API.touchPointSequence)
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    let data = []
                    data.push({ id: null, name: 'None', code: 'vn' })

                    this.setState({
                        touchPointList: data.concat(res.data),
                    })
                    console.log(this.state.touchPointList)
                }
            })
            .catch((err) => err)
    }

    departmentRefresh = () => {
        getApiCallDynamic({}, API.departmentName).then((response) => {
            if (response?.status === 200) {
                this.setState({
                    departmentList: response.data,
                })
            }
        })
    }

    supplierRefresh = () => {
        getApiCallDynamic({}, API.supplierName).then((response) => {
            if (response?.status === 200) {
                this.setState({
                    supplierList: response.data,
                })
            }
        })
    }

    componentDidMount() {
        this.defaultParam()
        this.getApprovedCompany()
        this.departmentRefresh()
        this.supplierRefresh()
        this.getTouchPoint(1) //orgId==1
    }

    onChangeSearchFilter(e) {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    handleDropDownChange =
        (val, name, multi = false) =>
        (event) => {
            console.log('drop', event, 'val', val, 'name', name, 'multi', multi)
            if (multi) {
                if (event.length === 0) {
                    this.setState({
                        [val]: '',
                        // [name]: ''
                    })
                    return
                }
                event = event[0]
            }
            this.setState({
                [val]: event.value,
                // [name]: event.label
            })
        }

    getRequestParams(
        poId,
        startDate,
        endDate,
        approvedTime,
        status,
        tpId,
        companyCode,
        companyName,
        department,
        supplier,
    ) {
        let params = {}

        if (poId) {
            params['poId'] = poId
        }
        if (companyCode) {
            params['companyCode'] = companyCode
        }
        if (companyName) {
            params['companyName'] = companyName
        }

        if (startDate) {
            params['startDate'] = startDate
        }
        if (endDate) {
            params['endDate'] = endDate
        }
        if (approvedTime) {
            params['approvedTime'] = approvedTime
        }
        if (tpId) {
            params['touchPointId'] = tpId
        }
        if (status) {
            params['status'] = status
        }
        if (department) {
            params['department'] = department
        }
        if (supplier) {
            params['supplier'] = supplier
        }

        return params
    }
    findTouchPointName = (a) => {
        let tpName = ''
        this.state.touchPointList.map((column) => ({
            column: column.id === a ? (tpName = column.name) : '',
        }))

        return tpName
    }

    downloadData = () => {
        this.setState({
            excelData: [],

            spinner: true,
        })
        console.log('rrrrrr')
        let {
            poId,
            startDate,
            endDate,
            approvedTime,
            status,
            tpId,
            companyCode,
            companyName,
            department,
            supplier,
        } = this.state

        const params = this.getRequestParams(
            poId,
            startDate,
            endDate,
            approvedTime,
            status,
            tpId,
            companyCode,
            companyName,
            department,
            supplier,
        )

        if (status === 'PENDING') {
            if (tpId === '' || tpId === null) {
                this.setState({
                    excelData: [],

                    spinner: false,
                })
                console.log(excelData)
                return toastNotify('warn', 'Please select Touch Point')
            }
        }
        getApiCallDynamic(params, API.poApprovedByPeriod)
            .then((response) => {
                if (response?.status === 200) {
                    const { data, totalPages, currentPage } = response
                    console.log('Data', data)
                    if (response.data.length > 0) {
                        const columns = data.map((column) => ({
                            poId: column.poId,
                            vendorName: column.vendorName,
                            department: column.department,
                            approvedTime: column.approvedTime
                                ? new Date(column.approvedTime)
                                : '',
                            revisedFobDate: new Date(column.revisedFobDate),
                            activeTouchPointName: this.findTouchPointName(
                                column.activeTouchPointState,
                            ),
                        }))

                        const totalValueInUSD = data
                            .map((col) => col.totalValueInUSD)
                            .reduce((acc, col) => col + acc)

                        console.log('download api call', columns)
                        downloadExcelReport(
                            columns,
                            PO_APPROVED_BY_PERIOD_REPORT.CONTENT_HEADER,
                            PO_APPROVED_BY_PERIOD_REPORT.FILE_NAME,
                            totalValueInUSD,
                            PO_APPROVED_BY_PERIOD_REPORT.USD_CONTENT_HEADER,
                        )
                    } else {
                        toastNotify('warn', 'No Record Found')
                    }
                    this.setState({
                        excelData: columns,
                        spinner: false,
                    })
                }
            })
            .catch((e) => {
                this.setState({
                    excelData: [],
                    page: 1,
                    spinner: false,
                })
                // console.log(e)
            })
    }
    render() {
        const {
            poId,
            data,
            currentTutorial,
            currentIndex,
            page,
            count,
            pageSize,
            startDate,
            approvedTime,
            endDate,
        } = this.state

        let PO_APPROVED_BY_REPORT_FIELDS = (
            <tr>
                <td rowSpan={2}>
                    <label className="from-control from-control-sm">
                        Approval Date:
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            placeholder="Search by title"
                            value={approvedTime}
                            id="approvedTime"
                            onChange={this.onChangeSearchFilter}
                        />
                    </label>
                </td>
                {/* <td rowSpan={2}>
                    <label className="from-control from-control-sm">
                        Status
                        <sup className="text-danger">*</sup>
                    </label>
                    <Dropdown
                        controlClassName={`form-control form-control-sm `}
                        className={` Dropdown-root-width `}
                        value={this.state.status}
                        id="status"
                        options={PO_STATUS_TYPE_FOR_REPORT}
                        onChange={this.handleDropDownChange('status')}
                        placeholder="Select an option"
                    />
                </td> */}
                <td rowSpan={2}>
                    <label class="pr-2">Supplier</label>
                    <div className=" " style={{ minWidth: '300px' }}>
                        <Select
                            multi={false}
                            // className={`form-control form-control-sm w-60 f-r select select-search  ${
                            //     errors.region
                            //         ? 'border border-danger is-invalid'
                            //         : ''
                            // } `}
                            className={
                                'form-control form-control-sm  select select-search  '
                            }
                            style={{ minHeight: 'unset' }}
                            clearOnBlur={true}
                            clearable={true}
                            placeholder="Select..."
                            values={[
                                {
                                    label: this.state.supplier,
                                },
                            ]}
                            id="supplier"
                            options={this.state.supplierList.map((i) => {
                                return {
                                    value: i,
                                    label: i,
                                }
                            })}
                            // onChange={this.handleDropDownChange(
                            //     'country',
                            // )}
                            onChange={this.handleDropDownChange(
                                'supplier',
                                '',
                                true,
                            )}
                        />
                    </div>
                </td>
                <td rowSpan={2}>
                    <label class="pr-2">Department</label>
                    <div className=" " style={{ minWidth: '100px' }}>
                        <Select
                            multi={false}
                            // className={`form-control form-control-sm w-60 f-r select select-search  ${
                            //     errors.region
                            //         ? 'border border-danger is-invalid'
                            //         : ''
                            // } `}
                            className={
                                'form-control form-control-sm  select select-search  '
                            }
                            style={{ minHeight: 'unset' }}
                            clearOnBlur={true}
                            clearable={true}
                            placeholder="Select..."
                            values={[
                                {
                                    label: this.state.department,
                                },
                            ]}
                            id="department"
                            options={this.state.departmentList.map((i) => {
                                return {
                                    value: i,
                                    label: i,
                                }
                            })}
                            // onChange={this.handleDropDownChange(
                            //     'country',
                            // )}
                            onChange={this.handleDropDownChange(
                                'department',
                                '',
                                true,
                            )}
                        />
                    </div>
                </td>

                <td></td>
                {/* <td></td> */}
            </tr>
        )

        return (
            <LoadingOverlay
                active={this.state.spinner}
                // spinner={<BounceLoader />}
                spinner={true}
                text="Please wait a moment"
            >
                <div className="container-fluid h-100">
                    <div className="row">
                        <div className="col-md-auto py-3 m-auto">
                            <h2>PO Approved By Period</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-auto m-auto">
                            <div className="p-1">
                                {/* decide upon role */}

                                <div className="center list row">
                                    <div className="centerItem col-md-12">
                                        <table className="table table-condensed">
                                            <tbody>
                                                {PO_APPROVED_BY_REPORT_FIELDS}
                                                <tr></tr>
                                                <tr>
                                                    <td rowSpan={2} colSpan={4}>
                                                        <label className="from-control from-control-sm"></label>

                                                        <button
                                                            className="from-control btn btn-sm btn-outline-primary"
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .downloadData
                                                            }
                                                        >
                                                            Download As Excel
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}
