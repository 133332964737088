export const stringInverter = (event, props, string) => {
    console.log('inverter', event, props, string)

    if (props.state[event.target.id] === string) {
        props.setState({
            [event.target.id]: '',
        })
    } else {
        props.setState({
            [event.target.id]: string,
        })
    }
}
