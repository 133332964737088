import React, { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'

export default function ConfirmModal(props) {
    const [show, setShow] = useState(props.alertShow)

    const handleClose = () => setShow(false)
    const handleShow = () => {
        console.log('props', props)

        setShow(true)
    }

    const handleCall = () => {
        props.handler()

        setShow(false)
    }
    const { selectionType } = props

    return (
        <>
            {selectionType === 'icon' ? (
                <i
                    className={props.className}
                    id={props.id}
                    onClick={handleShow}
                ></i>
            ) : (
                <Button
                    className={props.className}
                    id={props.id}
                    variant="primary"
                    onClick={handleShow}
                >
                    {props.buttonName}
                </Button>
            )}
            {props.button === 'logout' ? (
                <a
                    className="dropdown-item"
                    id="logoutModal"
                    onClick={handleShow}
                >
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                </a>
            ) : null}
            <Modal show={show} centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.message}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary mr-2"
                        id={props.id}
                        onClick={handleClose}
                    >
                        No
                    </Button>
                    <Button
                        variant="primary"
                        id={props.id}
                        onClick={handleCall}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
