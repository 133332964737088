import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import { EVENT_MODEL_CLOSE } from '../utils/constant'

export const ModalCommon = (props) => {
    return (
        <Modal
            centered
            size={props.size}
            className="scroll4"
            show={props.show}
            onHide={(e) => {
                props.handleClose(EVENT_MODEL_CLOSE)
            }}
            aria-labelledby="contained-modal-title-vcenter"
            open
        >
            <Modal.Header>
                <div>
                    <div style={{ float: 'left' }}>
                        <img id="1" src="/assets/images/eDoc.png" height="30" />
                    </div>
                </div>
                <Modal.Title className="w-100">
                    <div className="text-center ">
                        <h1 className="h4 text-gray-900">
                            {props.tittle ? props.tittle : 'Information Update'}
                        </h1>
                    </div>
                </Modal.Title>
                <button
                    id={'show'}
                    type="button"
                    className="close float-right"
                    onClick={props.handleClose}
                    aria-label="Close"
                >
                    <span id={'show'} aria-hidden="true">
                        &times;
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body className="l-bg">{props.load}</Modal.Body>
        </Modal>
    )
}
