import React, { Component } from 'react'
import Pagination from '@mui/material/Pagination'
import axios from 'axios'
import { getPoList, PO_STATUS_TYPE } from '../utils/constant'
import Dropdown from 'react-dropdown'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import { getCreatedCompanyList } from '../utils/event-handlers'
import { toastNotify } from '../utils/Alert-msg'
import LoadingOverlay from 'react-loading-overlay'
import { API } from '../containers/config'
import { getApiCallDynamic } from '../service'

export default class PoListPagination extends Component {
    constructor(props) {
        super(props)
        console.log('--user---', this.props.user.data)
        this.onChangeSearchFilter = this.onChangeSearchFilter.bind(this)
        this.retrievePoLists = this.retrievePoLists.bind(this)
        // this.refreshList = this.refreshList.bind(this);
        // this.setActiveTutorial = this.setActiveTutorial.bind(this);
        // this.removeAllTutorials = this.removeAllTutorials.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this)

        this.state = {
            data: [],
            excelData: [],
            currentTutorial: null,
            currentIndex: -1,
            poId: '',
            startDate: null,
            endDate: null,

            page: 1,
            count: 0,
            pageSize: 50,
            touchPointList: [],
            status: '',
            tpName: '',
            tpId: null,
            companyName: '',
            companyCode: '',
            companyList: [],
            spinner: false,
        }

        this.pageSizes = [50, 100, 200]
    }

    getTouchPoint = (id) => {
        let queryParameter = { templateId: 2, orgId: 1 }
        getApiCallDynamic(queryParameter, API.touchPointSequence)
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    let data = []
                    data.push({ id: null, name: 'None', code: 'vn' })

                    this.setState({
                        touchPointList: data.concat(res.data),
                    })
                    console.log(this.state.touchPointList)
                }
            })
            .catch((err) => err)
    }
    getApprovedCompany = () => {
        getCreatedCompanyList('APPROVED', 'vn')
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        companyList: res.data,
                    })
                    console.log(this.state.companyList)
                }
            })
            .catch((err) => err)
    }
    defaultParam = () => {
        const {
            roleList,
            businessInfo: { touchPointName },
            businessInfo: { touchPointId },
        } = this.props.user.data
        this.setState(
            {
                status: roleList.some((i) => i.includes('tp::adm'))
                    ? 'ALL'
                    : 'PENDING',
                tpName: roleList.some((i) => i.includes('tp::adm'))
                    ? 'None'
                    : touchPointName,
                tpId: roleList.some((i) => i.includes('tp::adm'))
                    ? null
                    : touchPointId,
            },
            () => this.retrievePoLists(),
        )
    }
    componentDidMount() {
        this.defaultParam()
        this.getTouchPoint(1) //orgId==1
        this.getApprovedCompany()
    }

    onChangeSearchFilter(e) {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    onClear = (e) => {
        console.log('onClear', e.target.id)
        this.setState(
            {
                [e.target.id]: '',
            },
            () => this.retrievePoLists(),
        )
    }

    handleDropDownChange =
        (val, name, multi = false) =>
        (event) => {
            console.log('drop', event, 'val', val, 'name', name, 'multi', multi)
            if (multi) {
                if (event.length === 0) {
                    this.setState({
                        [val]: '',
                        // [name]: ''
                    })
                    return
                }
                event = event[0]
            }
            this.setState({
                [val]: event.value,
                // [name]: event.label
            })
        }

    getRequestParams(
        poId,
        page,
        pageSize,
        startDate,
        endDate,
        status,
        tpId,
        companyCode,
        companyName,
    ) {
        let params = {}

        if (poId) {
            params['poId'] = poId
        }
        if (companyCode) {
            params['companyCode'] = companyCode
        }
        if (companyName) {
            params['companyName'] = companyName
        }

        if (page) {
            params['page'] = page - 1
        }

        if (pageSize) {
            params['size'] = pageSize
        }
        if (startDate) {
            params['startDate'] = startDate
        }
        if (endDate) {
            params['endDate'] = endDate
        }
        if (tpId && this.state.status !== 'COMPLETED') {
            params['touchPointId'] = tpId
        }
        if (status) {
            params['status'] = status
        }

        return params
    }

    handlePageChange(event, value) {
        this.setState(
            {
                page: value,
            },
            () => {
                this.retrievePoLists(true)
            },
        )
    }

    handlePageSizeChange(event) {
        this.setState(
            {
                pageSize: event.target.value,
                page: 1,
            },
            () => {
                this.retrievePoLists(true)
            },
        )
    }

    retrievePoLists(pageChange = false) {
        this.setState({
            data: [],

            spinner: true,
        })
        console.log('rrrrrr')
        let {
            poId,
            page,
            pageSize,
            startDate,
            endDate,
            status,
            tpId,
            companyCode,
            companyName,
        } = this.state

        if (pageChange === true) {
        } else {
            page = 1
        }

        const params = this.getRequestParams(
            poId,
            page,
            pageSize,
            startDate,
            endDate,
            status,
            tpId,
            companyCode,
            companyName,
        )

        if (status === 'PENDING') {
            if (tpId === '' || tpId === null) {
                this.setState({
                    data: [],

                    spinner: false,
                })
                return toastNotify('warn', 'Please select Touch Point')
            }
        }
        axios
            .get(`/scem/api/v1/po`, { params: params })
            .then((response) => {
                const { dataList, totalPages, currentPage } = response.data
                this.props.poPendingCount()
                this.setState({
                    data: dataList,
                    count: totalPages,
                    spinner: false,
                    page: currentPage + 1,
                })
                console.log(response.data)
            })
            .catch((e) => {
                this.setState({
                    data: [],
                    page: 1,
                    spinner: false,
                })
                console.log(e)
            })
    }

    render() {
        const {
            poId,
            data,
            currentTutorial,
            currentIndex,
            page,
            count,
            pageSize,
            startDate,
            endDate,
        } = this.state

        const parentMethod = {
            onClear: this.onClear,
            state: this.state,
            refresh: this.retrievePoLists,
            handleDropDownChange: this.handleDropDownChange,
            handleChange: this.onChangeSearchFilter,
        }

        return (
            <div className="list row">
                <div className="col-md-12">
                    <table className="table table-condensed">
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td>
                                    <label className="from-control from-control-sm">
                                        FOB Start Date:
                                        <input
                                            type="date"
                                            className="form-control form-control-sm"
                                            placeholder="Search by title"
                                            value={startDate}
                                            id="startDate"
                                            onChange={this.onChangeSearchFilter}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <label className="from-control from-control-sm">
                                        FOB End Date
                                        <input
                                            type="date"
                                            className="form-control form-control-sm"
                                            placeholder="Search by title"
                                            value={endDate}
                                            id="endDate"
                                            onChange={this.onChangeSearchFilter}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <label className="from-control from-control-sm">
                                        Status
                                        <sup className="text-danger">*</sup>
                                    </label>
                                    <Dropdown
                                        controlClassName={`form-control form-control-sm `}
                                        className={` Dropdown-root-width `}
                                        value={this.state.status}
                                        id="status"
                                        options={PO_STATUS_TYPE}
                                        onChange={this.handleDropDownChange(
                                            'status',
                                        )}
                                        placeholder="Select an option"
                                    />
                                </td>
                                <td
                                // className={
                                //     this.props.vendor ? 'd-none' : ''
                                // }
                                >
                                    <label className="from-control from-control-sm">
                                        Touch Point
                                        <sup className="text-danger">*</sup>
                                    </label>

                                    <Dropdown
                                        controlClassName={`form-control form-control-sm `}
                                        className={` Dropdown-root-width `}
                                        value={
                                            this.state.status == 'COMPLETED'
                                                ? 'none'
                                                : this.state.tpName
                                        }
                                        id="tpId"
                                        options={this.state.touchPointList.map(
                                            (i) => {
                                                return {
                                                    value: i.id,
                                                    label: i.name,
                                                }
                                            },
                                        )}
                                        onChange={this.handleDropDownChange(
                                            'tpId',
                                            'tpName',
                                        )}
                                        placeholder="Select an option"
                                        disabled={
                                            this.state.status == 'COMPLETED'
                                        }
                                    />
                                </td>
                                <td>
                                    <label className="from-control from-control-sm"></label>

                                    <button
                                        className="from-control btn btn-sm btn-outline-primary"
                                        type="button"
                                        onClick={this.retrievePoLists}
                                    >
                                        Search
                                    </button>
                                </td>
                                <td></td>
                                {/* <td></td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 overflow-hidden mb-2">
                    <div className=" float-left">
                        {'Items per Page: '}
                        <select
                            onChange={this.handlePageSizeChange}
                            value={pageSize}
                        >
                            {this.pageSizes.map((size) => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className=" float-right">
                        <Pagination
                            className=""
                            showFirstButton
                            showLastButton
                            count={count}
                            page={page}
                            siblingCount={3}
                            boundaryCount={1}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={this.handlePageChange}
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <LoadingOverlay
                        active={this.state.spinner}
                        // spinner={<BounceLoader />}
                        spinner={true}
                        text="Please wait a moment"
                    >
                        <BootstrapTable
                            className={'min-height scroll5'}
                            keyField="id"
                            data={this.state.data}
                            columns={getPoList(
                                (page - 1) * pageSize,
                                parentMethod,
                            )}
                            selectRow={this.props.selectRow}
                            hover
                            condensed
                            filter={filterFactory()}
                            noDataIndication="Table is Empty"
                        />
                    </LoadingOverlay>
                </div>
            </div>
        )
    }
}
