import React, { Component } from 'react'
import { getApiCallDynamic } from '../../service'
import { getCreatedUserList } from '../../utils/event-handlers'
import { API } from '../config'
import { UserRegistration } from '../organization/user-reg'

export default class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedData: {},
            userDetails: [],
        }
    }

    handleSelectedRow = (row, isSelect, rowIndex, e) => {
        console.log(
            'row====>',
            row,
            'isSelected=>>>>',
            isSelect,
            'rowIndex=>>>>>>>',
            rowIndex,
            'Event=>>>>',
            e,
        )
        this.setState({
            selectedData: row,
            selectedRow: rowIndex,
            show: true,
        })
    }

    refresh = () => {
        let queryParameter = { id: this.props.user.data.businessInfo.userId }
        getApiCallDynamic(queryParameter, API.createdUserList)
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        userDetails: res.data,
                        selectedData: res.data,
                        show: true,
                    })
                    this.setState({})
                }
            })
            .catch((err) => err)
    }

    handleDropDownChange = (val) => (event) => {
        this.state[val] = event.value
        this.setState()

        getCreatedUserList(event.value)
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        userDetails: res.data,
                    })
                    console.log(this.state.userDetails)
                }
            })
            .catch((err) => err)
    }

    componentDidMount() {
        this.refresh()
    }

    handleClose = () => {
        this.setState({
            show: false,
        })
    }

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true, // enable hide selection column.

            clickToSelect: true,
            onSelect: this.handleSelectedRow,
        }

        const props = this.props

        return (
            <div id="company_list" className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="col-lg-12" align="center"></div>
                        <div className="p-1">
                            <h2 className="text-center  py-3">
                                Personal Information
                            </h2>

                            {this.state.show ? (
                                <UserRegistration
                                    {...props}
                                    call={'profile'}
                                    edit={true}
                                    tittle={'Profile'}
                                    status={true}
                                    data={this.state.selectedData}
                                    handleClose={this.handleClose}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
