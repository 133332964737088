import React, { Component } from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import { Path } from '../containers/config.js'
import { PASSWORD_LENGTH } from '../api/config'
import { Auth } from '../api/auth.js'

export default class ChangePassword extends Component {
    constructor(props) {
        super(props)
        console.log('Change password', this.props)
        this.state = {
            password: '',
            confirmPassword: '',
            oldPassword: '',
            username: this.props.expire
                ? this.props.username
                : this.props.user.data.sub,

            icon: 'fa-eye',
            tagType: 'password',
            iconConfirm: 'fa-eye',
            tagTypeConfirm: 'password',

            iconOldPassword: 'fa-eye',
            tagTypeOldPassword: 'password',
        }
    }

    handleChange = (event) => {
        console.log(
            '---ID---',
            event.target.id,
            '---value---',
            event.target.value,
        )
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    handleShowPassword = (event) => {
        console.log(event)
        if (event.target.id === 'password') {
            if (this.state.tagType === 'password') {
                this.setState({
                    tagType: 'text',
                    icon: 'fa-eye-slash',
                })
            } else {
                this.setState({
                    tagType: 'password',
                    icon: 'fa-eye',
                })
            }
        }
        if (event.target.id === 'confirmPassword') {
            if (this.state.tagTypeConfirm === 'password') {
                this.setState({
                    tagTypeConfirm: 'text',
                    iconConfirm: 'fa-eye-slash',
                })
            } else {
                this.setState({
                    tagTypeConfirm: 'password',
                    iconConfirm: 'fa-eye',
                })
            }
        }

        if (event.target.id === 'oldPassword') {
            if (this.state.tagTypeOldPassword === 'password') {
                this.setState({
                    tagTypeOldPassword: 'text',
                    iconOldPassword: 'fa-eye-slash',
                })
            } else {
                this.setState({
                    tagTypeOldPassword: 'password',
                    iconOldPassword: 'fa-eye',
                })
            }
        }
    }

    handleValidation = () => {
        let errors = {}
        let count = 0
        var regNumber = /[0-9]+/
        var regCapitalAlpha = /[A-Z]+/
        var regSmallAlpha = /[a-z]+/
        var regSpecialChar = /[*@!#%&()^~{}]+/

        if (this.state.oldPassword === '') {
            errors.oldPassword = 'Please enter your current password'
        }

        if (this.state.password !== this.state.confirmPassword) {
            errors.confirmPassword = 'Password did not match'
        }

        if (this.state.password.includes(' ')) {
            errors.password = "Password can't contain white space"
        } else {
            if (
                this.state.password
                    .toLowerCase()
                    .includes(this.state.username.toLowerCase())
            ) {
                errors.password = "Password can't contain user name"
            } else if (this.state.password.toLowerCase().includes('admin')) {
                errors.password = "Password can't contain admin, administrator"
            } else if (
                this.state.password.toLowerCase().includes('administrator')
            ) {
                errors.password = "Password can't contain admin, administrator"
            } else {
                if (this.state.password.length < PASSWORD_LENGTH) {
                    errors.password = `Password length must be ${PASSWORD_LENGTH}`
                } else {
                    if (regNumber.test(this.state.password)) {
                        count = count + 1
                    }
                    if (regCapitalAlpha.test(this.state.password)) {
                        count = count + 1
                    }
                    if (regSmallAlpha.test(this.state.password)) {
                        count = count + 1
                    }
                    if (regSpecialChar.test(this.state.password)) {
                        count = count + 1
                    }
                    if (count < 3) {
                        errors.password =
                            'Password must contain One numbers, one capital alphabet. One small alphabet, One Special character'
                    }
                }
            }
        }

        console.log(
            regNumber.test(this.state.password),
            regCapitalAlpha.test(this.state.password),
        )

        return errors
    }

    handleSubmit = () => {
        console.log('Change Password', this.state)
        let data = {
            currentPassword: this.state.oldPassword,
            newPassword: this.state.password,
        }
        if (this.props.expire) {
            Auth.expirePassword(data).then((response) => {
                this.props.props.userHasAuthenticated(response[0], response[1])
                this.setState({
                    spinner: false,
                })

                if (response[0]) {
                    alert('Password updated successfully')
                    this.props.props.history.push(Path.index)
                } else {
                    alert(response[1].error.errorMessage)
                }
            })
        } else {
            Auth.changePassword(data).then((response) => {
                this.props.userHasAuthenticated(response[0], response[1])
                this.setState({
                    spinner: false,
                })

                if (response[0]) {
                    alert('Password updated successfully')
                    this.props.history.push(Path.home)
                } else {
                    alert(response[1].error.errorMessage)
                }
            })
        }
    }

    /* To Do Ad user redirect */
    render() {
        return (
            <>
                <Formik
                    initialValues={this.state}
                    validate={(values) => this.handleValidation(this, values)}
                    onSubmit={(values, { setSubmitting }) => {
                        this.handleSubmit()
                        setSubmitting(false)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <>
                            <Form>
                                <div>
                                    <div className="container ">
                                        <div className="row ">
                                            <div className="col-lg-12">
                                                <div className="p-1">
                                                    <h2 className="text-center  py-3">
                                                        {'Change Password'}
                                                    </h2>

                                                    {/* <!-- Login --> */}

                                                    <div className="row justify-content-center">
                                                        <div className="col-md-4 card  p-3 shadow">
                                                            <div className="mb-3 form-group">
                                                                <label
                                                                    className="form-label"
                                                                    for="email"
                                                                >
                                                                    Current
                                                                    Password
                                                                    <sup className="text-danger">
                                                                        *
                                                                    </sup>
                                                                </label>
                                                                <div className="input-group ">
                                                                    <input
                                                                        id="oldPassword"
                                                                        type={
                                                                            this
                                                                                .state
                                                                                .tagTypeOldPassword
                                                                        }
                                                                        className={`form-control  ${
                                                                            touched.oldPassword &&
                                                                            errors.oldPassword
                                                                                ? 'is-invalid invalid-pad'
                                                                                : ''
                                                                        }`}
                                                                        required
                                                                        placeholder="Old Password "
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            id="oldPassword"
                                                                            className="input-group-text text-origin"
                                                                            onClick={
                                                                                this
                                                                                    .handleShowPassword
                                                                            }
                                                                        >
                                                                            <i
                                                                                id="oldPassword"
                                                                                toggle="#password"
                                                                                className={`fa  ${this.state.iconOldPassword}  toggle-password`}
                                                                            ></i>
                                                                        </span>
                                                                    </div>
                                                                    <ErrorMessage
                                                                        component="div"
                                                                        name={
                                                                            'oldPassword'
                                                                        }
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="mb-3 form-group">
                                                                <label
                                                                    className="form-label"
                                                                    for="email"
                                                                >
                                                                    New Password
                                                                    <sup className="text-danger">
                                                                        *
                                                                    </sup>
                                                                </label>
                                                                <div className="input-group ">
                                                                    <input
                                                                        id="password"
                                                                        type={
                                                                            this
                                                                                .state
                                                                                .tagType
                                                                        }
                                                                        className={`form-control  ${
                                                                            touched.password &&
                                                                            errors.password
                                                                                ? 'is-invalid invalid-pad'
                                                                                : ''
                                                                        }`}
                                                                        required
                                                                        placeholder="Password "
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            id="password"
                                                                            className="input-group-text text-origin"
                                                                            onClick={
                                                                                this
                                                                                    .handleShowPassword
                                                                            }
                                                                        >
                                                                            <i
                                                                                id="password"
                                                                                toggle="#password"
                                                                                className={`fa  ${this.state.icon}  toggle-password`}
                                                                            ></i>
                                                                        </span>
                                                                    </div>
                                                                    <ErrorMessage
                                                                        component="div"
                                                                        name={
                                                                            'password'
                                                                        }
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="mb-3 form-group">
                                                                <label
                                                                    className="form-label"
                                                                    for="email"
                                                                >
                                                                    Confirm
                                                                    Password
                                                                    <sup className="text-danger">
                                                                        *
                                                                    </sup>
                                                                </label>
                                                                <div className="input-group ">
                                                                    <input
                                                                        id="confirmPassword"
                                                                        type={
                                                                            this
                                                                                .state
                                                                                .tagTypeConfirm
                                                                        }
                                                                        className={`form-control  ${
                                                                            touched.confirmPassword &&
                                                                            errors.confirmPassword
                                                                                ? 'is-invalid invalid-pad'
                                                                                : ''
                                                                        }`}
                                                                        required
                                                                        placeholder="Confirm Password"
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />

                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            id="confirmPassword"
                                                                            className="input-group-text text-origin"
                                                                            onClick={
                                                                                this
                                                                                    .handleShowPassword
                                                                            }
                                                                        >
                                                                            <i
                                                                                id="confirmPassword"
                                                                                toggle="#password"
                                                                                className={`fa  ${this.state.iconConfirm}  toggle-password`}
                                                                            ></i>
                                                                        </span>
                                                                    </div>
                                                                    <ErrorMessage
                                                                        component="div"
                                                                        name={
                                                                            'confirmPassword'
                                                                        }
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="text-center">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-outline-success m-3"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </>
        )
    }
}
