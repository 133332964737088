import { Link } from '@material-ui/core'
import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { toastNotify } from '../utils/Alert-msg'
import { uploadFile, uploadPOFile } from '../utils/event-handlers'

export default class PoCreationFileUploadPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            file: '',
            uploadPercentage: 0,
        }
    }

    handleChange = (event) => {
        if (event.target.type === 'file') {
            this.setState({
                file: event.target.files[0],
            })
        } else {
            this.setState({
                [event.target.id]: event.target.value,
            })
        }
    }

    onSubmit = () => {
        console.log('file', this.state.file)
        if (this.state.file === undefined || this.state.file === '') {
            toastNotify('warn', 'Please Select a file')
        } else {
            let fileSize = this.state.file.size / 1048576

            if (fileSize > 50) {
                toastNotify('error', 'File size can not be grater than 50mb')
                return
            }
            console.log('who', this.state.file)
            let blnValid = false
            let _validFileExtensions = ['.csv']
            // if()
            for (var j = 0; j < _validFileExtensions.length; j++) {
                var sCurExtension = _validFileExtensions[j]
                if (
                    this.state.file.name
                        .substr(
                            this.state.file.name.length - sCurExtension.length,
                            sCurExtension.length,
                        )
                        .toLowerCase() == sCurExtension.toLowerCase()
                ) {
                    blnValid = true
                    break
                }
            }

            if (!blnValid) {
                toastNotify('warn', 'File extension missmatch')
            } else {
                const data = new FormData()
                this.setState({
                    spinner: true,
                })
                const option = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent
                        let percent = Math.floor((loaded * 100) / total)

                        if (percent < 100) {
                            this.setState({
                                uploadPercentage: percent,
                            })
                        }
                    },
                }

                data.append('fileToUpload', this.state.file)

                uploadPOFile(data, option).then((res) => {
                    if (
                        (res !== undefined && res.status === 200) ||
                        (res !== undefined && res.status === 201)
                    ) {
                        let data = res.data
                        this.setState(
                            {
                                uploadPercentage: 100,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({ uploadPercentage: 0 })
                                    toastNotify(
                                        'success',
                                        'File uploaded successfully',
                                    )
                                }, 1000)
                            },
                        )
                    }
                    this.setState({
                        spinner: false,
                    })
                })
            }
        }
    }

    render() {
        return (
            <div id="company_list" className="container-fluid">
                <div className="row">
                    <div className="col-lg-12" align="center">
                        <div className="p-1">
                            <div className="col-md-6 py-3 m-auto">
                                <h2 className="text-center pb-3">
                                    PO Xd file upload
                                </h2>
                                <div
                                    className=""
                                    style={{
                                        backgroundColor: '#fff',
                                        padding: '50px',
                                    }}
                                >
                                    <div className="po-upload">
                                        <input
                                            type="file"
                                            onChange={this.handleChange}
                                            accept=".csv"
                                        />
                                        <button
                                            className="btn btn-sm btn-outline-success"
                                            onClick={this.onSubmit}
                                        >
                                            Upload
                                        </button>
                                    </div>
                                    <small className="text-danger">
                                        Po XD File format{' '}
                                        <Link
                                            className="link"
                                            href="/files/xd07_report_header.csv"
                                            target="_blank"
                                            download
                                        >
                                            Click here!
                                        </Link>
                                    </small>

                                    {this.state.uploadPercentage > 0 && (
                                        <ProgressBar
                                            className={'mt-1'}
                                            now={this.state.uploadPercentage}
                                            label={`${this.state.uploadPercentage}%`}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
