import { element } from 'prop-types'
import React from 'react'
import { stringInverter } from '../utils/html-css-event-handler'

export default function CardView(props) {
    const { state } = props.this
    const { poSequence } = props.this.props
    const { html, buttonName, value } = props

    return !poSequence.some((element) => element.code === props.code) ? (
        <></>
    ) : (
        <div className="card">
            <div className="card-header" id="headingOne">
                <h2 className="mb-0">
                    <button
                        className={
                            state[value] === 'show'
                                ? 'btn btn-full btn-link touch-dropdown-collapse '
                                : 'btn btn-full btn-link touch-dropdown'
                        }
                        id={value}
                        onClick={(e) => {
                            stringInverter(e, props.this, 'show')
                        }}
                        type="button"
                        data-toggle="collapse"
                        data-target="#id"
                        aria-expanded="true"
                        aria-controls="id"
                    >
                        {buttonName}
                    </button>
                </h2>
            </div>
            <div
                id={value}
                className={`collapse ${state[value]}`}
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
            >
                <div className="card-body">{html}</div>
            </div>
        </div>
    )
}
