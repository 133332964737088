import React from 'react'
import { ModalCommon } from '../../components/modal'
import {
    getPOList,
    SetupDashBoardHandlerClass,
} from '../../utils/event-handlers'
import Vendor from './vendor'
import CFS from './cfs'
import Sourcing from './sourcing'
import ForwarderAtOrigin from './forwarder-at-origin'
import BankAtOriginByVendors from './bank-at-origin-by-vendor'
import BankIntermediaryByVendor from './bank-intermediary-by-vendor'
import ForwarderAtDestination from './forwarder-at-destination'
import CustomsAtDestination from './customs-at-destination'
import CustomsAtOrigin from './customs-at-origin'
import CourierAgency from './courier-agency'
import BusinessServicesCentre from './business-services-centre'
import EarlyFundingEntity from './early-funding-entity'
import PoListPagination from '../../components/po-list-show'
import { getApiCallDynamic } from '../../service'
import { API } from '../config'

export class POList extends SetupDashBoardHandlerClass {
    constructor(props) {
        super(props)
        this.child = React.createRef()
        console.log('username', this.props)

        this.state = {
            selectedData: {},
            poList: [],
            poSequence: [],
        }
    }

    handleSelectedRow = (row, isSelect, rowIndex, e) => {
        console.log(
            'row====>',
            row,
            'isSelected=>>>>',
            isSelect,
            'rowIndex=>>>>>>>',
            rowIndex,
            'Event=>>>>',
            e,
        )
        console.log('Event=>>>>', e.target.id)
        if (e.target.id !== 'test' && e.target.id === 'poId') {
            let poIdInfo = {}
            let touchPointPoInfo = []
            let poSequence = []

            let tpsQueryParameter = { templateId: row.templateId, orgId: '1' }

            getApiCallDynamic(tpsQueryParameter, API.touchPointSequence).then(
                (res) => {
                    if (res?.status === 200) {
                        poSequence = res.data
                        console.log('api get', res.data)
                        this.setState({
                            poSequence: poSequence,
                        })
                    }
                },
            )
            // .catch((err) => err)

            let tpQueryParameter = {
                poId: row.poId,
                touchPointId: this.props.user.data.businessInfo.touchPointId,
            }
            getApiCallDynamic(tpQueryParameter, API.touchPointPOInfo).then(
                (res) => {
                    if (res?.status === 200) {
                        touchPointPoInfo = {
                            ...res.data,
                            ...poIdInfo,
                        }

                        console.log('touchPointPoInfo', touchPointPoInfo)
                        this.setState({
                            selectedData: touchPointPoInfo,
                            selectedRow: rowIndex,
                            show: true,
                        })
                        console.log('api get', this.state.selectedData)
                    }
                },
            )
            // .catch((err) => err)
        }
    }

    refresh = () => {
        getPOList(this.props.poStatus)
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        poList: res.data.dataList,
                    })
                    console.log(this.state.poList)
                }
            })
            .catch((err) => err)
    }

    componentDidMount() {
        window.scrollTo(500, 110)
    }

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true, // enable hide selection column.
            bgColor: 'rgba(0, 0, 0, 0.05)',
            clickToSelect: true,
            onSelect: this.handleSelectedRow,
        }
        const { roleList } = this.props.user.data

        let html
        let vendor = false
        if (
            roleList.some((i) => i.includes('tp::vendor')) ||
            roleList.some((i) => i.includes('tp::vn'))
        ) {
            vendor = true
        }

        html = () => {
            if (
                roleList.some((i) => i.includes('tp::vendor')) ||
                roleList.some((i) => i.includes('tp::vn'))
            ) {
                vendor = true

                return (
                    <Vendor
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
            if (
                roleList.some((i) => i.includes('tp::sourcing')) ||
                roleList.some((i) => i.includes('tp::sc'))
            ) {
                return (
                    <Sourcing
                        poUnintUpdate={true}
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
            if (
                roleList.some((i) => i.includes('tp::cfs')) ||
                roleList.some((i) => i.includes('tp::cfs'))
            ) {
                return (
                    <CFS
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }

            if (
                roleList.some((i) => i.includes('tp::forwarder_at_origin')) ||
                roleList.some((i) => i.includes('tp::fo'))
            ) {
                return (
                    <ForwarderAtOrigin
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
            if (
                roleList.some((i) =>
                    i.includes('tp::bank_at_origin_by_vendor'),
                ) ||
                roleList.some((i) => i.includes('tp::bov'))
            ) {
                return (
                    <BankAtOriginByVendors
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
            if (
                roleList.some((i) =>
                    i.includes('tp::bank_intermediary_by_vendor'),
                ) ||
                roleList.some((i) => i.includes('tp::biv'))
            ) {
                return (
                    <BankIntermediaryByVendor
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
            if (
                roleList.some((i) =>
                    i.includes('tp::forwarder_at_destination'),
                ) ||
                roleList.some((i) => i.includes('tp::fd'))
            ) {
                return (
                    <ForwarderAtDestination
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
            if (
                roleList.some((i) => i.includes('tp::customs_at_origin')) ||
                roleList.some((i) => i.includes('tp::cao'))
            ) {
                return (
                    <CustomsAtOrigin
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
            if (
                roleList.some((i) =>
                    i.includes('tp::customs_at_destination'),
                ) ||
                roleList.some((i) => i.includes('tp::cad'))
            ) {
                return (
                    <CustomsAtDestination
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }

            if (
                roleList.some((i) => i.includes('tp::courier_agency')) ||
                roleList.some((i) => i.includes('tp::ca'))
            ) {
                return (
                    <CourierAgency
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
            if (
                roleList.some((i) =>
                    i.includes('tp::business_services_centre'),
                ) ||
                roleList.some((i) => i.includes('tp::bsc'))
            ) {
                return (
                    <BusinessServicesCentre
                        poUnintUpdate={true}
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
            if (
                roleList.some((i) => i.includes('tp::early_funding_entity')) ||
                roleList.some((i) => i.includes('tp::efe'))
            ) {
                return (
                    <EarlyFundingEntity
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }

            if (
                roleList.some((i) => i.includes('tp::admin')) ||
                roleList.some((i) => i.includes('tp::adm'))
            ) {
                return (
                    <BusinessServicesCentre
                        status={true}
                        data={this.state.selectedData}
                        poSequence={this.state.poSequence}
                        tittle={'Information Update'}
                        props={this.props}
                        handleClose={this.handleClose}
                        refresh={this.child.retrievePoLists}
                        poPendingCount={this.props.poPendingCount}
                    />
                )
            }
        }

        // this.resetData();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-auto py-3 m-auto">
                        <h2>PO List</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-auto m-auto">
                        <div className="p-1">
                            {/* decide upon role */}

                            <ModalCommon
                                load={html()}
                                tittle={'Information Update'}
                                handleClose={this.handleClose}
                                show={this.state.show}
                                onHide={this.handleClose}
                                size={'xl'}
                            />

                            <PoListPagination
                                poPendingCount={this.props.poPendingCount}
                                ref={(instance) => {
                                    this.child = instance
                                }}
                                {...this.props}
                                vendor={vendor}
                                selectRow={selectRow}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
