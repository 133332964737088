import {
    companyStatusChange,
    DateTimeFormater,
    userStatusChange,
} from './event-handlers'
import ConfirmModal from './confirm-modal'
import React from 'react'
import { customFilter } from 'react-bootstrap-table2-filter'

export const PO_APPROVAL_STATUS_REPORT = {
    CONTENT_HEADER: [
        [
            'PO ID',
            'Company',
            'Department',
            'Approval Status',
            'Approval Date',
            'Current Touch Point',
            'Revised FOB Date',
            'Comments',
        ],
    ],
    FILE_NAME: 'po_approval_status.xlsx',
}
export const EXCEPTION_CHECK_REPORT = {
    CONTENT_HEADER: [
        [
            'PO ID',
            'Company',
            'Last Approval Date',
            'Previous Unit Price in System (XD)',
            'Latest Unit Price in System (XD)',
            'Revised FOB Date',
        ],
    ],
    FILE_NAME: 'exception_check_report.xlsx',
}
export const PRICE_MISMATCH_REPORT = {
    CONTENT_HEADER: [
        [
            'PO ID',
            'Company',
            'Department',
            'Stroke',
            'System Unit Price',
            'Invoice Unit Price',
        ],
    ],
    FILE_NAME: 'price_mismatch_report.xlsx',
}
export const PO_APPROVED_BY_PERIOD_REPORT = {
    CONTENT_HEADER: [
        [
            'PO ID',
            'Company',
            'Department',
            'Approval Date',
            'Revised FOB Date',
            'Current Touch Point',
        ],
    ],
    FILE_NAME: 'po_approval_status_by_period.xlsx',
    USD_CONTENT_HEADER: 'Total Value In USD',
}
export const PAYMENT_TYPE = ['LC', 'TT', 'DC']

export const REGISTRATION_TYPE = [
    { label: 'User', value: 'user' },
    { label: 'Comapny', value: 'company' },
]

export const EVENT_MODEL_CLOSE = {
    target: { id: 'show' },
}

export const PORT_OF_LANDING = [
    'Chittagong Port',
    'Dhaka Airport',
    'Mongla',
    'Paira',
]

export const COMPANY_TYPE = [
    'ALL',
    'ENABLED',
    'APPROVED',
    'PENDING',
    'REJECTED',
    'DISABLED',
]

export const STATUS_TYPE = [
    'ENABLED',
    'APPROVED',
    'PENDING',
    'REJECTED',
    'DISABLED',
]

export const PO_STATUS_TYPE = [
    'ALL',
    'PENDING',
    'REJECTED',
    'COMPLETED',
    'NOT COMPLETED',
]

export const PO_STATUS_TYPE_FOR_REPORT = [
    'ALL',
    'PENDING',
    'APPROVED',
    'REJECTED',
]

export const BUSINESS_CURRENCY = [
    { businessCurrency: 'BDT', id: 1, checked: false },
    { businessCurrency: 'GBP', id: 2, checked: false },
    { businessCurrency: 'USD', id: 3, checked: false },
]

export const COUNTRY_LIST = ['Bangladesh', 'India', 'United State']

export const COLUMNS_COMPANY_LIST = [
    {
        dataField: 'index',
        text: 'S/N',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            width: '50px',
        },
        formatter: (cell, row, rowIndex, colIndex) => {
            return <> {`${rowIndex + 1}`}</>
        },
    },
    {
        dataField: 'code',
        text: 'Company ID',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },

        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },
    {
        dataField: 'name',
        text: 'Company Name',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            minWidth: '135px',
        },

        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },
    {
        dataField: 'companyLocation',
        text: 'Company Location',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            minWidth: '135px',
        },

        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },
    {
        dataField: 'factoryLocation',
        text: 'Factory Location',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            minWidth: '135px',
        },

        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },
    {
        dataField: 'portOfOrigin',
        text: 'Port of Origin',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            minWidth: '135px',
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },
    {
        dataField: 'businessCurrency',
        text: 'Business Currency',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            minWidth: '140px',
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },
    {
        dataField: 'creditTermInDays',
        text: 'Credit Term In Days',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },
    },
    {
        dataField: 'createdTime',
        text: 'Created Time',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },
        filterValue: (cell, row) => DateTimeFormater(row.createdTime),

        formatter: (cellContent, row) => {
            return <>{`${DateTimeFormater(row.createdTime)}  `}</>
        },
    },
    {
        dataField: 'id',
        text: 'Action',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },
        formatter: (cellContent, row) => {
            return (
                <>
                    <i className="fa fa-eye px-1" id="view"></i>
                    <i className="fa fa-edit px-1" id="edit"></i>
                </>
            )
        },
    },
]

export const COLUMNS_ROLE_LIST = [
    {
        dataField: 'index',
        text: 'S/N',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },
        formatter: (cell, row, rowIndex, colIndex) => {
            return <> {`${rowIndex + 1}`}</>
        },
    },

    {
        dataField: 'role',
        text: 'Role Name',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },
    },
    {
        dataField: 'roleDescription',
        text: 'Role Description',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },
    },
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },
    },
    {
        dataField: 'createdTime',
        text: 'Created Time',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },

        filterValue: (cell, row) => DateTimeFormater(row.createdTime),

        formatter: (cellContent, row) => {
            return <>{`${DateTimeFormater(row.createdTime)}  `}</>
        },
    },
]

export class PriceFilter extends React.Component {
    constructor(props) {
        super(props)
        this.filter = this.filter.bind(this)
        this.getValue = this.getValue.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.input = {
            value: '',
        }
    }
    getValue() {
        return this.input.value
    }
    filter() {
        this.props.method.refresh()
    }
    onCancel(e) {
        this.filter()
    }
    onChange = (e) => {
        if (e.key === 'Enter') {
            this.filter()
        }
    }

    render() {
        const { method, id } = this.props
        const { handleChange, state, onClear } = method
        return [
            <>
                <i
                    className={
                        (state[this.props.id] !== '') &
                        (state[this.props.id] !== undefined)
                            ? ' search-icon pl-2 fas fa-search text-danger'
                            : 'search-icon pl-2  fas fa-search'
                    }
                    style={{ color: '#72848c' }}
                >
                    <div className="mb-0 form-group">
                        <input
                            id={id}
                            className="search-input mb-2 mt-2"
                            key="input"
                            value={state[id]}
                            autoFocus={true}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            placeholder="Filter"
                            onKeyDown={this.onChange}
                        />
                        <button
                            className=" btn mr-2  btn-primary btn-sm search-icon-button"
                            style={{ height: '25px', fontSize: '12px' }}
                            onClick={this.filter}
                        >
                            Search
                        </button>
                        <button
                            id={id}
                            className=" btn ml-2 btn-secondary btn-sm"
                            style={{ height: '25px', fontSize: '12px' }}
                            onClick={onClear}
                        >
                            Clear
                        </button>
                    </div>
                </i>
            </>,
        ]
    }
}

export function getUserList(offset = 0, method) {
    return [
        {
            dataField: 'index',
            text: 'S/N',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            formatter: (cell, row, rowIndex, colIndex) => {
                return <> {`${offset + rowIndex + 1}`}</>
            },
        },
        {
            dataField: 'username',
            text: 'User Name',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="username"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },

        {
            dataField: 'email',
            text: 'Email',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="email"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },
        {
            dataField: 'companyName',
            text: 'Company',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="companyName"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },
        {
            dataField: 'country',
            text: 'Country',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="country"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },

        {
            dataField: 'id',
            text: 'Action',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            formatter: (cellContent, row) => {
                return (
                    <>
                        <i className="fa fa-eye px-1" id="view"></i>
                        <i className="fa fa-edit px-1" id="edit"></i>
                    </>
                )
            },
        },
    ]
}
export function userPendingListColumn(offset = 0, method) {
    return [
        {
            dataField: 'index',
            text: 'S/N',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
                width: '20px',
            },
            formatter: (cell, row, rowIndex, colIndex) => {
                return <> {`${offset + rowIndex + 1}`}</>
            },
        },

        {
            dataField: 'username',
            text: 'User Name',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="username"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },
        {
            dataField: 'email',
            text: 'Email',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="email"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },
        {
            dataField: 'companyName',
            text: 'Company',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="companyName"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },
        {
            dataField: 'country',
            text: 'Country',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="country"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },

        {
            dataField: 'id',
            text: 'Action',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            formatter: (cellContent, row) => {
                return (
                    <>
                        <i className="fa fa-edit px-1" id="edit"></i>
                        <ConfirmModal
                            title="Confirmation"
                            selectionType="icon"
                            className="fa fa-check px-1"
                            buttonName="Approve"
                            id="approved"
                            message="Are you sure, you want to approve the user?"
                            handler={(e) =>
                                userStatusChange(
                                    { userId: cellContent, status: 'APPROVE' },
                                    method.refresh,
                                )
                            }
                        />
                        <ConfirmModal
                            title="Confirmation"
                            selectionType="icon"
                            className="fa fa-times px-1"
                            buttonName="Reject"
                            id="reject"
                            message="Are you sure, you want to reject the user?"
                            handler={(e) =>
                                userStatusChange(
                                    { userId: cellContent, status: 'REJECT' },
                                    method.refresh,
                                )
                            }
                        />
                    </>
                )
            },
        },
    ]
}

export function getCompanyList(offset = 0, method) {
    return [
        {
            dataField: 'index',
            text: 'S/N',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
                width: '50px',
            },
            formatter: (cell, row, rowIndex, colIndex) => {
                return <> {`${offset + rowIndex + 1}`}</>
            },
        },

        {
            dataField: 'code',
            text: 'Company ID',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="companyCode"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },
        {
            dataField: 'name',
            text: 'Company Name',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
                minWidth: '135px',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="companyName"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },

        {
            dataField: 'businessCurrency',
            text: 'Business Currency',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
                minWidth: '140px',
            },
            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="businessCurrency"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },

        {
            dataField: 'createdTime',
            text: 'Created On',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            filterValue: (cell, row) => DateTimeFormater(row.createdTime),

            formatter: (cellContent, row) => {
                return <>{`${DateTimeFormater(row.createdTime)}  `}</>
            },
        },
        {
            dataField: 'id',
            text: 'Action',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            formatter: (cellContent, row) => {
                return (
                    <>
                        <i className="fa fa-eye px-1" id="view"></i>
                        <i className="fa fa-edit px-1" id="edit"></i>
                    </>
                )
            },
        },
    ]
}

export function getCompanyPendingList(offset = 0, method) {
    return [
        {
            dataField: 'index',
            text: 'S/N',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            formatter: (cell, row, rowIndex, colIndex) => {
                return <> {`${offset + rowIndex + 1}`}</>
            },
        },

        {
            dataField: 'code',
            text: 'Company ID',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="companyCode"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },
        {
            dataField: 'name',
            text: 'Company Name',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
                minWidth: '135px',
            },
            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="companyName"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },

        {
            dataField: 'businessCurrency',
            text: 'Business Currency',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
                minWidth: '140px',
            },
            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="businessCurrency"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },

        {
            dataField: 'createdTime',
            text: 'Created On',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            filterValue: (cell, row) => DateTimeFormater(row.createdTime),

            formatter: (cellContent, row) => {
                return <>{`${DateTimeFormater(row.createdTime)}  `}</>
            },
        },
        {
            dataField: 'id',
            text: 'Action',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            formatter: (cellContent, row) => {
                return (
                    <>
                        <i className="fa fa-edit px-1" id="edit"></i>
                        <ConfirmModal
                            title="Confirmation"
                            selectionType="icon"
                            className="fa fa-check px-1"
                            buttonName="Approve"
                            id="approved"
                            message="Are you sure, you want to approve the company?"
                            handler={(e) =>
                                companyStatusChange(
                                    { cmpId: cellContent, status: 'APPROVE' },
                                    method.refresh,
                                )
                            }
                            refresh={method.refresh}
                        />
                        <ConfirmModal
                            title="Confirmation"
                            selectionType="icon"
                            className="fa fa-times px-1"
                            buttonName="Reject"
                            id="reject"
                            message="Are you sure, you want to reject the company?"
                            handler={(e) =>
                                companyStatusChange(
                                    { cmpId: cellContent, status: 'REJECT' },
                                    method.refresh,
                                )
                            }
                            refresh={method.refresh}
                        />
                    </>
                )
            },
        },
    ]
}

export function getPoList(offset = 0, method) {
    return [
        {
            dataField: 'index',
            text: 'S/N',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            formatter: (cell, row, rowIndex, colIndex) => {
                return <> {`${offset + rowIndex + 1}`}</>
            },
        },
        {
            dataField: 'poId',
            text: 'P/O ID',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },
            formatter: (cell, row, rowIndex, colIndex) => {
                return (
                    <label
                        style={{ color: 'green', cursor: 'pointer' }}
                        id="poId"
                        title="View"
                    >
                        {' '}
                        {cell}
                    </label>
                )
            },
            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="poId"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },
        {
            dataField: 'vendorName',
            text: 'Company',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
                <PriceFilter
                    id="companyName"
                    method={method}
                    onFilter={onFilter}
                    column={column}
                />
            ),
        },

        {
            dataField: 'activeTouchPointName',
            text: 'Touch Point',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filterValue: (cellContent, row) =>
                cellContent === null || cellContent === undefined
                    ? 'Not active'
                    : cellContent,
            csvFormatter: (cellContent, row) =>
                cellContent === null || cellContent === undefined
                    ? 'Not active'
                    : cellContent,

            formatter: (cellContent, row) =>
                cellContent === null || cellContent === undefined
                    ? 'Not active'
                    : cellContent,

            // filter: customFilter(),
            // filterRenderer: (onFilter, column) =>
            //  <PriceFilter id='companyName' method={method} onFilter={onFilter} column={column} />
        },
        {
            dataField: 'revisedFobDate',
            text: 'Revised FOB Date',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            filterValue: (cell, row) =>
                cell === null || cell == undefined
                    ? ''
                    : DateTimeFormater(cell),

            formatter: (cell, row) => {
                return (
                    <>
                        {cell === null || cell == undefined
                            ? ''
                            : `${DateTimeFormater(cell)}  `}
                    </>
                )
            },
        },
        {
            dataField: 'rejectedBy',
            text: 'Rejected By',
            headerStyle: {
                boxShadow:
                    '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
            },

            // filter: customFilter(),
            // filterRenderer: (onFilter, column) =>
            //     <PriceFilter id='rejectedBy' method={method} onFilter={onFilter} column={column} />
        },
    ]
}

export const POLIST = [
    {
        dataField: 'index',
        text: 'S/N',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },
        formatter: (cell, row, rowIndex, colIndex) => {
            return <> {`${rowIndex}`}</>
        },
    },

    {
        dataField: 'poId',
        text: 'P/O ID',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },

        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },
    {
        dataField: 'vendorName',
        text: 'Company',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },

        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },

    {
        dataField: 'activeTouchPointName',
        text: 'Touch Point',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },

        filterValue: (cellContent, row) =>
            cellContent === null || cellContent === undefined
                ? 'Not active'
                : cellContent,
        csvFormatter: (cellContent, row) =>
            cellContent === null || cellContent === undefined
                ? 'Not active'
                : cellContent,

        formatter: (cellContent, row) =>
            cellContent === null || cellContent === undefined
                ? 'Not active'
                : cellContent,

        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },

    {
        dataField: 'revisedFobDate',
        text: 'Revised FOB Date',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },

        filterValue: (cell, row) =>
            cell === null || cell == undefined
                ? ''
                : DateTimeFormater(row.createdTime),

        formatter: (cell, row) => {
            return (
                <>
                    {cell === null || cell == undefined
                        ? ''
                        : `${DateTimeFormater(row.createdTime)}  `}
                </>
            )
        },
    },

    {
        dataField: 'rejectedBy',
        text: 'Rejected By',
        headerStyle: {
            boxShadow:
                '0 1px 1px 0 rgb(95 47 47 / 20%), 0 6px 20px 0 rgba(0, 0, 0, 0)',
        },

        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
            <PriceFilter onFilter={onFilter} column={column} />
        ),
    },
]

export const TOUCH_POINT = [
    { id: 1, name: 'Vendors', touchPoint: 'Vendors', checked: false },
    { id: 2, name: 'Sourcing', touchPoint: 'Sourcing', checked: false },
    { id: 3, name: 'CFS', touchPoint: 'CFD', checked: false },

    {
        id: 4,
        name: 'Forwarder at origin',
        touchPoint: 'Forwarder at origin',
        checked: false,
    },
    {
        id: 5,
        name: 'Bank at origin by vendor',
        touchPoint: 'Bank at origin by vendor',
        checked: false,
    },
    {
        id: 6,
        name: 'Bank intermediary by vendor',
        touchPoint: 'Bank intermediary by vendor',
        checked: false,
    },
    {
        id: 7,
        name: 'Forwarder at destination',
        touchPoint: 'Forwarder at destination',
        checked: false,
    },
    {
        id: 8,
        name: 'Customs at origin',
        touchPoint: 'Customs at origin',
        checked: false,
    },
    {
        id: 9,
        name: 'Customs at destination',
        touchPoint: 'Customs at destination',
        checked: false,
    },
    {
        id: 10,
        name: 'Courier Agency',
        touchPoint: 'Courier Agency',
        checked: false,
    },
    {
        id: 11,
        name: 'Business Services Centre',
        touchPoint: 'Business Services Centre',
        checked: false,
    },
    {
        id: 12,
        name: 'Early Funding Entity',
        touchPoint: 'Early Funding Entity',
        checked: false,
    },
]
export const TOUCH_POINT1 = [
    'Vendors',
    'Sourcing',
    'CFS',
    'Forwarder at origin',
    'Bank at origin by vendor',
]
export const TOUCH_POINT2 = [
    'Vendor',
    'Sourcing',
    'CFS',
    'Business Services Centre',
]

export const DUMMY_USER = [
    {
        id: 13,
        name: 'SuperAdmin',
        username: 'SuperAdmin',
        password: 'SuperAdmin',
    },
    { id: 14, name: 'Admin', username: 'Admin', password: 'Admin' },

    { id: 1, name: 'Vendors', username: 'Vendors', password: 'Vendors' },
    { id: 2, name: 'Sourcing', username: 'Sourcing', password: 'Sourcing' },
    { id: 3, name: 'CFS', username: 'CFS', password: 'CFS' },
    {
        id: 4,
        name: 'Forwarder at origin',
        username: 'Forwarder at origin',
        password: 'Forwarder at origin',
    },
    {
        id: 5,
        name: 'Bank at origin by vendor',
        username: 'Bank at origin by vendor',
        password: 'Bank at origin by vendor',
    },
    {
        id: 6,
        name: 'Bank intermediary by vendor',
        username: 'BankVendors',
        password: 'BankVendors',
    },
    {
        id: 7,
        name: 'Forwarder at destination',
        username: 'FAT',
        password: 'FAT',
    },
    { id: 8, name: 'Customs at origin', username: 'CAO', password: 'CAO' },
    { id: 9, name: 'Customs at destination', username: 'CAD', password: 'CAD' },
    { id: 10, name: 'Courier Agency', username: 'CA', password: 'CA' },
    {
        id: 11,
        name: 'Business Services Centre',
        username: 'BSC',
        password: 'BSC',
    },
    { id: 12, name: 'Early Funding Entity', username: 'EFE', password: 'EFE' },
]
