import React from 'react'
import { toastNotify } from '../../utils/Alert-msg'
import {
    creatPO,
    getForm,
    objectHasKeyValueParser,
    PoHandlerClass,
} from '../../utils/event-handlers'
import { Formik, Form } from 'formik'
import PoTrafic from '../../components/po-trafic'
import {
    BankAtOriginByVendorHtml,
    BankIntermediaryByVendorHtml,
    CFSHtml,
    CustomsAtDestinationHtml,
    CustomsAtOriginHtml,
    ForwardAtOriginHtml,
    ForwarderAtDestinationHtml,
    SourcingHtml,
    VendorHtml,
} from '../../pages/vendor-html'
import CardView from '../../components/card-view'

export default class CustomsAtDestination extends PoHandlerClass {
    constructor(props) {
        super(props)
        this.user = this.props.props.user.data

        this.state = {
            uploadPercentage: 0,
            id: 9,

            poId: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data,
                      key: 'poId',
                      type: 'text',
                  })
                : '',
            field1: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '1',
                      type: 'text',
                  })
                : '',
            field2: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '2',
                      type: 'text',
                  })
                : '',
            field3: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '3',
                      type: 'text',
                  })
                : '',
            field4: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '4',
                      type: 'file',
                  })
                : [],
            field5: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '5',
                      type: 'text',
                  })
                : '',
            field6: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '6',
                      type: 'file',
                  })
                : [],
            field7: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '7',
                      type: 'file',
                  })
                : [],
            field8: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '8',
                      type: 'text',
                  })
                : '',
            field9: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '9',
                      type: 'text',
                  })
                : '',
            field10: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '10',
                      type: 'text',
                  })
                : '',
            field11: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '11',
                      type: 'text',
                  })
                : '',
            field12: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '12',
                      type: 'text',
                  })
                : '',
            field13: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '13',
                      type: 'text',
                  })
                : '',
            field14: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '14',
                      type: 'text',
                  })
                : '',
            field15: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '15',
                      type: 'text',
                  })
                : '',
            field16: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '16',
                      type: 'text',
                  })
                : '',

            field17: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '17',
                      type: 'text',
                  })
                : '',
            field18: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '18',
                      type: 'text',
                  })
                : '',
            field19: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '19',
                      type: 'text',
                  })
                : '',
            field20: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '20',
                      type: 'text',
                  })
                : '',
            field21: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '21',
                      type: 'text',
                  })
                : '',
            field22: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '22',
                      type: 'text',
                  })
                : '',
            field23: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '23',
                      type: 'text',
                  })
                : '',

            field24: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '24',
                      type: 'text',
                  })
                : '',

            field25: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '25',
                      type: 'text',
                  })
                : '',

            field26: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '26',
                      type: 'text',
                  })
                : '',
            field27: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '27',
                      type: 'text',
                  })
                : '',
            field28: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '28',
                      type: 'text',
                  })
                : '',

            field29: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '29',
                      type: 'text',
                  })
                : '',
            field30: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '30',
                      type: 'text',
                  })
                : '',
            field31: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '31',
                      type: 'text',
                  })
                : '',

            // new

            field32: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '32',
                      type: 'text',
                  })
                : '',
            field33: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '33',
                      type: 'text',
                  })
                : '',

            form: null,
        }
    }

    handleSubmit = () => {
        if (
            this.user.touchPointId !== this.props.data.expectedTouchPointState
        ) {
            toastNotify('warn', 'Submission is restricted')
        } else {
            let data = {
                id: this.props.data.id,
                poId: this.state.poId,
                fieldData: {},
            }

            creatPO(data)
                .then((res) => {
                    if (res !== undefined) {
                        toastNotify('success', 'po successfully update')
                        this.props.handleClose({ target: { id: 'show' } })
                        this.props.refresh()
                    }
                })
                .catch((err) => err)
        }
    }

    componentDidMount() {
        getForm('9')
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        form: res.data,
                    })
                    console.log(this.state.form)
                }
            })
            .catch((err) => err)
    }

    render() {
        const editable =
            this.user.touchPointId === this.props.data.expectedTouchPointState

        return (
            <div className="container-fluid">
                {this.props.status ? (
                    <PoTrafic
                        expectedTouchPointState={
                            this.props.data.expectedTouchPointState
                        }
                        poSequence={this.props.poSequence}
                        activeTouchPointState={
                            this.props.data.activeTouchPointState
                        }
                        repairFlag={this.props.data.repairFlag}
                    />
                ) : null}
                <div className="row justify-content-center py-5">
                    <div className="col-lg-12">
                        <div className="p-5">
                            <Formik
                                initialValues={this.state}
                                onSubmit={(values, { setSubmitting }) => {
                                    console.log('va', values)
                                    this.handleSubmit()
                                    setSubmitting(false)
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <>
                                        <Form>
                                            <div
                                                className="accordion"
                                                id="accordionExample"
                                            >
                                                <CardView
                                                    this={this}
                                                    buttonName="Vendor"
                                                    value="vendor"
                                                    code="vn"
                                                    html={
                                                        <VendorHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Sourcing"
                                                    value="sourcing"
                                                    code="sc"
                                                    html={
                                                        <SourcingHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="CFS"
                                                    value="cfs"
                                                    code="cfs"
                                                    html={
                                                        <CFSHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Forward At Origin"
                                                    value="forwardAtOrigin"
                                                    code="fao"
                                                    html={
                                                        <ForwardAtOriginHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Bank At Origin By Vendor"
                                                    value="bankAtOriginByVendor"
                                                    code="bov"
                                                    html={
                                                        <BankAtOriginByVendorHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Bank Intermediary By Vendor"
                                                    value="bankIntermediaryByVendor"
                                                    code="biv"
                                                    html={
                                                        <BankIntermediaryByVendorHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>
                                                <CardView
                                                    this={this}
                                                    buttonName="Forward At Destination"
                                                    value="forwardAtDestination"
                                                    code="fd"
                                                    html={
                                                        <ForwarderAtDestinationHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>
                                                <CardView
                                                    this={this}
                                                    buttonName="Custome At Origin"
                                                    value="customsAtOrigin"
                                                    code="cao"
                                                    html={
                                                        <CustomsAtOriginHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>
                                                <CardView
                                                    this={this}
                                                    buttonName="Custome At Destination"
                                                    value="customsAtDestination"
                                                    code="cad"
                                                    html={
                                                        <CustomsAtDestinationHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            this={this}
                                                            disabled={!editable}
                                                        />
                                                    }
                                                ></CardView>
                                            </div>

                                            <div className="justify-content-center row pt-5">
                                                <button
                                                    href="#"
                                                    type="submit"
                                                    className={
                                                        editable
                                                            ? 'btn btn-outline-success mr-3'
                                                            : 'd-none'
                                                    }
                                                    disabled={!editable}
                                                >
                                                    Submit
                                                </button>

                                                <button
                                                    id="show"
                                                    type="button"
                                                    className={
                                                        this.props.status
                                                            ? 'btn btn-outline-danger mr-5'
                                                            : 'd-none'
                                                    }
                                                    onClick={
                                                        this.props.handleClose
                                                    }
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
