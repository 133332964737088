import React from 'react'
import { toastNotify } from '../../utils/Alert-msg'
import {
    addIsCheckedField,
    creatPO,
    objectHasKeyValueParser,
    PoHandlerClass,
} from '../../utils/event-handlers'
import { Formik, Form, ErrorMessage } from 'formik'
import Dropdown from 'react-dropdown'
import {
    CourierAgencyHtml,
    BankAtOriginByVendorHtml,
    BankIntermediaryByVendorHtml,
    CFSHtml,
    CustomsAtDestinationHtml,
    CustomsAtOriginHtml,
    ForwardAtOriginHtml,
    ForwarderAtDestinationHtml,
    SourcingHtml,
    VendorHtml,
    BusinessServicesCentreHtml,
} from '../../pages/vendor-html'
import CardView from '../../components/card-view'
import PoTrafic from '../../components/po-trafic'
import { getApiCallDynamic, postApiCallDynamic } from '../../service'
import { API } from '../config'

export default class BusinessServicesCentre extends PoHandlerClass {
    constructor(props) {
        super(props)
        this.user = this.props.props.user.data

        this.state = {
            uploadPercentage: 0,
            id: 11,
            tpId: '',
            touchPointList: [],
            poId: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data,
                      key: 'poId',
                      type: 'text',
                  })
                : '',
            field1: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '1',
                      type: 'text',
                  })
                : '',
            field2: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '2',
                      type: 'text',
                  })
                : '',
            field3: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '3',
                      type: 'text',
                  })
                : '',
            field4: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '4',
                      type: 'file',
                  })
                : [],
            field5: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '5',
                      type: 'text',
                  })
                : '',
            field6: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '6',
                      type: 'file',
                  })
                : [],
            field7: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '7',
                      type: 'file',
                  })
                : [],
            field8: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '8',
                      type: 'text',
                  })
                : '',
            field9: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '9',
                      type: 'text',
                  })
                : '',
            field10: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '10',
                      type: 'text',
                  })
                : '',
            field11: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '11',
                      type: 'text',
                  })
                : '',
            field12: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '12',
                      type: 'text',
                  })
                : '',
            field13: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '13',
                      type: 'text',
                  })
                : '',
            field14: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '14',
                      type: 'text',
                  })
                : '',
            field15: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '15',
                      type: 'text',
                  })
                : '',
            field16: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '16',
                      type: 'text',
                  })
                : '',

            field17: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '17',
                      type: 'text',
                  })
                : '',
            field18: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '18',
                      type: 'text',
                  })
                : '',
            field19: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '19',
                      type: 'text',
                  })
                : '',
            field20: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '20',
                      type: 'text',
                  })
                : '',
            field21: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '21',
                      type: 'text',
                  })
                : '',
            field22: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '22',
                      type: 'text',
                  })
                : '',
            field23: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '23',
                      type: 'text',
                  })
                : '',

            field24: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '24',
                      type: 'text',
                  })
                : '',

            field25: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '25',
                      type: 'text',
                  })
                : '',

            field26: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '26',
                      type: 'text',
                  })
                : '',
            field27: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '27',
                      type: 'text',
                  })
                : '',
            field28: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '28',
                      type: 'text',
                  })
                : '',

            field29: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '29',
                      type: 'text',
                  })
                : '',
            field30: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '30',
                      type: 'text',
                  })
                : '',
            field31: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '31',
                      type: 'text',
                  })
                : '',

            field32: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '32',
                      type: 'text',
                  })
                : '',
            field33: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '33',
                      type: 'text',
                  })
                : '',

            // new

            field34: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '34',
                      type: 'text',
                  })
                : '',
            field35: this.props.status
                ? objectHasKeyValueParser({
                      obj: this.props.data.fieldData,
                      key: '35',
                      type: 'text',
                  })
                : '',

            form: null,
        }
    }

    handleSubmit = () => {
        if (
            this.user.businessInfo.touchPointId !==
            this.props.data.expectedTouchPointState
        ) {
            toastNotify('warn', 'Submission is restricted')
        } else {
            let data = {
                id: this.props.data.id,

                poId: this.state.poId,
                fieldData: {
                    34: this.state.field34,
                    35: this.state.field35,
                },
            }

            creatPO(data)
                .then((res) => {
                    if (res !== undefined) {
                        toastNotify('success', 'po successfully update')
                        this.props.handleClose({ target: { id: 'show' } })
                        this.props.refresh()
                    }
                })
                .catch((err) => err)
        }
    }

    componentDidMount() {
        let queryParameter = {
            id: 11,
        }
        getApiCallDynamic(queryParameter, API.formData)
            .then((res) => {
                if (res?.status === 200) {
                    this.setState({
                        form: res.data,
                    })
                    console.log(this.state.form)
                }
            })
            .catch((err) => err)

        let tpQueryParameter = {
            templateId: 2,
            orgId: 1,
        }
        getApiCallDynamic(tpQueryParameter, API.touchPointSequence)
            .then((res) => {
                if (res !== undefined && res.status === 200 && res.data) {
                    this.setState({
                        touchPointList: res.data.filter((i) => i.code != 'bsc'),
                    })
                    console.log(this.state.touchPointList)
                }
            })
            .catch((err) => err)
    }

    handleDropDownChange = (val, name) => (event) => {
        console.log('drop', event, 'val', val, 'name', name)
        this.setState({
            [val]: event.value,
            [name]: event.label,
        })
    }

    handleReject = () => {
        if (this.state.tpId === '') {
            toastNotify('warn', 'Please select a Touch point')
        } else {
            let data = {
                rejectState: this.state.tpId,
                id: this.props.data.id,
            }
            postApiCallDynamic(data, API.rejectPO, 'UPDATE')
                .then((res) => {
                    if (res !== undefined && res.status === 200) {
                        toastNotify('success', res.data.message)
                        this.props.handleClose({ target: { id: 'show' } })
                        this.props.refresh()
                    } else {
                        this.props.handleClose({ target: { id: 'show' } })
                        this.props.refresh()
                    }
                })
                .catch((err) => err)
        }
    }

    handleValidation = () => {
        let errors = {}
        if (this.state.field34 === '') {
            errors.field34 = 'Please enter a value'
        }

        if (this.state.field35 === '') {
            errors.field35 = 'Please enter a value'
        }

        return errors
    }
    render() {
        const editable =
            this.user.businessInfo.touchPointId ===
            this.props.data.expectedTouchPointState

        return (
            <div className="container-fluid">
                {this.props.status ? (
                    <PoTrafic
                        expectedTouchPointState={
                            this.props.data.expectedTouchPointState
                        }
                        poSequence={this.props.poSequence}
                        activeTouchPointState={
                            this.props.data.activeTouchPointState
                        }
                        repairFlag={this.props.data.repairFlag}
                    />
                ) : null}
                <div className="row justify-content-center py-5">
                    <div className="col-lg-12">
                        <div className="p-5">
                            <Formik
                                initialValues={this.state}
                                validate={(values) =>
                                    this.handleValidation(this, values)
                                }
                                onSubmit={(values, { setSubmitting }) => {
                                    console.log('va', values)
                                    this.handleSubmit()
                                    setSubmitting(false)
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <>
                                        <Form>
                                            <div
                                                className="accordion"
                                                id="accordionExample"
                                            >
                                                <CardView
                                                    this={this}
                                                    buttonName="Vendor"
                                                    value="vendor"
                                                    code="vn"
                                                    html={
                                                        <VendorHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Sourcing"
                                                    value="sourcing"
                                                    code="sc"
                                                    html={
                                                        <SourcingHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="CFS"
                                                    value="cfs"
                                                    code="cfs"
                                                    html={
                                                        <CFSHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Forward At Origin"
                                                    value="forwardAtOrigin"
                                                    code="fao"
                                                    html={
                                                        <ForwardAtOriginHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Bank At Origin By Vendor"
                                                    value="bankAtOriginByVendor"
                                                    code="bov"
                                                    html={
                                                        <BankAtOriginByVendorHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Bank Intermediary By Vendor"
                                                    value="bankIntermediaryByVendor"
                                                    code="biv"
                                                    html={
                                                        <BankIntermediaryByVendorHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>
                                                <CardView
                                                    this={this}
                                                    buttonName="Forward At Destination"
                                                    value="forwardAtDestination"
                                                    code="fd"
                                                    html={
                                                        <ForwarderAtDestinationHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>
                                                <CardView
                                                    this={this}
                                                    buttonName="Custome At Origin"
                                                    value="customsAtOrigin"
                                                    code="cao"
                                                    html={
                                                        <CustomsAtOriginHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>
                                                <CardView
                                                    this={this}
                                                    buttonName="Custome At Destination"
                                                    value="customsAtDestination"
                                                    code="cad"
                                                    html={
                                                        <CustomsAtDestinationHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Courier Agency"
                                                    value="courierAgency"
                                                    code="ca"
                                                    html={
                                                        <CourierAgencyHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                            this={this}
                                                        />
                                                    }
                                                ></CardView>

                                                <CardView
                                                    this={this}
                                                    buttonName="Business Services Centre"
                                                    value="businessServicesCentre"
                                                    code="bsc"
                                                    html={
                                                        <BusinessServicesCentreHtml
                                                            errors={errors}
                                                            touched={touched}
                                                            this={this}
                                                            disabled={!editable}
                                                        />
                                                    }
                                                ></CardView>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-6 mb-3 mt-2 ml-1 mb-sm-0">
                                                    <label>
                                                        Reject To
                                                        <sup className="text-danger"></sup>
                                                    </label>
                                                    <Dropdown
                                                        controlClassName={`form-control `}
                                                        className={` Dropdown-root-width ${
                                                            errors.tpId
                                                                ? 'border border-danger is-invalid'
                                                                : ''
                                                        } `}
                                                        value={
                                                            this.state.tpName
                                                        }
                                                        id="tpId"
                                                        options={this.state.touchPointList.map(
                                                            (i) => {
                                                                if (
                                                                    i.code !==
                                                                    'bsc'
                                                                ) {
                                                                    return {
                                                                        value: i.id,
                                                                        label: i.name,
                                                                    }
                                                                }
                                                            },
                                                        )}
                                                        onChange={this.handleDropDownChange(
                                                            'tpId',
                                                            'tpName',
                                                        )}
                                                        placeholder="Select an option"
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="tpId"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>

                                            <div className="justify-content-center row pt-5">
                                                <button
                                                    href="#"
                                                    type="submit"
                                                    className={
                                                        editable
                                                            ? 'btn btn-outline-success mr-3'
                                                            : 'd-none'
                                                    }
                                                    disabled={!editable}
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    id="show"
                                                    href="#"
                                                    type="button"
                                                    className={
                                                        editable
                                                            ? 'btn btn-outline-danger mr-3'
                                                            : 'd-none'
                                                    }
                                                    onClick={this.handleReject}
                                                    disabled={!editable}
                                                >
                                                    Reject
                                                </button>

                                                <button
                                                    id="show"
                                                    type="button"
                                                    className={
                                                        this.props.status
                                                            ? 'btn btn-outline-danger mr-5'
                                                            : 'd-none'
                                                    }
                                                    onClick={
                                                        this.props.handleClose
                                                    }
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
