import * as XLSX from 'xlsx'

export const downloadExcelReport = (
    data,
    contentHeader,
    fileName,
    totalValueInUSD,
    usdContentHeader,
) => {
    let length = Object.keys(data[0]).length
    let totalRowCount = data.length
    console.log('row', totalRowCount)

    let worksheet = XLSX.utils.json_to_sheet(data)
    worksheet['!autofilter'] = { ref: findFilterRange(length) }
    console.log('final', findFilterRange(length))
    let workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, contentHeader, { origin: 'A1' })
    // Po Count
    XLSX.utils.sheet_add_aoa(worksheet, [['Total Po Count']], {
        origin: totalCountCellNumber(length) + '2',
    })
    XLSX.utils.sheet_add_aoa(worksheet, [[totalRowCount]], {
        origin: totalCountCellNumber(length + 1) + '2',
    })
    // usd count
    XLSX.utils.sheet_add_aoa(worksheet, [[usdContentHeader]], {
        origin: totalCountCellNumber(length) + '3',
    })
    XLSX.utils.sheet_add_aoa(worksheet, [[totalValueInUSD]], {
        origin: totalCountCellNumber(length + 1) + '3',
    })

    XLSX.writeFile(workbook, `${Date.now()}_${fileName}`)
}

const totalCountCellNumber = (length) => {
    let cellNumber = String.fromCharCode(64 + length + 2)
    console.log('ll', cellNumber)
    return cellNumber
}

const findFilterRange = (length) => {
    let firstValue = 'A1:'
    let lastValue = ''
    let finalValue = ''
    let temp = parseInt(length / 26)
    if (temp > 0) {
        lastValue = lastValue.concat(String.fromCharCode(64 + temp))
    }
    lastValue = lastValue.concat(
        String.fromCharCode(64 + (length - temp * 26)) + '1',
    )
    finalValue = firstValue.concat(lastValue)
    return finalValue
}
