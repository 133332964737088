import axios from 'axios'
import { API_AUTH, configureAxios } from './config'
import jwt_decode from 'jwt-decode'
import { AlertError } from '../utils/event-handlers'
import { toastNotify } from '../utils/Alert-msg'

class Authentication {
    constructor() {
        this.result = {
            data: null,
            accessToken: null,
            refreshToken: null,
            error: null,
            isPasswordExpired: null,
        }

        this.session = 'scem-session'
        this.authCallback = null
    }

    onSetResult(data = null, error = null) {
        if (data && data.accessToken) {
            this.result.data = jwt_decode(data.accessToken)
            console.log('data From accessToken', this.result.data)
            this.result.accessToken = data.accessToken
            this.result.isPasswordExpired = 0.1
            this.result.error = null

            localStorage.setItem(this.session, JSON.stringify(this.result))
            localStorage.setItem('time', new Date())
            localStorage.setItem('user-session', 'login')

            configureAxios({
                authToken: data.accessToken,
                authCallback: this.authCallback,
            })

            return [true, this.result]
        }

        localStorage.removeItem(this.session)

        this.result.data = null
        this.result.accessToken = null

        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorMessage
        ) {
            this.result.error = {
                errorMessage: error.response.data.errorMessage,
                errorCode: error.response.data.errorCode,
            }
        }

        return [false, this.result]
    }

    setAuthCallback(authCallback) {
        this.authCallback = authCallback
    }

    currentSession() {
        return JSON.parse(localStorage.getItem(this.session))
    }

    async signin(username, password) {
        return axios
            .post(API_AUTH.login, {
                username: username,
                password: Buffer.from(password).toString('base64'),
            })
            .then((response) => this.onSetResult(response.data))
            .catch((error) => this.onSetResult('', error))
    }

    async signout() {
        localStorage.removeItem(this.session)
        return axios
            .post(API_AUTH.logout)
            .then((res) => {
                if (res.status === 200) {
                    localStorage.removeItem(this.session)
                    toastNotify('success', res.data.message)
                }
                localStorage.removeItem(this.session)
            })
            .catch((err) => {
                localStorage.removeItem(this.session)
            })
    }

    async changePassword(data) {
        return axios
            .post(API_AUTH.changePassword, data)
            .then((response) => this.onSetResult(response.data))
            .catch((error) => this.onSetResult('', error))
    }

    async expirePassword(data) {
        return axios
            .put(API_AUTH.expirePassword, data)
            .then((response) => this.onSetResult(response.data))
            .catch((error) => this.onSetResult('', error))
    }

    async setPassword(verifyCode, password) {
        return axios
            .put(API_AUTH.setPassword, {
                verifyCode: verifyCode,
                password: password,
            })
            .then((response) => this.onSetResult(response.data))
            .catch((error) => this.onSetResult('', error))
    }

    async forgetPassword(data) {
        return axios
            .get(API_AUTH.forgetPassword, {
                params: { username: data },
            })
            .catch((error) => AlertError(error))
    }
}

export const Auth = new Authentication()
