import React, { Component } from 'react'
import { API } from '../config'
import Dropdown from 'react-dropdown'
import LoadingOverlay from 'react-loading-overlay'
import {
    PO_APPROVAL_STATUS_REPORT,
    PO_STATUS_TYPE_FOR_REPORT,
    TOUCH_POINT2,
} from '../../utils/constant'
import { toastNotify } from '../../utils/Alert-msg'
import { getCreatedCompanyList } from '../../utils/event-handlers'
import axios from 'axios'
import { downloadExcelReport } from '../../utils/download-excel'
import { getApiCallDynamic } from '../../service/index'
var XLSX = require('xlsx')

export class POApprovalStatus extends Component {
    constructor(props) {
        super(props)
        console.log('this.props', this.props)

        this.state = {
            poId: '',
            startDate: null,
            endDate: null,

            touchPointList: [],
            status: '',
            tpName: '',
            tpId: null,
            companyName: '',
            companyCode: '',
            companyList: [],
            spinner: false,
            excelData: [],
        }
        this.onChangeSearchFilter = this.onChangeSearchFilter.bind(this)
    }
    getTouchPoint = (id) => {
        let queryParameter = { templateId: 2, orgId: 1 }
        getApiCallDynamic(queryParameter, API.touchPointSequence)
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    let data = []
                    data.push({ id: null, name: 'None', code: 'vn' })

                    this.setState({
                        touchPointList: data.concat(res.data),
                    })
                    console.log(this.state.touchPointList)
                }
            })
            .catch((err) => err)
    }
    getApprovedCompany = () => {
        getCreatedCompanyList('APPROVED', 'vn')
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    this.setState({
                        companyList: res.data,
                    })
                    console.log(this.state.companyList)
                }
            })
            .catch((err) => err)
    }
    defaultParam = () => {
        const {
            roleList,
            businessInfo: { touchPointName },
            businessInfo: { touchPointId },
        } = this.props.user.data
        this.setState({
            status: roleList.some((i) => i.includes('tp::adm'))
                ? 'ALL'
                : 'PENDING',
            tpName: roleList.some((i) => i.includes('tp::adm'))
                ? 'None'
                : touchPointName,
            tpId: roleList.some((i) => i.includes('tp::adm'))
                ? null
                : touchPointId,
        })
    }
    componentDidMount() {
        this.defaultParam()
        this.getTouchPoint(1) //orgId==1
        this.getApprovedCompany()
    }
    onChangeSearchFilter(e) {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    handleDropDownChange =
        (val, name, multi = false) =>
        (event) => {
            console.log('drop', event, 'val', val, 'name', name, 'multi', multi)
            if (multi) {
                if (event.length === 0) {
                    this.setState({
                        [val]: '',
                        // [name]: ''
                    })
                    return
                }
                event = event[0]
            }
            this.setState({
                [val]: event.value,
                // [name]: event.label
            })
        }

    getRequestParams(
        poId,
        startDate,
        endDate,
        status,
        tpId,
        companyCode,
        companyName,
    ) {
        let params = {}

        if (poId) {
            params['poId'] = poId
        }
        if (companyCode) {
            params['companyCode'] = companyCode
        }
        if (companyName) {
            params['companyName'] = companyName
        }

        if (startDate) {
            params['startDate'] = startDate
        }
        if (endDate) {
            params['endDate'] = endDate
        }
        if (tpId) {
            params['touchPointId'] = tpId
        }
        if (status) {
            params['status'] = status
        }

        return params
    }

    findTouchPointName = (a) => {
        let tpName = ''
        this.state.touchPointList.map((column) => ({
            column: column.id === a ? (tpName = column.name) : '',
        }))

        return tpName
    }

    downloadData = () => {
        this.setState({
            excelData: [],
            spinner: true,
        })
        console.log('rrrrrr')
        let {
            poId,
            startDate,
            endDate,
            status,
            tpId,
            companyCode,
            companyName,
        } = this.state

        const params = this.getRequestParams(
            poId,
            startDate,
            endDate,
            status,
            tpId,
            companyCode,
            companyName,
        )

        if (
            status === 'PENDING' ||
            status === 'APPROVED' ||
            status === 'REJECTED'
        ) {
            if (tpId === '' || tpId === null) {
                this.setState({
                    excelData: [],
                    spinner: false,
                })

                return toastNotify('warn', 'Please select Touch Point')
            }
        }

        getApiCallDynamic(params, API.poApprovalStatus)
            .then((response) => {
                if (response?.status === 200) {
                    const { data, totalPages, currentPage } = response
                    console.log('DataList', response.data)
                    if (response.data.length > 0) {
                        const columns = data.map((column) => ({
                            poId: column.poId,
                            vendorName: column.vendorName,
                            department: column.department,
                            status: column.status,
                            approvedTime: column.approvedTime
                                ? new Date(column.approvedTime)
                                : '',
                            activeTouchPointName: this.findTouchPointName(
                                column.touchPointId,
                            ),
                            revisedFobDate: new Date(column.revisedFobDate),
                            comment: column.comment,
                        }))

                        console.log('download api call', columns)
                        downloadExcelReport(
                            columns,
                            PO_APPROVAL_STATUS_REPORT.CONTENT_HEADER,
                            PO_APPROVAL_STATUS_REPORT.FILE_NAME,
                        )
                    } else {
                        toastNotify('warn', 'No Record Found')
                    }
                    this.setState({
                        excelData: columns,
                        spinner: false,
                    })
                }
            })
            .catch((e) => {
                this.setState({
                    excelData: [],
                    page: 1,
                    spinner: false,
                })
                // console.log(e)
            })
    }
    render() {
        const {
            poId,
            data,
            currentTutorial,
            currentIndex,
            page,
            count,
            pageSize,
            startDate,
            endDate,
        } = this.state

        let PO_APPROVAL_STATUS_FIELDS = (
            <tr>
                <td rowSpan={2}>
                    <label className="from-control from-control-sm">
                        FOB Start Date:
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            placeholder="Search by title"
                            value={startDate}
                            id="startDate"
                            onChange={this.onChangeSearchFilter}
                        />
                    </label>
                </td>
                <td rowSpan={2}>
                    <label className="from-control from-control-sm">
                        FOB End Date
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            placeholder="Search by title"
                            value={endDate}
                            id="endDate"
                            onChange={this.onChangeSearchFilter}
                        />
                    </label>
                </td>
                <td rowSpan={2}>
                    <label className="from-control from-control-sm">
                        Status
                        <sup className="text-danger">*</sup>
                    </label>
                    <Dropdown
                        controlClassName={`form-control form-control-sm `}
                        className={` Dropdown-root-width `}
                        value={this.state.status}
                        id="status"
                        options={PO_STATUS_TYPE_FOR_REPORT}
                        onChange={this.handleDropDownChange('status')}
                        placeholder="Select an option"
                    />
                </td>
                <td
                    rowSpan={2}
                    // className={
                    //     this.props.vendor ? 'd-none' : ''
                    // }
                >
                    <label className="from-control from-control-sm">
                        Touch Point
                        <sup className="text-danger">*</sup>
                    </label>

                    <Dropdown
                        controlClassName={`form-control form-control-sm `}
                        className={` Dropdown-root-width `}
                        value={this.state.tpName}
                        id="tpId"
                        options={this.state.touchPointList.map((i) => {
                            return {
                                value: i.id,
                                label: i.name,
                            }
                        })}
                        onChange={this.handleDropDownChange('tpId', 'tpName')}
                        placeholder="Select an option"
                    />
                </td>

                <td></td>
                {/* <td></td> */}
            </tr>
        )

        return (
            <LoadingOverlay
                active={this.state.spinner}
                // spinner={<BounceLoader />}
                spinner={true}
                text="Please wait a moment"
            >
                <div className="container-fluid h-100">
                    <div className="row">
                        <div className="col-md-auto py-3 m-auto">
                            <h2>PO Approval Status</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-auto m-auto">
                            <div className="p-1">
                                {/* decide upon role */}

                                <div className="center list row">
                                    <div className="centerItem col-md-12">
                                        <table className="table table-condensed">
                                            <tbody>
                                                {PO_APPROVAL_STATUS_FIELDS}
                                                <tr></tr>
                                                <tr>
                                                    <td rowSpan={2} colSpan={4}>
                                                        <label className="from-control from-control-sm"></label>

                                                        <button
                                                            className="from-control btn btn-sm btn-outline-primary"
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .downloadData
                                                            }
                                                        >
                                                            Download As Excel
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}
