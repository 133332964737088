import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Path } from '../containers/config'
import { Form, Formik } from 'formik'
import { Auth } from '../api/auth'

export default class ForgerPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
        }
    }

    handleChange = (event) => {
        console.log(
            '---ID---',
            event.target.id,
            '---value---',
            event.target.value,
        )
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    handleSubmit = () => {
        Auth.forgetPassword(this.state.username).then((res) => {
            if (res && res.status === 200) {
                alert(res.data.message)
                this.props.history.push(Path.index)
            }
        })
    }

    handleValidation = () => {
        let errors = {}
        return errors
    }
    render() {
        return (
            <>
                <Formik
                    initialValues={this.state}
                    validate={(values) => this.handleValidation(this, values)}
                    onSubmit={(values, { setSubmitting }) => {
                        this.handleSubmit()
                        setSubmitting(false)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <>
                            <Form>
                                <div className="container-fluid l-bg">
                                    <div className="row justify-content-center">
                                        <div className="col-auto login-center">
                                            <div className="card shadow m-auto">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6 col-12 h-100 l-img">
                                                            <img src="assets/images/eDoc-login.png" />
                                                        </div>
                                                        <div className="col-md-6 col-12 h-100 text-center">
                                                            <h1>
                                                                Reset Password
                                                            </h1>

                                                            <div className="form-group">
                                                                <label
                                                                    style={{
                                                                        float: 'left',
                                                                    }}
                                                                    for="exampleInputEmail1"
                                                                >
                                                                    Username
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    id="username"
                                                                    aria-describedby="emailHelp"
                                                                    required
                                                                    placeholder="e.g. Jhon"
                                                                    onChange={
                                                                        this
                                                                            .handleChange
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="text-center">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-outline-success btn-block"
                                                                >
                                                                    Reset
                                                                </button>
                                                            </div>

                                                            <div className="mb-3  mt-3">
                                                                <small>
                                                                    {' '}
                                                                    <Link
                                                                        className=""
                                                                        to={
                                                                            Path.login
                                                                        }
                                                                    >
                                                                        Back to
                                                                        Login
                                                                    </Link>
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </>
        )
    }
}
