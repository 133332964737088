import axios from 'axios'
import { AlertError } from '../utils/event-handlers'

//sal
export function getApiCallDynamic(param, path) {
    console.log('api param', param, path)
    return axios
        .get(path, { params: param })
        .catch((error) => AlertError(error))
}

export function postApiCallDynamic(data, path, header) {
    return (
        axios
            .post(path, data, { headers: { 'XTR-API-Action': header } })
            // .then((res) => {
            //     if (res) {
            //         SuccessMsg(res.status)
            //         refresh()
            //     }
            // })
            .catch((error) => AlertError(error))
    )
}
