/**
 * Path definition to route between JavaScript pages.
 * Paths can be accessed through Path's static
 * members, e.g., Path.index etc.
 * @type {string}
 */
const INDEX = '/'
const NOTFOUND = '/notfound'

const LOGIN = '/login'
const FORGETPASSWORD = '/forget-password'
const REGISTERPAGE = '/registration'

const HOME = '/home'
const COMPANYREG = '/home/admin/company-reg'
const COMPANYLIST = '/home/admin/company-list'
const COMPANYPENDINGLIST = '/home/admin/company-pending-list'

const USERREG = '/home/admin/user-reg'
const USERLIST = '/home/admin/user-list'
const USERPENDINGLIST = '/home/admin/user-pending-list'

const ROLELIST = '/home/admin/role-list'

const ACTIVITYLOG = '/home/admin/activity-log'

const PROFILE = '/home/profile'

const POFILEUPLOAD = '/home/file-upload'
const CHANGEPASSWORD = '/home/change-password'

const VENDOR = '/home/vendor'

const POLISTDASHBOARD = '/home/po-list'

const PENDINGPOLISTDASHBOARD = '/home/pending/po-list'

const PO_APPROVAL_STATUS = '/home/reports/po-approval'

const EXCEPTION_CHECK_REPORT = '/home/reports/exception-report'

const PRICE_MISMATCH_REPORT = '/home/reports/mismatch-report'

const PO_APPROVED_BY_PERIOD = '/home/reports/po-approved-by-period'

export const PATHNAME = [
    { name: '/home', activeLink: '1', activeDrowDown: '' },
    { name: '/home/admin/company-reg', activeLink: '3', activeDrowDown: '2' },
    { name: '/home/admin/company-list', activeLink: '4', activeDrowDown: '2' },
    {
        name: '/home/admin/company-pending-list',
        activeLink: '5',
        activeDrowDown: '2',
    },
    { name: '/home/admin/user-reg', activeLink: '7', activeDrowDown: '6' },
    { name: '/home/admin/user-list', activeLink: '8', activeDrowDown: '6' },
    {
        name: '/home/admin/user-pending-list',
        activeLink: '9',
        activeDrowDown: '6',
    },
    { name: '/home/file-upload', activeLink: '15' },
    { name: '/home/profile', activeLink: '13', activeDrowDown: '12' },
    { name: '/home/change-password', activeLink: '14', activeDrowDown: '12' },
    { name: '/home/po-list', activeLink: '10', activeDrowDown: '' },
    {
        name: '/home/reports/po-approval',
        activeLink: 'po_approval_status',
        activeDrowDown: 'report',
    },
    {
        name: '/home/reports/exception-report',
        activeLink: 'exception_check_report',
        activeDrowDown: 'report',
    },
    {
        name: '/home/reports/mismatch-report',
        activeLink: 'price_mismatch_report',
        activeDrowDown: 'report',
    },
    {
        name: '/home/reports/po-approved-by-period',
        activeLink: 'po_approved_by_period',
        activeDrowDown: 'report',
    },
]
// { name: '/home/pending/po-list',activeLink }
// { name: '/home/vendor',activeLink: ,activeDrowDown:''},
// { name: '/home/admin/activity-log', activeLink},
export class Path {
    static get index() {
        return INDEX
    }
    static get poApprovalStatus() {
        return PO_APPROVAL_STATUS
    }

    static get exceptionCheckReport() {
        return EXCEPTION_CHECK_REPORT
    }

    static get priceMismatchReport() {
        return PRICE_MISMATCH_REPORT
    }
    static get poApprovedByPeriod() {
        return PO_APPROVED_BY_PERIOD
    }
    static get login() {
        return LOGIN
    }

    static get companyReg() {
        return COMPANYREG
    }
    static get companyList() {
        return COMPANYLIST
    }
    static get companyPendingList() {
        return COMPANYPENDINGLIST
    }
    static get userReg() {
        return USERREG
    }
    static get userList() {
        return USERLIST
    }
    static get userPendingList() {
        return USERPENDINGLIST
    }

    static get roleList() {
        return ROLELIST
    }

    static get activityLog() {
        return ACTIVITYLOG
    }

    static get profile() {
        return PROFILE
    }

    static get poFileUpload() {
        return POFILEUPLOAD
    }

    static get changePassword() {
        return CHANGEPASSWORD
    }
    static get vendor() {
        return VENDOR
    }

    static get pendingPoList() {
        return PENDINGPOLISTDASHBOARD
    }

    static get poListDashboard() {
        return POLISTDASHBOARD
    }

    static get home() {
        return HOME
    }

    static get forgetPassword() {
        return FORGETPASSWORD
    }
    static get registerPage() {
        return REGISTERPAGE
    }

    static get notFound() {
        return NOTFOUND
    }
}

const API_CREATEDCOMPANYLIST = '/scem/api/v1/company'
const API_CREATECOMPANY = '/scem/api/v1/company'
const API_CREATEDUSERLIST = '/scem/api/v1/user'
const API_CREATEUSER = '/scem/api/v1/user'
const API_USER_STATUS_CHANGE = '/scem/api/v1/user/status'
const API_COMPANY_STATUS_CHANGE = '/scem/api/v1/company/status'
const API_ROLELIST = '/scem/api/v1/acl/role'
const API_ORGANIZATIONLIST = '/scem/api/v1/organization'
const API_TOUCH_POINTLIST = '/scem/api/v1/touchpoint'
const API_POLIST = '/scem/api/v1/po/'
const API_FORM = '/scem/api/v1/form'
const API_CREATEPO = '/scem/api/v1/po'
const API_TOUCH_POINT_PO_INFO = '/scem/api/v1/po/touchpoint'
const API_TOUCH_POINT_SEQUENCE = '/scem/api/v1/template/sequence'

const API_REJECTPO = '/scem/api/v1/po/reject'
const API_FILE_UPLOAD = '/document-management/api/v1/local/files'
const API_PO_FILE_UPLOAD = '/document-management/api/v1/scem/files'
const API_FILE_DOWNLAOD = '/document-management/api/v1/local/files'
const API_PO_ACTIVITY_LIST = '/scem/api/v1/po/activity'
const API_PO_PENDING_COUNT = '/scem/api/v1/po/pending'
const API_COMPANY_PENDING_COUNT = '/scem/api/v1/company/pending/count'
const API_USER_PENDING_COUNT = '/scem/api/v1/user/pending/count'
const API_PO_ALL = '/scem/api/v1/po'
const API_PO_APPROVAL_STATUS = '/scem/api/v1/po/poas'
const API_EXCEPTION_CHECK_REPORT = '/scem/api/v1/po/ecr'
const API_PRICE_MISMATCH_REPORT = '/scem/api/v1/po/pmr'
const API_PO_APPROVED_BY_PERIOD = '/scem/api/v1/po/poabp'
const API_DEPARTMENT_NAME = '/scem/api/v1/po/dept'
const API_SUPPLIER_NAME = '/scem/api/v1/po/supplier'
const API_ORGANIZATIONLIST_ALL = '/scem/api/v1/organization/list/all'
const API_CREATECOMPANY_SIGNUP = '/scem/api/v1/company/signup'
const API_CREATEDCOMPANYLIST_ALL = '/scem/api/v1/company/list/all'

// api path

export class API {
    static get createdCompanyListAll() {
        return API_CREATEDCOMPANYLIST_ALL
    }
    static get createCompanySignup() {
        return API_CREATECOMPANY_SIGNUP
    }
    static get organizationListAll() {
        return API_ORGANIZATIONLIST_ALL
    }
    static get poAll() {
        return API_PO_ALL
    }
    static get poApprovalStatus() {
        return API_PO_APPROVAL_STATUS
    }
    static get exceptionCheckReport() {
        return API_EXCEPTION_CHECK_REPORT
    }
    static get priceMismatchReport() {
        return API_PRICE_MISMATCH_REPORT
    }
    static get poApprovedByPeriod() {
        return API_PO_APPROVED_BY_PERIOD
    }

    static get departmentName() {
        return API_DEPARTMENT_NAME
    }
    static get supplierName() {
        return API_SUPPLIER_NAME
    }

    static get companyPendingCount() {
        return API_COMPANY_PENDING_COUNT
    }
    static get userPendingCount() {
        return API_USER_PENDING_COUNT
    }
    static get poPendingCount() {
        return API_PO_PENDING_COUNT
    }

    static get fileUpload() {
        return API_FILE_UPLOAD
    }

    static get poFileUpload() {
        return API_PO_FILE_UPLOAD
    }

    static get fileDownload() {
        return API_FILE_DOWNLAOD
    }

    static get touchPointPOInfo() {
        return API_TOUCH_POINT_PO_INFO
    }

    static get touchPointSequence() {
        return API_TOUCH_POINT_SEQUENCE
    }

    static get createPO() {
        return API_CREATEPO
    }

    static get rejectPO() {
        return API_REJECTPO
    }

    static get poActivityList() {
        return API_PO_ACTIVITY_LIST
    }

    static get formData() {
        return API_FORM
    }

    static get touchPointList() {
        return API_TOUCH_POINTLIST
    }

    static get organizationList() {
        return API_ORGANIZATIONLIST
    }

    static get createdCompanyList() {
        return API_CREATEDCOMPANYLIST
    }

    static get createCompany() {
        return API_CREATECOMPANY
    }

    static get createdUserList() {
        return API_CREATEDUSERLIST
    }

    static get userStatusChange() {
        return API_USER_STATUS_CHANGE
    }

    static get companyStatusChange() {
        return API_COMPANY_STATUS_CHANGE
    }

    static get createUser() {
        return API_CREATEUSER
    }

    static get roleList() {
        return API_ROLELIST
    }

    static get poList() {
        return API_POLIST
    }
}
